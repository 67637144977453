export const departmentStatusEnum = {
  ACTIVE: 0,
  INACTIVE: 1
}


export const departmentStatus = {
  [departmentStatusEnum.ACTIVE]: 'Active',
  [departmentStatusEnum.INACTIVE]: 'Inactive'
}
