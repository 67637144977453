// import client from '@/apis/http-client'
// import Vue from 'vue'

// ========================================================================
// TODO: Remove mocking data and helper
// function when fully implemented.
import client from '@/apis/http-client'
import Vue from 'vue'
let pathUrl = `/por/api`
// const pathOrgUrl = `por/api/organization?page_size=1000`
const pathOrgUrl = `sso/rbac/organization/permission`
import {
  convertDatePicker,
  convertFileToBase64,
  splitToDateAndTime
} from '../helper/helper'
import qs from 'qs'
import moment from 'moment'

// End: TODO
// ========================================================================

// Initial state.
const defaultState = () => {
  return {
    // Filters
    orgList: {
      all: [],
      selected: []
    },
    promotionOnly: false,
    filterStatus: '',
    filterCategory: '',
    textSearch: '',
    deleteId: 0,
    isDelete: false,
    // List of news
    category: [],
    isDisableAfterPublish: false,
    isLoading: false,
    newsList: [],
    updateIdList: [],
    isPreview: false,
    currentPage: 1,
    pageSize: 25,
    total: 0,
    sortBy: 'update_date',
    sortDesc: 'DESC',
    orgObject: [],
    // news object
    newsObject: {
      id: null,
      orgs: [],
      category_id: 0,
      contents: [
        {
          code: 'th',
          title: null,
          description: '',
          thumbnail: ''
        },
        {
          code: 'en',
          title: null,
          description: '',
          thumbnail: ''
        }
      ],
      public_date: null,
      status: 0,
      period_start_date: null,
      period_end_date: null,
      period_start_time: '',
      period_end_time: '',
      create_by_user: null,
      update_by_user: null
    },
    price: 0,
    isEditMode: false,
    duplicateId: null
  }
}

// State
let state = defaultState()

// Function
const transformDuplicate = (newsObject) => {
    delete newsObject['create_by']
    delete newsObject['update_by']
    delete newsObject['update_date']
    newsObject.contents = newsObject.contents.map(c => ({
      code: c.code,
      title: c.title,
      description: c.description,
      thumbnail: c.thumbnail
    }))
    return newsObject
}

// Actions
const actions = {
  async setOrgInfo({commit}, isViewOnly) {
    commit('SET_IS_LOADING', true)

    let mode = ''
    if (isViewOnly) {
      mode = 'View'
    } else if (state.isEditMode) {
      mode = 'Edit'
    } else {
      mode = 'Add'
    }

    let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=${mode}&category=News`
    )
    commit('SET_IS_LOADING', false)
    commit('SET_ORG_LIST', data.data)
    commit('SET_ORG_FILTER', data.data)
  },
  async setListOrgInfo({commit}) {
    let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=View&category=News`
    )
    commit('SET_ORG_FILTER', data.data)
  },
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  setImageThai({commit}, file) {
    commit('SET_IMAGE_THAI', file)
  },
  setImageEng({commit}, file) {
    commit('SET_IMAGE_ENG', file)
  },
  resetState({commit}) {
    commit('RESET_STATE')
  },
  setEditMode({commit}, isEditable) {
    commit('SET_EDIT_MODE', isEditable)
  },
  setDeleteId({commit}, id) {
    commit('SET_DELETE_ID', id)
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setFilterStatus({commit}, status) {
    commit('SET_FILTER_STATUS', status)
  },
  setFilterCategory({commit}, status) {
    commit('SET_FILTER_CATEGORY', status)
  },
  setShowPreview({commit}, status) {
    commit('SET_SHOW_PREVIEW', status)
  },
  setTextSearch({commit}, text) {
    if (text == state.textSearch) return
    commit('SET_TEXT_SEARCH', text)
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setSelectedId({commit}, ids) {
    commit('SET_UPDATE_ID', ids)
  },
  setDeletePopup({commit}, status) {
    commit('SET_POPUP_DELETE', status)
  },
  setOrgFilterCategory({commit}, status) {
    commit('SET_ORG_FILTER_CATEGORY', status)
  },
  async setNewsId({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/news/${id}`)
    commit('SET_NEWS', data.data)
    commit('SET_PERIODS', data.data)
    commit('SET_IS_LOADING', false)
  },
  async setDuplicateNewsId({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/news/${id}`)
    commit('SET_NEWS', { ...data.data,
      id: defaultState().newsObject.id,
      period_start_date: defaultState().newsObject.period_start_date,
      period_end_date: defaultState().newsObject.period_end_date,
      period_start_time: defaultState().newsObject.period_start_time,
      period_end_time: defaultState().newsObject.period_end_time,
    })
    commit('SET_IS_LOADING', false)
  },
  async setPreview({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/news/${id}`)
    if (data) {
      commit('SET_NEWS', data.data)
      commit('SET_PERIODS', data.data)
      commit('SET_SHOW_PREVIEW', true)
    }
    commit('SET_IS_LOADING', false)
  },
  /// EDIT call
  async update({commit, dispatch}, status) {
    let ids = state.updateIdList.map(id => {
      return id.id
    })
    let body = {ids: ids, status: status}
    try {
      await client.secureHttpClient.patch(`${pathUrl}/news/bulk-update`, body)
      dispatch('fetch')
      commit('SET_UPDATE_ID', [])
      return true
    } catch (e) {
      dispatch('fetch')
      Vue.$log.error(e)
      return false
    }
  },
  async updateAllStatus({commit}, {ids, status}) {
    try {
      ids = ids.map(id => {
        return id.id
      })
      let body = {
        ids: ids,
        status: status
      }
      commit('SET_LOAD', true)
      await client.secureHttpClient.patch(`${pathUrl}/news/bulk-update`, body)
      commit('SET_LOAD', false)
      return true
    } catch (e) {
      commit('SET_LOAD', false)
      return false
    }
  },
  async upload(_, payload) {
    try {
      let data = await client.secureHttpClient.post(
        `${pathUrl}/uploadImage`,
        payload
      )
      return data.data.url
    } catch (e) {
      Vue.$log.error(e)
      return ''
    }
  },
  async deletePkg({commit, dispatch}) {
    try {
      commit('SET_IS_LOADING', true)
      await client.secureHttpClient.delete(`${pathUrl}/news/${state.deleteId}`)
      commit('SET_POPUP_DELETE', false)
      commit('SET_DELETE_ID', 0)
      dispatch('fetch')
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      commit('SET_POPUP_DELETE', false)
      return false
    }
  },
  // API calls
  async fetch({commit}) {
    commit('SET_IS_LOADING', true)
    // const   {page, search, limit, status, category, sortBy, sortDesc} = state
    const {
      currentPage,
      pageSize,
      textSearch,
      filterStatus,
      sortBy,
      sortDesc,
      filterCategory,
      orgList
    } = state
    const params = {
      page: currentPage,
      limit: pageSize,
      q: textSearch,
      orderBy: sortBy,
      sort: sortDesc,
      status: filterStatus,
      category: filterCategory,
      ...(orgList.selected.length > 0 && {
        orgs: orgList.selected.map(o => o.codename)
      })
    }
    try {
      let data = await client.secureHttpClient.get(`${pathUrl}/news`, {
        params,
        paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_TOTAL', data.data.total)
      commit('SET_NEWS_LIST', data.data.results)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  setStyleImage({commit}) {
    commit('SET_STYLE_CONTENT')
  },

  async updatePkg({commit}, payload) {
    const {pkg, id} = payload
    commit('SET_IS_LOADING', true)
    try {
      let data = await client.secureHttpClient.put(`${pathUrl}/news/${id}`, pkg)
      commit('SET_IS_LOADING', false)
      return data.data.success
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async create({commit}, pkg) {
    commit('SET_IS_LOADING', true)
    try {
      // reset for duplicate case
      const newsObject = transformDuplicate(pkg)

      await client.secureHttpClient.post(`${pathUrl}/news/`, newsObject)
      commit('RESET_STATE')
      commit('SET_IS_LOADING', false)
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async fetchNews(
    {commit},
    {page, search, limit, status, category, sortBy, sortDesc}
  ) {
    try {
      commit('SET_LOAD', true)
      let queryString = `?page=${page}&&limit=${limit}&&q=${search}&&status=${status}&&category=${
        category[0].text
      }&orderBy=${sortBy[0] ? sortBy[0] : 'update_date'}&sort=${
        sortDesc[0] ? 'DESC' : 'ASC'
      }`
      let data = await client.secureHttpClient.get(
        `${pathUrl}/news${queryString}`
      )
      if (data.data) {
        commit('SET_DATA', data)
      }
      commit('SET_LOAD', false)
    } catch (e) {
      commit('SET_LOAD', false)
    }
  },
  async fetchCategory({commit}) {
    const {orgFilterCategory} = state
    try {
      let query = ''
      if (orgFilterCategory && orgFilterCategory.length > 0) {
        orgFilterCategory.forEach((org, index) => {
          query =
            index === 0
              ? `?orgs[${index}]=${org}`
              : `${query}&orgs[${index}]=${org}`
        })
      }
      let category = await client.secureHttpClient.get(
        `${pathUrl}/category${query}`
      )
      commit('SET_CATEGORY', category)
    } catch (e) {
      commit('SET_CATEGORY', {data: []})
    }
  },
  async fetchCategoryOnList({commit}) {
    const {orgFilterCategory} = state
    try {
      let query = ''
      if (orgFilterCategory && orgFilterCategory.length > 0) {
        orgFilterCategory.forEach((org, index) => {
          query =
            index === 0
              ? `?orgs[${index}]=${org}`
              : `${query}&orgs[${index}]=${org}`
        })
      }
      let category = await client.secureHttpClient.get(
        `${pathUrl}/category${query}`
      )
      commit('SET_CATEGORY', category)
    } catch (e) {
      commit('SET_CATEGORY', {data: []})
    }
  },
  setDuplicateId({commit}, id) {
    commit('SET_DUPLICATE_NEWS_ID', id)
  },
}

// Mutations
const mutations = {
  SET_ORG_LIST(state, org) {
    state.orgObject = org.data
  },
  RESET_STATE(state) {
    state.newsObject = defaultState().newsObject
    state.orgList = defaultState().orgList
    state.duplicateId = defaultState().duplicateId
    state.isDisableAfterPublish = defaultState().isDisableAfterPublish
  },
  RESET_LIST_STATE(state) {
    state.filterStatus = defaultState().filterStatus
    state.sortBy = defaultState().sortBy
    state.orgList = defaultState().orgList
  },
  SET_STYLE_CONTENT(state) {
    state.newsObject.contents[0].description = state.newsObject.contents[0].description
      .toString()
      .split('<img')
      .join('<img width="100%" height="auto"')
    state.newsObject.contents[1].description = state.newsObject.contents[1].description
      .toString()
      .split('<img')
      .join('<img width="100%" height="auto"')
  },
  SET_EDIT_MODE(state, isEdit) {
    state.isEditMode = isEdit
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },

  SET_CATEGORY(state, category) {
    let results = category.data.map(object => {
      return {text: object.contents[0].title, value: object.id}
    })
    state.category = results
  },
  SET_FILTER_STATUS(state, status) {
    state.filterStatus = status
  },

  SET_FILTER_CATEGORY(state, status) {
    state.filterCategory = status
  },
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  SET_TOTAL(state, total) {
    state.total = total
  },
  SET_NEWS_LIST(state, news) {
    state.newsList = news
  },
  SET_NEWS(state, pkg) {
    state.newsObject = {...defaultState().newsObject, ...pkg}
    state.isDisableAfterPublish = moment(pkg.period_start_date).isSameOrBefore()

    const start = !pkg.period_start_date
      ? {dateString: null, timeString: ''}
      : splitToDateAndTime(pkg.period_start_date)
    state.newsObject.period_start_date = start.dateString
    state.newsObject.period_start_time = start.timeString
    const end = !pkg.period_end_date
      ? {dateString: null, timeString: ''}
      : splitToDateAndTime(pkg.period_end_date)
    state.newsObject.period_end_date = end.dateString
    state.newsObject.period_end_time = end.timeString
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_UPDATE_ID(state, ids) {
    state.updateIdList = ids
  },
  SET_IMAGE_THAI(state, file) {
    convertFileToBase64(file)
      .then(base64 => {
        state.newsObject.contents[0].thumbnail = base64
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_IMAGE_ENG(state, file) {
    convertFileToBase64(file)
      .then(base64 => {
        state.newsObject.contents[1].thumbnail = base64
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_POPUP_DELETE(state, status) {
    state.isDelete = status
  },
  SET_DELETE_ID(state, id) {
    state.deleteId = id
  },
  SET_SHOW_PREVIEW(state, status) {
    state.isPreview = status
  },
  SET_ORG_FILTER_CATEGORY(state, status) {
    state.orgFilterCategory = status
  },
  SET_ORG_FILTER(state, org) {
    const selected = []

    if (org.data.length === 1) {
      org.data.forEach(d =>
        selected.push({
          codename: d.codename,
          selected: true
        })
      )
    }
    state.orgList.selected = selected
    state.orgList.all = org.data
  },
  SET_PERIODS(state, data) {
    state.newsObject.period_start_date =
      data.period_start_date && convertDatePicker(data.period_start_date)
    state.newsObject.period_end_date =
      data.period_end_date && convertDatePicker(data.period_end_date)
  },
  SET_DUPLICATE_NEWS_ID(state, id) {
    state.duplicateId = id
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
