export const prefix = {
  en:
    "<meta name='viewport' http-equiv='Content-Type' content='text/html; charset=utf-8 width=device-width,initial-scale=0.8,minimum-scale=0.8,maximum-scale=0.8' />",
  th:
    "<meta name='viewport' http-equiv='Content-Type' content='text/html; charset=utf-8 width=device-width,initial-scale=0.9,minimum-scale=0.9,maximum-scale=0.9' />"
}

export const termConditionTypeEnum = {
  REGISTER: 'register',
  MEMBERSHIP: 'membership',
  BINDING: 'binding',
  DELETE_ACCOUNT: 'delete_account',
}
export const termConditionTypeText = {
  [termConditionTypeEnum.REGISTER]: 'Register',
  [termConditionTypeEnum.MEMBERSHIP]: 'Membership',
  [termConditionTypeEnum.BINDING]: 'Binding',
  [termConditionTypeEnum.DELETE_ACCOUNT]: 'Delete Account',
}