export const symptomaticAreasStatusEnum = {
  ACTIVE: 0,
  INACTIVE: 1
}


export const symptomaticAreasStatus = {
  [symptomaticAreasStatusEnum.ACTIVE]: 'Active',
  [symptomaticAreasStatusEnum.INACTIVE]: 'Inactive'
}
