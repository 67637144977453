import client from '@/apis/http-client'
// import moment from 'moment'
import Vue from 'vue'
const pathUrl = `por/api/admin`
const pathOrgUrl = `por/api/organization`

// Initial state.
const defaultState = () => {
  return {
    // Filters
    filterStatus: '',
    textSearch: '',
    deleteId: 0,
    resetPasswordId: 0,
    isDelete: false,
    isResetPassword: false,
    // List of users
    isLoading: false,
    usersList: [],
    updateIdList: [],
    currentPage: 1,
    totalUsers: 0,
    pageSize: 25,
    total: 0,
    sortBy: 'updated_at',
    sortDesc: 'DESC',
    status: 0,

    userObject: {
      id: null,
      first_name: '',
      last_name: '',
      email: '',
      username: '',
      is_active: true,
      position: ''
    },
    orgObject: [],
    orgRoleObject: [],
    selectRoleOrgData: [],
    isEditMode: false
  }
}

// State
let state = defaultState()

// Actions
const actions = {
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  resetState({commit}) {
    commit('RESET_STATE')
  },
  setEditMode({commit}, isEditable) {
    commit('SET_EDIT_MODE', isEditable)
  },
  setDeleteId({commit}, id) {
    commit('SET_DELETE_ID', id)
  },
  setResetPasswordId({commit}, id) {
    commit('SET_RESET_PASSWORD_ID', id)
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setFilterStatus({commit}, status) {
    commit('SET_FILTER_STATUS', status)
  },
  setTextSearch({commit}, text) {
    if (text == state.textSearch) return
    commit('SET_TEXT_SEARCH', text)
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setSelectedId({commit}, ids) {
    commit('SET_UPDATE_ID', ids)
  },
  setDeletePopup({commit}, status) {
    commit('SET_POPUP_DELETE', status)
  },
  setResetPasswordPopup({commit}, status) {
    commit('SET_POPUP_RESET_PASSWORD', status)
  },
  setSelectedRoleOrg({commit}, ids) {
    commit('SET_SELECT_ORG_LIST', ids)
  },
  async setOrgRoleInfo({commit}) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathOrgUrl}/role`)
    commit('SET_ORG_ROLE_LIST', data.data)
    commit('SET_IS_LOADING', false)
  },
  async setOrgInfo({commit}) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathOrgUrl}`, {params: {is_active: true}})
    commit('SET_ORG_LIST', data.data)
    commit('SET_IS_LOADING', false)
  },
  async setUserId({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/${id}`)
    if (data) {
      const roles = data.data.roles.map(role => role.org_role.id)
      commit('SET_SELECT_ORG_LIST', roles)
      commit('SET_USER', data.data)
    }
    commit('SET_IS_LOADING', false)
  },
  /// EDIT call
  async update({commit, dispatch}, status) {
    let ids = state.updateIdList.map(({id}) => id)
    let body = {users: ids, is_active: status === 'Active'}
    try {
      await client.secureHttpClient.post(
        `${pathUrl}/bulk_update`,
        body
      )
      dispatch('fetch')
      commit('SET_UPDATE_ID', [])
      return true
    } catch (e) {
      Vue.$log.error(e)
      return false
    }
  },
  async deleteUser({commit, dispatch}) {
    try {
      commit('SET_IS_LOADING', true)
      await client.secureHttpClient.delete(
        `${pathUrl}/${state.deleteId}`
      )
      commit('SET_POPUP_DELETE', false)
      commit('SET_DELETE_ID', 0)
      dispatch('fetch')
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_POPUP_DELETE', false)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async resetPasswordUser({commit, dispatch}) {
    try {
      commit('SET_IS_LOADING', true)
      await client.secureHttpClient.patch(`${pathUrl}/${state.resetPasswordId}/change_password`, {email_notify: true})
      commit('SET_POPUP_RESET_PASSWORD', false)
      commit('SET_RESET_PASSWORD_ID', 0)
      dispatch('fetch')
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  // API calls
  async fetch({commit}) {
    commit('SET_IS_LOADING', true)
    const {
      currentPage,
      pageSize,
      textSearch,
      filterStatus,
      sortBy,
      sortDesc
    } = state
    try {
      const order_by = sortDesc === 'DESC' ? `-${sortBy}` : sortBy
      let params = {page: currentPage, page_size: pageSize, search: textSearch, order_by}
      params = filterStatus === '' ? params : {...params, is_active: filterStatus}

      let data = await client.secureHttpClient.get(`${pathUrl}`, {params})
      commit('SET_TOTAL_USERS', data.data.total)
      commit('SET_USERS_LIST', data.data.data)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },

  async updatePkg({commit}, payload) {
    const {pkg, id} = payload
    commit('SET_IS_LOADING', true)
    pkg.status = parseInt(pkg.status)
    try {
      let data = await client.secureHttpClient.patch(
        `${pathUrl}/${id}`,
        pkg
      )
      commit('SET_IS_LOADING', false)
      return data.data.updated_at
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async create({commit}, pkg) {
    commit('SET_IS_LOADING', true)
    pkg.status = parseInt(pkg.status)
    pkg.email_notify = true
    try {
      let create = await client.secureHttpClient.post(`${pathUrl}`, pkg)
      commit('RESET_STATE')
      commit('SET_IS_LOADING', false)
      return create.data.id ? true : false
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async chkExistingEmail({commit}, {email, id}) {
    try {
      const body = id ? {email, id} : {email}
      let chkEmail = await client.secureHttpClient.post(`${pathUrl}/email/validation`, body)

      commit('SET_IS_LOADING', false)
      return chkEmail.status === 200
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)

      return false
    }
  }
}

const mutations = {
  RESET_STATE(state) {
    state.userObject = defaultState().userObject
    state.selectRoleOrgData = defaultState().selectRoleOrgData
  },
  RESET_LIST_STATE(state) {
    state.filterStatus = defaultState().filterStatus
    state.textSearch = defaultState().textSearch
    state.sortBy = defaultState().sortBy
  },
  SET_EDIT_MODE(state, isEdit) {
    state.isEditMode = isEdit
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  SET_FILTER_STATUS(state, status) {
    state.filterStatus = status
  },
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  SET_TOTAL_USERS(state, total) {
    state.totalUsers = total
  },
  SET_USERS_LIST(state, users) {
    state.usersList = users
  },
  SET_USER(state, user) {
    state.userObject = user
  },
  SET_ORG_LIST(state, org) {
    state.orgObject = org
  },
  SET_ORG_ROLE_LIST(state, org) {
    state.orgRoleObject = org
  },
  SET_SELECT_ORG_LIST(state, org) {
    state.selectRoleOrgData =  org
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_UPDATE_ID(state, ids) {
    state.updateIdList = ids
  },
  SET_POPUP_DELETE(state, status) {
    state.isDelete = status
  },
  SET_POPUP_RESET_PASSWORD(state, status) {
    state.isResetPassword = status
  },
  SET_DELETE_ID(state, id) {
    state.deleteId = id
  },
  SET_RESET_PASSWORD_ID(state, id) {
    state.resetPasswordId = id
  },

}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

