import client from '@/apis/http-client'
import {convertDate} from '../helper/helper'
import qs from 'qs'

const pathUrl = `/por/api`
const pathOrgUrl = `sso/rbac/organization/permission`
const defaultState = () => {
  return {
    loading: false,
    loadingList: false,
    phone_number: '',
    profile: '',
    first_name: '',
    nationality: '',
    last_name: '',
    email: '',
    sex: '',
    birth_date: '',
    id_card: '',
    age: '',
    address: '',
    flag_address: '',
    province: '',
    province_id: 0,
    district: '',
    district_id: 0,
    sub_district: '',
    sub_district_id: 0,
    postal: '',
    create_date: '',
    update_date: '',
    update_by_user: {name: '', is_active: true},
    device_os: '',
    status: 1,
    is_delete: null,
    member: {},
    members: [
      // {
      //   membership_org: 'No membership',
      //   membership_no: '',
      //   type: '',
      //   issue_date: '',
      //   expire_date: '',
      //   tnc_version: '',
      // },
    ],
    hn: '',
    connect_status: '',
    connect_date_time: '',
    connect_hospital_name: '',
    tnc_version: '',
    binding_tnc_version: '',
    patients: [
      // {
      //   index: 0,
      //   org_codename: "never",
      //   hn: "",
      //   connection_status: "Never binding",
      //   connect_date_time: "",
      //   connect_hospital_name: "",
      //   is_connect: false,
      // }
    ],
    total: 0,
    customerList: [],
    currentPage: 1,
    pageSize: 25,
    textSearch: '',
    orgList: {
      all: [],
      selected: []
    },
    filterBinding: null,
    filterDeviceOS: null,
    filterMember: null,
    filterIsDelete: null,
    sortBy: 'update_date',
    sortDesc: 'DESC',
    removable: {
      status: false,
      error: null
    },
    provinces: [],
    districts: [],
    sub_districts: [],
  }
}
const state = defaultState()

const createParams = (state) => {
  const {
    currentPage,
    pageSize,
    textSearch,
    filterBinding,
    filterDeviceOS,
    filterMember,
    filterIsDelete,
    orgList,
    sortBy,
    sortDesc
  } = state

  const params = {
    lang: 'en',
    page: currentPage,
    limit: pageSize,
    q: `${encodeURIComponent(textSearch)}`,
    orderby: sortBy,
    sort: sortDesc,
    ...(filterBinding && {filterBinding: filterBinding}),
    ...(filterDeviceOS && {filterDevice: filterDeviceOS}),
    ...(filterMember && {filterMember: filterMember}),
    ...(filterIsDelete && {filterIsDelete: filterIsDelete}),
    ...(orgList.selected.length > 0 && {
      org_codename: orgList.selected.map(o => o.codename)
    })
  }
  return params
}

const fetchData = async ({commit, dispatch}, {uid}) => {
  try {
    commit('SET_LOADING', true)
    let user = await client.secureHttpClient.get(`/por/api/getUser/${uid}`)
    if (user && user.data) {
      commit('SET_USER', user.data.data)
      if (user.data.data.patients.length > 0) {
        commit('SET_PATIENT', user.data.data.patients)
      } else {
        commit('RESET_PATIENT')
      }
      if (user.data.data.flag_address || user.data.data.province_id > 0) {
        dispatch('fetchProvinces')
        dispatch('fetchDistricts', user.data.data.province_id)
      }
      if (user.data.data.district_id > 0) {
        dispatch('fetchSubDistricts', user.data.data.district_id)
      }
    }
    commit('SET_LOADING', false)
  } catch (e) {
    commit('SET_LOADING', false)
    return null
  }
}
const fetch = async ({commit}) => {
  commit('SET_LOADING_LIST', true)
  const params = createParams(state)
  try {
    let data = await client.secureHttpClient.get(
      `${pathUrl}/getAllUser`, {
          params,
          paramsSerializer: params => qs.stringify(params, {encode: false})
        }
    )
    commit('SET_TOKEN', data)
    commit('SET_TOTAL', data.data.total)
    commit('SET_CUSTOMER_LIST', data.data.data)
    commit('SET_LOADING_LIST', false)
    return true
  } catch (e) {
    commit('SET_LOADING_LIST', false)
    return false
  }
}
const fetchProvinces = async ({commit}) => {
  try {
    let data = await client.secureHttpClient.get(
      `${pathUrl}/provinces/en`
    )
    commit('SET_PROVINCES', data.data.data)
    return true
  } catch (e) {
    return false
  }
}
const fetchDistricts = async ({commit}, province_id) => {
  try {
    let data = await client.secureHttpClient.get(`${pathUrl}/amphures/en/${province_id}`
    )
    commit('SET_DISTRICTS', data.data.data)
    return true
  } catch (e) {
    return false
  }
}
const fetchSubDistricts = async ({commit}, district_id) => {
  try {
    let data = await client.secureHttpClient.get(
      `${pathUrl}/postal/en/${district_id}`
    )
    commit('SET_SUB_DISTRICTS', data.data.data)
    return true
  } catch (e) {
    return false
  }
}
const update = async (_, object) => {
  try {
    let data = await client.secureHttpClient.put(
      `/por/api/user/bulk-update`,
      object
    )
    return data.data
  } catch (e) {
    return {success: false, message: e.response.data.message}
  }
}
const updateAddress = async ({dispatch}, object) => {
  try {
    let data = await client.secureHttpClient.put(
      `/por/api/user/updateAddress`,
      object
    )
    await dispatch('fetchData', {uid: object.uid})
    return data.data
  } catch (e) {
    return {success: false, message: e.response.data.message}
  }
}
const deleteCustomer = async ({commit}, {uid}) => {
  try {
    commit('SET_LOADING', true)
    await client.secureHttpClient.delete(
      `/por/api/user/${uid}/remove?orgcode=psuv`
    )
    commit('SET_LOADING', false)
    return true
  } catch (e) {
    commit('SET_LOADING', false)
    return false
  }
}
const checkRemovable = async ({commit}, uid) => {
  try {
    commit('SET_LOADING', true)
    const data = await client.secureHttpClient.get(
      `/por/api/user/${uid}/removable`
    )
    commit('SET_REMOVABLE', data.data)
    commit('SET_LOADING', false)
    return true
  } catch (e) {
    commit('SET_LOADING', false)
    return false
  }
}
const setIdcard = async ({commit}, id_card) => {
  commit('SET_ID_CARD', id_card)
}
const resetList = ({commit}) => {
  commit('RESET_LIST_STATE')
}
const setListOrgInfo = async ({commit}) => {
  commit('SET_LOADING_LIST', true)
  let data = await client.secureHttpClient.get(
    `${pathOrgUrl}?permission=View&category=AllCustomer`
  )
  commit('SET_ORG_FILTER', data.data)
  commit('SET_LOADING_LIST', false)
}
const setPage = ({commit}, page) => {
  commit('SET_PAGE', page)
}
const setPageSize = ({commit}, size) => {
  commit('SET_PAGE_SIZE', size)
}
const setSort = ({commit}, sort) =>  {
  commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
}
const setFieldSort = ({commit}, field) => {
  if (field) {
    commit('SET_FIELD_SORT', field)
  } else {
    commit('SET_FIELD_SORT', defaultState().sortBy)
  }
}
const setTextSearch = ({commit}, text) => {
  if (text === state.textSearch) return
  commit('SET_TEXT_SEARCH', text)
}
const setFilterBinding = ({commit}, binding) => {
  commit('SET_FILTER_BINDING', binding)
}
const setFilterDeviceOS = ({commit}, deviceOS) => {
  commit('SET_FILTER_DEVICE_OS', deviceOS)
}
const setFilterMember = ({commit}, member) => {
  commit('SET_FILTER_MEMBER', member)
}
const setFilterIsDelete = ({commit}, isDelete) => {
  commit('SET_FILTER_IS_DELETE', isDelete)
}

const mutations = {
  SET_TOKEN(state, {token}) {
    state.token = token
  },
  SET_LOADING(state, loading) {
    state.loading = loading
    if (loading) {
      state.profile = ''
    }
  },
  SET_LOADING_LIST(state, loading) {
    state.loadingList = loading
  },
  SET_ID_CARD(state, id_card) {
    state.id_card = id_card
  },
  SET_USER(state, user) {
    const {
      phone_number,
      profile,
      first_name,
      nationality,
      last_name,
      email,
      sex,
      birth_date,
      id_card,
      age,
      address,
      flag_address,
      province,
      province_id,
      district,
      district_id,
      sub_district,
      sub_district_id,
      postal,
      create_date,
      update_date,
      update_by_user,
      device_os,
      status,
      is_delete,
      member,
      members,
      tnc_version,
      binding_tnc_version,
      create_by_org,
      update_by_org,
    } = user

    state.phone_number = phone_number
    state.profile = profile
    state.first_name = first_name
    state.nationality = nationality
    state.last_name = last_name
    state.email = email
    state.sex = sex
    state.birth_date = birth_date
    state.id_card = id_card
    state.age = age
    state.address = address
    state.flag_address = flag_address
    state.province = province
    state.province_id = province_id
    state.district = district
    state.district_id = district_id
    state.sub_district = sub_district
    state.sub_district_id = sub_district_id
    state.postal = postal
    state.create_date = create_date
    state.update_date = update_date
    state.update_by_user = update_by_user
    state.device_os = device_os
    state.status = status
    state.is_delete = is_delete
    state.tnc_version = tnc_version
    state.binding_tnc_version = binding_tnc_version
    state.create_by_org = create_by_org
    state.update_by_org = update_by_org
    state.member = member ? member : {}
    const memberships = members.map(member => {
      return {
        membership_org: member.membership_org.org_codename,
        membership_no: member.member_no,
        type: member.membership_contents[0].name,
        issue_date: member.issue_date ? convertDate(member.issue_date) : '',
        expire_date: member.expire_date ? convertDate(member.expire_date) : '',
        tnc_version: member.tnc_version,
        create_date: member.create_date ? convertDate(member.create_date) : ''
      }
    })
    state.members = members.length > 0 ? memberships : defaultState().members
  },
  SET_PATIENT(state, patients) {
    state.patients = patients.map((patient, index) => {
      return {
        ...patient,
        index,
        connection_status: patient.is_connect ? 'success' : 'failed',
        connect_date_time: convertDate(patient.connect_date_time)
      }
    })
  },
  RESET_PATIENT(state) {
    state.patients = defaultState().patients
  },
  RESET_LIST_STATE(state) {
    state.loading = defaultState().loading
    state.loadingList = defaultState().loadingList
    state.textSearch = defaultState().textSearch
    state.filterBinding = defaultState().filterBinding
    state.filterDeviceOS = defaultState().filterDeviceOS
    state.filterMember = defaultState().filterMember
    state.filterIsDelete = defaultState().filterIsDelete
    state.orgList = defaultState().orgList
    state.sortBy = defaultState().sortBy
    state.sortDesc = defaultState().sortDesc
  },
  SET_TOTAL(state, total) {
    state.total = total
  },
  SET_CUSTOMER_LIST(state, customers) {
    state.customerList = customers
  },
  SET_ORG_FILTER(state, org) {
    const selected = []

    if (org.data.length === 1) {
      org.data.forEach(d =>
        selected.push({
          codename: d.codename,
          selected: true
        })
      )
    }
    state.orgList.selected = selected
    state.orgList.all = org.data
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  SET_FILTER_BINDING(state, binding) {
    state.filterBinding = binding
  },
  SET_FILTER_DEVICE_OS(state, deviceOS) {
    state.filterDeviceOS = deviceOS
  },
  SET_FILTER_MEMBER(state, member) {
    state.filterMember = member
  },
  SET_FILTER_IS_DELETE(state, isDelete) {
    state.filterIsDelete = isDelete
  },
  SET_REMOVABLE(state, data) {
    state.removable.status = data.removable
    state.removable.error = data.error
  },
  SET_PROVINCES(state, data) {
    state.provinces = data
  },
  SET_DISTRICTS(state, data) {
    state.districts = data
  },
  SET_SUB_DISTRICTS(state, data) {
    state.sub_districts = data
  },
}

const actions = {
  fetchData,
  update,
  updateAddress,
  setIdcard,
  deleteCustomer,
  resetList,
  setListOrgInfo,
  setPage,
  setPageSize,
  setSort,
  setFieldSort,
  setTextSearch,
  setFilterBinding,
  setFilterDeviceOS,
  setFilterMember,
  setFilterIsDelete,
  fetch,
  checkRemovable,
  fetchProvinces,
  fetchDistricts,
  fetchSubDistricts,
}

const getters = {
  isThaiNationality (state) {
    return state.nationality.trim() === 'ไทย' ||
      state.nationality.trim() === 'Thailand';
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
