import Vue from 'vue'
import client from '@/apis/http-client'
import {increasePatchVersion} from '@/helper/helper'
import qs from 'qs'
let pathUrl = `/por/api`

const defaultState = () => {
  return {
    isLoading: false,
    localizeList: [],
    total: 0,
    errorMessage: '',
    localize: {
      spreadsheet_id: '1Fyc1fCqTeCzAZpoEF2GGPFaxmTlAwkA4qMVzAPmIpp4',
      sheet_name: 'Localizable.strings',
      version: ''
    },
    // params
    currentPage: 1,
    pageSize: 25,
    textSearch: '',
    orgList: {
      all: [],
      selected: []
    },
    sortBy: 'create_date',
    sortDesc: 'DESC'
  }
}

// State
let state = defaultState()

function createParams(state) {
  const {currentPage, pageSize, sortBy, sortDesc} = state

  const params = {
    page: currentPage,
    limit: pageSize,
    ...(sortBy.length > 0 && {
      orderBy: sortBy,
      sort: sortDesc
    })
  }
  return params
}

async function updateLocalize(commit) {
  try {
    await client.secureHttpClient.post(`${pathUrl}/localize`, state.localize)
    commit('SET_IS_LOADING', false)
  } catch (e) {
    Vue.$log.error(e)
    const errMessage = e.response.data.message || 'Response Error'
    commit('SET_IS_LOADING', false)
    commit('SET_ERROR_MESSAGE', errMessage)
  }
}

const actions = {
  // API calls
  async fetch({commit}) {
    commit('SET_IS_LOADING', true)
    const params = createParams(state)
    try {
      let data = await client.secureHttpClient.get(`${pathUrl}/localizes`, {
        params,
        paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_TOTAL', data.data.total)
      commit('SET_LOCALIZE_LIST', data.data.results)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async update({commit}) {
    commit('SET_IS_LOADING', true)
    try {
      let data = await client.secureHttpClient.get(`${pathUrl}/localize`)
      commit('SET_NEW_VERSION', data.data.data.version)
      await updateLocalize(commit)
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  resetErrorMsg({commit}) {
    commit('RESET_ERROR')
  },
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  }
}
const mutations = {
  RESET_LIST_STATE(state) {
    state.localizeList = defaultState().localizeList
    state.total = defaultState().total
    state.currentPage = defaultState().currentPage
    state.pageSize = defaultState().pageSize
    state.sortBy = defaultState().sortBy
    state.sortDesc = defaultState().sortDesc
  },
  RESET_ERROR(state) {
    state.errorMessage = defaultState().errorMessage
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_ERROR_MESSAGE(state, message) {
    state.errorMessage = message
  },
  SET_NEW_VERSION(state, version) {
    state.localize.version = increasePatchVersion(version)
  },
  SET_TOTAL(state, total) {
    state.total = total
  },
  SET_LOCALIZE_LIST(state, localizes) {
    state.localizeList = localizes
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations
}
