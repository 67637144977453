import Vue from 'vue'
import client from '@/apis/http-client'
import moment from 'moment'
import qs from 'qs'

import {convertFileToBase64, splitToDateAndTime} from '../helper/helper'
import {bannerStatusEnum, sectionEnumText, typeEnum} from '@/constants/banner'
let pathUrl = `/por/api`
const pathOrgUrl = `sso/rbac/organization/permission`

const defaultState = () => ({
  // filter
  orgList: {
    all: [],
    selected: []
  },
  filterType: null,
  filterStatus: '',
  // Listing mode
  textSearch: '',
  banners: [],
  sortableList: [],
  sections: [],
  sortBy: 'update_date',
  sortDesc: 'DESC',
  total: 0,
  currentPage: 1,
  pageSize: 25,
  sortableTotal: 0,
  isBrowser: false,
  isLoading: false,
  isLoadingBranch: false,
  isUnpublished: false,
  isPublished: false,
  isExpired: false,
  statusTab: 0,
  orgObject: [],
  // Create/Edit mode
  isEditMode: false,
  contentList: [],
  bannerObject: {
    id: null,
    section_id: 1,
    content_id: "",
    browser_url: '',
    period_start_date: '',
    period_start_time: '',
    period_end_date: '',
    period_end_time: '',
    section: {text: '', value: 0},
    orgs: [],
    contents: [
      {
        id: '',
        code: 'th',
        title: '',
        thumbnail: '',
        slide_image: null
      },
      {
        id: '',
        code: 'en',
        title: '',
        thumbnail: '',
        slide_image: null
      }
    ],
    status: 0,
    active_date: null,
    linkedContent: [],
    link_to: typeEnum.NO_LINK,
    update_by_user: null,
    update_date: null,
    create_by_user: null,
    create_date: null
  },
  editOrderObject: {
    id: null,
    section_id: 1,
    content_id: "",
    browser_url: '',
    period_start_date: '',
    period_start_time: '',
    period_end_date: '',
    period_end_time: '',
    section: {text: '', value: 0},
    orgs: [],
    contents: [
      {
        id: '',
        code: 'th',
        title: '',
        thumbnail: '',
        slide_image: null
      },
      {
        id: '',
        code: 'en',
        title: '',
        thumbnail: '',
        slide_image: null
      }
    ],
    status: 0,
    active_date: null,
    linkedContent: [],
    link_to: typeEnum.NO_LINK,
    update_by_user: null,
    update_date: null,
    create_by_user: null,
    create_date: null
  },
  sectionContent: {
    isLoading: false,
    textSearch: '',
    page: 1,
    pageSize: 25,
    totalSize: 0,
  },
  selectedBranch: '',

  isImageEnPopupError: false,
  imageEnPopupErrorMessage: '',
  isImageThPopupError: false,
  imageThPopupErrorMessage: '',

  isImageEnError: false,
  imageEnErrorMessage: '',
  isImageThError: false,
  imageThErrorMessage: '',
  isDelete: false,
  isPreview: false,
  isEditOrder: false,
})

// Initial state
let state = defaultState()

const filterPayload = (obj) => {
  const excludeKeys = ["update_by_user", "update_date", "create_by_user", "create_date"]
  const asArray = Object.entries(obj);
  const filtered = asArray.filter(([key]) => !(excludeKeys.includes(key)));
  return Object.fromEntries(filtered);
}

// Actions
const actions = {
  setPopupImageError({commit}, data) {
    commit('SET_POPUP_IMAGE_ERROR', data)
  },
  setPopupImage({commit}, data) {
    commit('SET_POPUP_IMAGE', data)
  },
  setImageError({commit}, data) {
    commit('SET_IMAGE_ERROR', data)
  },
  setImage({commit}, data) {
    commit('SET_IMAGE', data)
  },
  setTextSearch({commit}, text) {
    if (text == state.textSearch) return
    commit('SET_TEXT_SEARCH', text)
  },
  setTabStatus({commit}, status) {
    commit('SET_TAB_STATUS', status)
  },
  setShowPreview({commit}, status) {
    commit('SET_SHOW_PREVIEW', status)
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setShowEditOrder({commit}, status) {
    commit('SET_SHOW_EDIT_ORDER', status)
  },
  resetState({commit}) {
    commit('RESET_STATE')
  },
  resetSection({commit}) {
    commit('RESET_SECTION')
  },
  resetContent({commit}) {
    commit('RESET_CONTENT')
  },
  resetStartDatetime({commit}) {
    commit('RESET_START_DATETIME')
  },
  resetEndDatetime({commit}) {
    commit('RESET_END_DATETIME')
  },
  resetContentTable({commit}) {
    commit('RESET_CONTENT_TABLE')
  },
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  resetPopupImage({commit}) {
    commit('RESET_POPUP_IMAGE')
  },
  resetSearchAndFilter({commit}) {
    commit('RESET_SEARCH_AND_FILTER')
  },
  setLoading({commit}, status) {
    commit('SET_LOADING', status)
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setFilterType({commit}, type) {
    commit('SET_FILTER_TYPE', type)
  },
  setFilterStatus({commit}, status) {
    let selected = ''
    if (status.length === 1) {
      selected = status[0]
    }
    commit('SET_FILTER_STATUS', selected)
  },
  setBrowserMode({commit}, value) {
    commit('SET_BROWSER_MODE', value)
  },
  resetBrowserMode({commit}) {
    commit('RESET_BROWSER_MODE')
  },
  setSelectedBranch({commit}, codename) {
    commit('SET_SELECTED_BRANCH', codename)
  },
  async setOrgInfo({commit}, isViewOnly) {
    commit('SET_LOADING', true)

    let mode = ''
    if (isViewOnly) {
      mode = 'View'
    } else if (state.isEditMode) {
      mode = 'Edit'
    } else {
      mode = 'Add'
    }

    let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=${mode}&category=Banner`
    )
    commit('SET_ORG_LIST', data.data)
    commit('SET_LOADING', false)
  },
  async setListOrgInfo({commit}) {
    commit('SET_IS_LOADING_BRANCH', true)
    try {
      let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=View&category=Banner`
      )
      commit('SET_IS_LOADING_BRANCH', false)
      commit('SET_ORG_FILTER', data.data)
    } catch (e) {
      commit('SET_IS_LOADING_BRANCH', false)
    }
  },
  async setBannerId({commit}, id) {
    commit('SET_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/banner/${id}`)
    if (data) {
      commit('SET_BANNER_OBJECT', data.data)
    }
    commit('SET_LOADING', false)
  },
  async setBannerPreview({commit}, id) {
    commit('SET_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/banner/${id}`)
    if (data) {
      commit('SET_BANNER_OBJECT', data.data)
      commit('SET_SHOW_PREVIEW', true)
    }
    commit('SET_LOADING', false)
  },
  async setEditOrder({commit}, item) {
    const indexNo = state.sortableList.findIndex(x => x.id === item.id);
    commit('SET_EDIT_ORDER_OBJECT', {...item, indexNo, total: state.sortableList.length})
    commit('SET_SHOW_EDIT_ORDER', true)
  },
  setLinkedContent({commit}, payload) {
    commit('SET_CONTENT_ID', payload.content_id)
    commit(
      'SET_LINKED_CONTENT',
      payload.contents
        ? payload.contents
        : defaultState().bannerObject.linkedContent
    )
    if (payload.expiry_date !== undefined) commit('SET_PERIOD_END_DATETIME', payload.expiry_date)
  },
  setEditMode({commit}, isEditable) {
    commit('SET_EDIT_MODE', isEditable)
  },
  async reorderBannersList({commit}, clonedBannersList) {
    commit('SET_SORTABLE_BANNERS', clonedBannersList)
    await client.secureHttpClient.patch(
      `${pathUrl}/banner/bulk-order-update`,
      clonedBannersList
    )
  },
  async fetch({commit}) {
    const {
      currentPage,
      pageSize,
      textSearch,
      filterType,
      filterStatus,
      sortBy,
      sortDesc,
      orgList
    } = state
    commit('SET_LOADING', true)
    try {
      const params = {
        page: currentPage,
        limit: pageSize,
        q: textSearch,
        orderBy: sortBy,
        sort: sortDesc,
        ...(filterStatus !== '' && {status: filterStatus}),
        ...(filterType !== null && {type: filterType}),
        ...(orgList.selected.length > 0 && {
          orgs: orgList.selected.map(o => o.codename)
        })
      }
      let data = await client.secureHttpClient.get(`${pathUrl}/banner`, {
        params,
        paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_TOTAL', data.data.total)
      commit('SET_BANNERS', data.data.results)
      commit('SET_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_LOADING', false)
    }
  },
  async fetchSortableList({commit}) {
    const {
      orgList
    } = state
    commit('SET_LOADING', true)
    try {
      const params = {
        sort: 'DESC',
        listType: 'sorting',
        ...(orgList.selected.length > 0 && {
          orgs: orgList.selected.map(o => o.codename)
        })
      }
      let data = await client.secureHttpClient.get(`${pathUrl}/banner`, {
        params,
        paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_SORTABLE_TOTAL', data.data.results.length)
      commit('SET_SORTABLE_BANNERS', data.data.results)
      commit('SET_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_LOADING', false)
    }
  },
  async fetchSections({commit}) {
    commit('SET_LOADING', true)
    let data = await client.secureHttpClient.get(
      `${pathUrl}/banner/section/list?isWebbrowser=${state.isBrowser}`
    )
    commit('SET_LOADING', false)
    commit('SET_SECTIONS', data.data)
  },
  async fetchContentList({commit}) {
    const {page, pageSize, textSearch} = state.sectionContent
    commit('SET_CONTENT_IS_LOADING', true)
    const codenames = state.bannerObject.orgs.map(o => o.codename)

    try {
      const params = {
        page: page,
        limit: pageSize,
        q: textSearch,
        org_codename: codenames
      }
      let data = await client.secureHttpClient.get(
        `${pathUrl}/banner/section/${state.bannerObject.section.value}/content`,
        {
          params,
          paramsSerializer: params => qs.stringify(params, {encode: false})
        }
      )
      commit('SET_CONTENT_TOTAL', data.data.total)
      commit('SET_CONTENT_LIST', data.data.results)
      commit('SET_CONTENT_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_CONTENT_IS_LOADING', false)
    }
  },
  /// EDIT call
  async changeStatus({dispatch}, {status, id}) {
    try {
      await client.secureHttpClient.patch(
        `${pathUrl}/banner/${id}/change-status`,
        {
          status: status ? bannerStatusEnum.INACTIVE : bannerStatusEnum.ACTIVE
        }
      )
      dispatch('fetch')
      return true
    } catch (e) {
      dispatch('fetch')
      Vue.$log.error(e)
      return false
    }
  },
  async deleteBanner({commit}, id) {
    commit('SET_LOADING', true)

    try {
      await client.secureHttpClient.delete(`${pathUrl}/banner/${id}`)
      commit('SET_LOADING', false)
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_LOADING', false)
      return false
    }
  },
  async create({commit}) {
    const payload = filterPayload(state.bannerObject)

    commit('SET_LOADING', true)
    try {
      await client.secureHttpClient.post(`${pathUrl}/banner/`, payload)
      commit('SET_LOADING', false)
      return true
    } catch (e) {
      commit('SET_LOADING', false)
      Vue.$log.error(e)
      return false
    }
  },
  async update({commit}, id) {
    try {
      let payload = filterPayload(state.bannerObject)

      commit('SET_LOADING', true)
      let isUpdate = await client.secureHttpClient.put(
        `${pathUrl}/banner/${id}`,
        payload
      )
      commit('SET_LOADING', false)
      return !!isUpdate.data
    } catch (e) {
      commit('SET_LOADING', false)
      Vue.$log.error(e)
      return false
    }
  },
  setContentPage({commit}, page) {
    commit('SET_CONTENT_PAGE', page)
  },
  setContentPageSize({commit}, size) {
    commit('SET_CONTENT_PAGE_SIZE', size)
  },
  setContentTextSearch({commit}, text) {
    if (text !== state.sectionContent.textSearch)
      commit('SET_CONTENT_TEXT_SEARCH', text)
  },
  setSectionId({commit}, id) {
    commit('SET_SECTION_ID', id)
  },
  setDeletePopup({commit}, status) {
    commit('SET_POPUP_DELETE', status)
  }
}

// Mutations
const mutations = {
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  RESET_STATE(state) {
    state.bannerObject = defaultState().bannerObject
    state.selectedBranch = defaultState().selectedBranch
    
    state.isImageEnPopupError = defaultState().isImageEnPopupError
    state.imageEnPopupErrorMessage = defaultState().imageEnPopupErrorMessage
    state.isImageThPopupError = defaultState().isImageThPopupError
    state.imageThPopupErrorMessage = defaultState().imageThPopupErrorMessage
    
    state.isImageEnError = defaultState().isImageEnError
    state.imageEnErrorMessage = defaultState().imageEnErrorMessage
    state.isImageThError = defaultState().isImageThError
    state.imageThErrorMessage = defaultState().imageThErrorMessage
    state.isDelete = defaultState().isDelete
    state.isUnpublished = defaultState().isUnpublished
    state.isPublished = defaultState().isPublished
    state.isExpired = defaultState().isExpired
  },
  RESET_LIST_STATE(state) {
    state.currentPage = defaultState().currentPage
    state.pageSize = defaultState().pageSize
    state.textSearch = defaultState().textSearch
    state.filterStatus = defaultState().filterStatus
    state.filterType = defaultState().filterType
    state.sortBy = defaultState().sortBy
    state.sortDesc = defaultState().sortDesc
    state.orgList = defaultState().orgList
  },
  RESET_SECTION(state) {
    // Section is
    // (1) Internal Link (In-App) > Application Menu
    // (2) External Link (URL) > Display Type
    state.bannerObject.section = defaultState().bannerObject.section
    state.bannerObject.section_id = defaultState().bannerObject.section_id
  },
  RESET_CONTENT(state) {
    // External Link (URL) > Browser URL
    state.bannerObject.browser_url = defaultState().bannerObject.browser_url
    // Internal Link (In-App) > Content
    state.bannerObject.content_id = defaultState().bannerObject.content_id
    // Internal Link (In-App) > Content Array
    state.bannerObject.linkedContent = defaultState().bannerObject.linkedContent
  },
  RESET_BROWSER_MODE(state) {
    state.bannerObject.browser_url = defaultState().bannerObject.browser_url
    state.bannerObject.section = defaultState().bannerObject.section
    state.bannerObject.linkedContent = defaultState().bannerObject.linkedContent
  },
  RESET_START_DATETIME(state) {
    state.bannerObject.period_start_date = defaultState().bannerObject.period_start_date
    state.bannerObject.period_start_time = defaultState().bannerObject.period_start_time
  },
  RESET_END_DATETIME(state) {
    state.bannerObject.period_end_date = defaultState().bannerObject.period_end_date
    state.bannerObject.period_end_time = defaultState().bannerObject.period_end_time
  },
  RESET_CONTENT_TABLE(state) {
    // Internal Link (In-App) > Select Content > Table
    state.sectionContent.isLoading = defaultState().sectionContent.isLoading
    state.sectionContent.textSearch = defaultState().sectionContent.textSearch
    state.sectionContent.page = defaultState().sectionContent.page
    state.sectionContent.pageSize = defaultState().sectionContent.pageSize
    state.sectionContent.totalSize = defaultState().sectionContent.totalSize
  },
  RESET_POPUP_IMAGE(state) {
    state.bannerObject.contents[0].slide_image = defaultState().bannerObject.contents[0].slide_image
    state.bannerObject.contents[1].slide_image = defaultState().bannerObject.contents[1].slide_image
    state.isImageEnPopupError = defaultState().isImageEnPopupError
    state.imageEnPopupErrorMessage = defaultState().imageEnPopupErrorMessage
    state.isImageThPopupError = defaultState().isImageThPopupError
    state.imageThPopupErrorMessage = defaultState().imageThPopupErrorMessage
  },
  RESET_SEARCH_AND_FILTER(state) {
    state.banners = defaultState().banners
    state.filterStatus = defaultState().filterStatus
    state.filterType = defaultState().filterType
    state.textSearch = defaultState().textSearch
  },
  SET_EDIT_MODE(state, isEdit) {
    state.isEditMode = isEdit
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  SET_TOTAL(state, total) {
    state.total = total
  },
  SET_SORTABLE_TOTAL(state, total) {
    state.sortableTotal = total
  },
  SET_FILTER_TYPE(state, type) {
    state.filterType = type
  },
  SET_FILTER_STATUS(state, status) {
    state.filterStatus = status
  },
  SET_BANNERS(state, banners) {
    state.banners = banners
  },
  SET_SORTABLE_BANNERS(state, banners) {
    state.sortableList = banners
  },
  SET_SECTIONS(state, sections) {
    state.sections = sections
  },
  SET_BANNER_OBJECT(state, banner) {
    state.bannerObject = {...defaultState().bannerObject, ...banner}
    const isPopupImage = state.bannerObject.contents[0].slide_image !== null
    state.isBrowser = !!(banner.browser_url || isPopupImage)
    state.bannerObject.section_id =
      Object.keys(banner.section).length > 0
        ? banner.section.value
        : defaultState().bannerObject.section_id
    const branches = state.bannerObject.orgs
      .filter(o => o.selected === true)
      .map(o => o.codename)
    state.selectedBranch = branches.length > 0 ? branches[0] : ''
    state.bannerObject.content_id = banner.content_id
      ? banner.content_id.toString()
      : defaultState().bannerObject.content_id

    if (banner.browser_url || isPopupImage) {
      state.bannerObject.link_to = typeEnum.LINK_TO_WEBSITE
    } else if (banner.linkedContent) {
      state.bannerObject.link_to = typeEnum.LINK_WITHIN_APP
      state.bannerObject.section = {
        value: banner.section.value,
        text: sectionEnumText[banner.section.text],
        type: banner.section.text
      }
    } else {
      state.bannerObject.link_to = typeEnum.NO_LINK
    }

    state.isUnpublished = moment(banner.period_start_date).isAfter()
    state.isPublished = moment(banner.period_start_date).isSameOrBefore() && moment(banner.period_end_date).isSameOrAfter()
    state.isExpired = moment(banner.period_end_date).isBefore()

    const start = !banner.period_start_date
      ? {dateString: '', timeString: ''}
      : splitToDateAndTime(banner.period_start_date)
    state.bannerObject.period_start_date = start.dateString
    state.bannerObject.period_start_time = start.timeString
    const end = !banner.period_end_date
      ? {dateString: '', timeString: ''}
      : splitToDateAndTime(banner.period_end_date)
    state.bannerObject.period_end_date = end.dateString
    state.bannerObject.period_end_time = end.timeString
  },
  SET_EDIT_ORDER_OBJECT(state, banner) {
    state.editOrderObject = banner
  },
  SET_LINKED_CONTENT(state, content) {
    state.bannerObject.linkedContent = content
  },
  SET_PERIOD_END_DATETIME(state, endDatetime) {
    const end = splitToDateAndTime(endDatetime)
    state.bannerObject.period_end_date = end.dateString
    state.bannerObject.period_end_time = end.timeString
  },
  SET_LOADING(state, status) {
    state.isLoading = status
  },
  SET_IS_LOADING_BRANCH(state, isLoading) {
    state.isLoadingBranch = isLoading
  },
  SET_TAB_STATUS(state, status) {
    state.statusTab = status
  },
  SET_POPUP_IMAGE_ERROR(state, {lang, isError, message}) {
    if (lang === 'en') {
      state.isImageEnPopupError = isError
      state.imageEnPopupErrorMessage = message
    }
    if (lang === 'th') {
      state.isImageThPopupError = isError
      state.imageThPopupErrorMessage = message
    }
  },
  SET_POPUP_IMAGE(state, {lang, file}) {
    convertFileToBase64(file)
      .then(base64 => {
        if (lang === 'th') {
          state.bannerObject.contents[0].slide_image = base64
        }
        if (lang === 'en') {
          state.bannerObject.contents[1].slide_image = base64
        }
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_IMAGE_ERROR(state, {lang, isError, message}) {
    if (lang === 'en') {
      state.isImageEnError = isError
      state.imageEnErrorMessage = message
    }
    if (lang === 'th') {
      state.isImageThError = isError
      state.imageThErrorMessage = message
    }
  },
  SET_IMAGE(state, {lang, file}) {
    convertFileToBase64(file)
      .then(base64 => {
        if (lang === 'th') {
          state.bannerObject.contents[0].thumbnail = base64
        }
        if (lang === 'en') {
          state.bannerObject.contents[1].thumbnail = base64
        }
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_SECTION_ID(state, id) {
    state.bannerObject.section_id = id
  },
  SET_CONTENT_ID(state, content_id) {
    state.bannerObject.content_id = content_id
  },
  SET_BROWSER_MODE(state, value) {
    state.isBrowser = value
  },
  SET_ORG_LIST(state, org) {
    state.orgObject = org.data
  },
  SET_ORG_FILTER(state, org) {
    const selected = []

    if (org.data.length === 1) {
      org.data.forEach(d =>
        selected.push({
          codename: d.codename,
          selected: true
        })
      )
    }
    state.orgList.selected = selected
    state.orgList.all = org.data
  },
  SET_SELECTED_BRANCH(state, codename) {
    state.selectedBranch = codename
  },
  SET_CONTENT_IS_LOADING(state, isLoading) {
    state.sectionContent.isLoading = isLoading
  },
  SET_CONTENT_LIST(state, data) {
    state.contentList = data
  },
  SET_CONTENT_TOTAL(state, total) {
    state.sectionContent.totalSize = total
  },
  SET_CONTENT_PAGE(state, page) {
    state.sectionContent.page = page
  },
  SET_CONTENT_PAGE_SIZE(state, pageSize) {
    state.sectionContent.pageSize = pageSize
  },
  SET_CONTENT_TEXT_SEARCH(state, text) {
    state.sectionContent.textSearch = text
  },
  SET_POPUP_DELETE(state, status) {
    state.isDelete = status
  },
  SET_SHOW_PREVIEW(state, status) {
    state.isPreview = status
  },
  SET_SHOW_EDIT_ORDER(state, status) {
    state.isEditOrder = status
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
