import client from '@/apis/http-client'
import Vue from 'vue'
import {channelEnum} from '@/constants/order'
let pathUrl = `/por/api`
const pathOrgUrl = `sso/rbac/organization/permission`
import qs from 'qs'
var _ = require('lodash')

// Initial state.
const defaultState = () => {
  return {
    isLoading: false,
    isLoadingExport: false,
    orderList: [],
    orderCsv: '',
    total: 0,
    // params
    currentPage: 1,
    pageSize: 25,
    textSearch: '',
    filterStatus: '',
    filterChannel: null,
    filterPaymentSuccessDate: [],
    filterRefundedDate: [],
    filterOrderedDate: [],
    orgList: {
      all: [],
      selected: []
    },
    sortBy: 'order_no',
    sortDesc: 'DESC',
    orderDetail: {}
  }
}

// State
let state = defaultState()

function createParams(state) {
  const {
    currentPage,
    pageSize,
    textSearch,
    filterStatus,
    filterChannel,
    filterPaymentSuccessDate,
    filterRefundedDate,
    filterOrderedDate,
    orgList,
    sortBy,
    sortDesc
  } = state

  const params = {
    lang: 'en',
    page: currentPage,
    limit: pageSize,
    q: `${encodeURIComponent(textSearch)}`,
    orderBy: sortBy,
    sort: sortDesc,
    ...(filterChannel !== null && filterChannel !== channelEnum.ALL && {payment_channel: filterChannel}),
    ...(filterPaymentSuccessDate.length > 0 && {
      paid_at_start_date: filterPaymentSuccessDate[0],
      paid_at_end_date: filterPaymentSuccessDate[1]
    }),
    ...(filterRefundedDate.length > 0 && {
      refunded_at_start_date: filterRefundedDate[0],
      refunded_at_end_date: filterRefundedDate[1]
    }),
    ...(filterStatus && {status: filterStatus}),
    ...(orgList.selected.length > 0 && {
      org_codename: orgList.selected.map(o => o.codename)
    }),
    ...(filterOrderedDate.length > 0 && {
      order_creation_start_date: filterOrderedDate[0],
      order_creation_end_date: filterOrderedDate[1]
    })
  }
  return params
}

// Actions
const actions = {
  // API calls
  async fetch({commit}) {
    commit('SET_IS_LOADING', true)
    const params = createParams(state)
    try {
      let data = await client.secureHttpClient.get(`${pathUrl}/orders`, {
        params,
        paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_TOTAL', data.data.total)
      commit('SET_ORDER_LIST', data.data.data)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async fetchDetail({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(
      `${pathUrl}/orders/${id}?lang=th`
    )
    commit('SET_ORDER_DETAIL', data.data.data)
    commit('SET_IS_LOADING', false)
  },
  async fetchExportCsv({commit}) {
    let params = createParams(state)
    params = _.omit(params, 'page')
    params = _.omit(params, 'limit')
    params = {export: 'csv', ...params}
    commit('SET_IS_LOADING_EXPORT', true)

    try {
      let data = await client.secureHttpClient.get(
        `${pathUrl}/exports/orders`,
        {
          params,
          paramsSerializer: params => qs.stringify(params, {encode: false})
        }
      )
      commit('SET_ORDER_CSV', data.data.data)
      commit('SET_IS_LOADING_EXPORT', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING_EXPORT', false)
    }
  },
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  async setListOrgInfo({commit}) {
    let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=View&category=OrderPackage`
    )
    commit('SET_ORG_FILTER', data.data)
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setTextSearch({commit}, text) {
    if (text === state.textSearch) return
    commit('SET_TEXT_SEARCH', text)
  },
  setFilterStatus({commit}, listStatus) {
    commit('SET_FILTER_STATUS', listStatus)
  },
  setFilterChannel({commit}, channel) {
    commit('SET_FILTER_CHANNEL', channel)
  },
  setFilterPaymentSuccessDate({commit}, orderDates) {
    commit('SET_FILTER_PAYMENT_SUCCESS_DATE', orderDates)
  },
  setFilterRefundedDate({commit}, refundedDates) {
    commit('SET_FILTER_REFUNDED_DATE', refundedDates)
  },
  setFilterOrderedDate({commit}, orderedDates) {
    commit('SET_FILTER_ORDERED_DATE', orderedDates)
  }
}

// Mutations
const mutations = {
  RESET_LIST_STATE(state) {
    state.textSearch = defaultState().textSearch
    state.filterStatus = defaultState().filterStatus
    state.filterChannel = defaultState().filterChannel
    state.filterPaymentSuccessDate = defaultState().filterPaymentSuccessDate
    state.filterRefundedDate = defaultState().filterRefundedDate
    state.orgList = defaultState().orgList
    state.sortBy = defaultState().sortBy
    state.sortDesc = defaultState().sortDesc
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_IS_LOADING_EXPORT(state, isLoadingExport) {
    state.isLoadingExport = isLoadingExport
  },
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  SET_TOTAL(state, total) {
    state.total = total
  },
  SET_ORDER_LIST(state, orders) {
    state.orderList = orders
  },
  SET_ORDER_CSV(state, csv) {
    state.orderCsv = csv
  },
  SET_ORDER_DETAIL(state, detail) {
    state.orderDetail = detail
  },
  SET_ORG_FILTER(state, org) {
    const selected = []

    if (org.data.length === 1) {
      org.data.forEach(d =>
        selected.push({
          codename: d.codename,
          selected: true
        })
      )
    }
    state.orgList.selected = selected
    state.orgList.all = org.data
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_FILTER_STATUS(state, listStatus) {
    state.filterStatus = listStatus
  },
  SET_FILTER_CHANNEL(state, channel) {
    state.filterChannel = channel
  },
  SET_FILTER_PAYMENT_SUCCESS_DATE(state, paymentSuccessDates) {
    state.filterPaymentSuccessDate = paymentSuccessDates
  },
  SET_FILTER_REFUNDED_DATE(state, refundedDates) {
    state.filterRefundedDate = refundedDates
  },
  SET_FILTER_ORDERED_DATE(state, orderedDates) {
    state.filterOrderedDate = orderedDates
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
