import client from '@/apis/http-client'
// import moment from 'moment'
import Vue from 'vue'
import {disabledRoles} from '@/constants/role-permission'
import {isStaff} from '@/helper/helper'
import qs from 'qs'
const pathUrl = `por/api`
const pathOrgUrl = `por/api/organization`
// Initial state.
const defaultState = () => {
  return {
    // Filters
    filterStatus: '',
    textSearch: '',
    deleteObj: {id: 0, user_count: 0},
    isDelete: false,
    // List of roles
    isLoading: false,
    rolesList: [],
    updateIdList: [],
    currentPage: 1,
    totalRoles: 0,
    pageSize: 25,
    total: 0,
    sortBy: 'updated_at',
    sortDesc: 'DESC',
    status: 0,
    orgObject: [],
    // Filters
    orgList: {
      all: [],
      selected: []
    },
    orgs: [],
    permissions: [],
    allPermissions: [],
    users: [],
    userLoading: false,
    roleObject: {
      codename: '',
      org: '',
      description: '',
      permissions: [],
      users: [],
      is_active: true,
      app: 'portal',
      user_roles: []
    },
    isEditMode: false,
    userList: [],
    createdRole: {}
  }
}

// State
let state = defaultState()

// Actions
const actions = {
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  resetState({commit}) {
    commit('RESET_STATE')
  },
  resetCreatedRoleState({commit}) {
    commit('RESET_CREATED_ROLE_STATE')
  },
  setEditMode({commit}, isEditable) {
    commit('SET_EDIT_MODE', isEditable)
  },
  setDeleteObj({commit}, obj) {
    commit('SET_DELETE_OBJ', obj)
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setFilterStatus({commit}, status) {
    let selected = ''
    if (status.length === 1) {
      selected = status[0]
    }
    commit('SET_FILTER_STATUS', selected)
  },
  setTextSearch({commit}, text) {
    commit('SET_TEXT_SEARCH', text)
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setSelectedId({commit}, ids) {
    commit('SET_UPDATE_ID', ids)
  },
  setDeletePopup({commit}, status) {
    commit('SET_POPUP_DELETE', status)
  },
  async setOrgInfo({commit}) {
    commit('SET_IS_LOADING', true)
    let data
    if (isStaff()) {
      data = await client.secureHttpClient.get(
        `${pathOrgUrl}/permission?permission=Edit&category=AppConfig`
      )
    } else {
      data = await client.secureHttpClient.get(`${pathOrgUrl}`, {
        params: {is_active: true}
      })
    }
    commit('SET_ORG_FILTER', data.data)
    commit('SET_ORG_LIST', data.data)
    commit('SET_IS_LOADING', false)
  },
  /// EDIT call
  async update({commit, dispatch}, status) {
    let ids = state.updateIdList.map(({id}) => id)
    let body = {roles: ids, is_active: status}
    try {
      await client.secureHttpClient.post(`${pathUrl}/role/bulk_update`, body)
      dispatch('fetch')
      commit('SET_UPDATE_ID', [])
      return true
    } catch (e) {
      Vue.$log.error(e)
      return false
    }
  },
  async deleteRole({commit, dispatch}) {
    try {
      commit('SET_IS_LOADING', true)
      await client.secureHttpClient.delete(
        `${pathUrl}/role/${state.deleteObj.id}`
      )
      commit('SET_POPUP_DELETE', false)
      commit('SET_DELETE_OBJ', defaultState().deleteObj)
      dispatch('fetch')
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_POPUP_DELETE', false)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async getUsers({commit}) {
    commit('SET_USER_LOADING', true)
    let users = await client.secureHttpClient.get(`${pathUrl}/admin/all`)
    commit('SET_USER_LIST', {users: users.data.data, usersInRole: state.roleObject.user_roles})
    commit('SET_USER_LOADING', false)
  },
  async updateUsers({commit}, data) {
    commit('SET_USER_LIST', data)
  },
  async getPermission({commit}) {
    let permissions = await client.secureHttpClient.get(
      `${pathUrl}/permission/all`
    )
    commit('SET_ALL_PERMISSIONS', permissions.data.data)
    let results = actions.mergePermission(permissions.data.data, false)
    commit('SET_PERMISSIONS', results)
  },
  async setRole({commit}, pk) {
    commit('SET_IS_LOADING', true)
    let role = await client.secureHttpClient.get(`${pathUrl}/role/${pk}`)
    if (role) {
      commit('SET_ROLE', role.data)
    }
    commit('SET_IS_LOADING', false)
  },
  // API calls
  async fetch({commit}) {
    commit('SET_IS_LOADING', true)
    const {
      currentPage,
      pageSize,
      textSearch,
      filterStatus,
      sortBy,
      sortDesc,
      orgList
    } = state
    try {
      const order_by = sortDesc === 'DESC' ? `-${sortBy}` : sortBy
      let params = {
        page: currentPage,
        page_size: pageSize,
        search: textSearch,
        order_by,
        ...(orgList.selected.length > 0 && {
          codename: orgList.selected.map(o => o.codename)
        })
      }
      params =
        filterStatus === '' ? params : {...params, is_active: filterStatus}

      let data = await client.secureHttpClient.get(`${pathUrl}/role`, {
        params,
        paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_TOTAL_ROLES', data.data.total)
      commit('SET_ROLES_LIST', data.data.data)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async updatePkg({commit}, payload) {
    const {pkg, id} = payload
    commit('SET_IS_LOADING', true)
    pkg.status = parseInt(pkg.status)
    try {
      let data = await client.secureHttpClient.patch(
        `${pathUrl}/role/${id}`,
        pkg
      )
      commit('SET_IS_LOADING', false)
      return data.data.updated_at
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async create({commit}, payload) {
    payload.description = payload.codename
    commit('SET_IS_LOADING', true)
    commit('RESET_CREATED_ROLE_STATE')
    try {
      const data = await client.secureHttpClient.post(
        `${pathUrl}/role`,
        payload
      )
      commit('SET_IS_LOADING', false)
      commit('SET_CREATED_ROLE', data.data)
    } catch (e) {
      commit('SET_IS_LOADING', false)
      throw new Error(e.response.data.message)
    }
  },
  async edit({commit}, payload) {
    commit('SET_IS_LOADING', true)
    const {pk, object} = payload
    object.users = object.user_roles.map(user_role => {
      return user_role.user.id
    })
    try {
      await client.secureHttpClient.patch(`${pathUrl}/role/${pk}`, object)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      commit('SET_IS_LOADING', false)
      throw new Error(e.response.data.message)
    }
  },
  mergePermission(permission, isExists) {
    let results = []
    permission.map(p => {
      let method
      let functionName
      let codeName
      if (p.codename.startsWith('CanView')) {
        method = 'view'
        functionName = p.codename.replace('CanView', '')
        codeName = p.codename
      } else if (p.codename.startsWith('CanAdd')) {
        method = 'add'
        functionName = p.codename.replace('CanAdd', '')
        codeName = p.codename
      } else if (p.codename.startsWith('CanEdit')) {
        method = 'edit'
        functionName = p.codename.replace('CanEdit', '')
        codeName = p.codename
      } else if (p.codename.startsWith('CanDelete')) {
        method = 'remove'
        functionName = p.codename.replace('CanDelete', '')
        codeName = p.codename
      } else if (p.codename.startsWith('CanBulk')) {
        method = 'bulk'
        functionName = p.codename.replace('CanBulk', '')
        codeName = p.codename
      } else return null
      const index = results.findIndex(
        result => result.functionName === functionName
      )
      if (index >= 0) {
        results[index][method] = isExists ? isExists : false
      } else {
        results.push({
          functionName,
          codeName,
          view: isExists ? isExists : false,
          add: method === 'add',
          edit: method === 'edit',
          remove: method === 'remove',
          bulk: method === 'bulk'
        })
      }
    })
    return results
  },
  async createAdminRole({state, commit, dispatch}, {codename, is_active}) {
    commit('RESET_STATE')
    await dispatch('getPermission')
    const payload = {
      codename: `ADMIN-${codename}`,
      org: codename,
      description: `ADMIN-${codename}`,
      permissions: state.allPermissions
        .map(p => p.codename)
        .filter(p => !disabledRoles.includes(p)),
      users: [],
      is_active: is_active,
      app: 'portal'
    }
    await dispatch('create', payload)
  }
}

const mutations = {
  RESET_STATE(state) {
    state.roleObject = defaultState().roleObject
  },
  RESET_LIST_STATE(state) {
    state.orgList = defaultState().orgList
    state.filterStatus = defaultState().filterStatus
    state.textSearch = defaultState().textSearch
    state.currentPage = defaultState().currentPage
    state.pageSize = defaultState().pageSize
    state.sortBy = defaultState().sortBy
    state.sortDesc = defaultState().sortDesc
  },
  RESET_CREATED_ROLE_STATE(state) {
    state.createdRole = defaultState().createdRole
  },
  SET_EDIT_MODE(state, isEdit) {
    state.isEditMode = isEdit
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  SET_FILTER_STATUS(state, status) {
    state.filterStatus = status
  },
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  SET_TOTAL_ROLES(state, total) {
    state.totalRoles = total
  },
  SET_ORG_FILTER(state, org) {
    const selected = []

    if (org.data.length === 1) {
      org.data.forEach(d =>
        selected.push({
          codename: d.codename,
          selected: true
        })
      )
    }
    state.orgList.selected = selected
    state.orgList.all = org.data
  },
  SET_ORG_LIST(state, org) {
    state.orgObject = org
  },
  SET_ROLES_LIST(state, roles) {
    state.rolesList = roles
  },
  SET_ROLE(state, role) {
    state.roleObject = role
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_UPDATE_ID(state, ids) {
    state.updateIdList = ids
  },
  SET_POPUP_DELETE(state, status) {
    state.isDelete = status
  },
  SET_DELETE_OBJ(state, obj) {
    state.deleteObj = obj
  },
  SET_USER_LIST(state, {users, usersInRole}) {
    const userWithAdded = []
    for (let user of users) {
      if (usersInRole.find(rm => rm.user.email === user.email)) {
        userWithAdded.push({...user, ...{disabled: true}})
      } else {
        userWithAdded.push({...user, ...{disabled: false}})
      }
    }
    state.users = userWithAdded
  },
  SET_ALL_PERMISSIONS(state, permissions) {
    state.allPermissions = permissions
  },
  SET_PERMISSIONS(state, permissions) {
    let results = []
    if (state.roleObject.permissions.length > 0) {
      results = actions.mergePermission(state.roleObject.permissions, true)
    }
    permissions = permissions.map(
      obj => results.find(o => o.functionName === obj.functionName) || obj
    )
    state.permissions = permissions
  },
  SET_USER_LOADING(state, loading) {
    state.userLoading = loading
  },
  SET_CREATED_ROLE(state, role) {
    state.createdRole = role
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
