import {isLowercase} from '@/helper/helper'

export const typeEnum = {
  DEPARTMENT: 'department',
  DOCTOR: 'doctor'
}

export const preconditionTypes = {
  [typeEnum.DEPARTMENT]: [],
  [typeEnum.DOCTOR]: [typeEnum.DEPARTMENT]
}

export const typeText = {
  [typeEnum.DEPARTMENT]: 'Department',
  [typeEnum.DOCTOR]: 'Doctor'
}

export const preferenceEnum = {
  ADD: 'create',
  // ADD_UPDATE: 'create_and_update'
}

export const preferenceText = {
  [preferenceEnum.ADD]: 'Add new records only',
  // [preferenceEnum.ADD_UPDATE]: 'Add new and Update existing records'
}

export const fileTypeEnum = {
  CSV: 'text/csv',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const fileTypeText = {
  [fileTypeEnum.CSV]: 'csv.',
  [fileTypeEnum.XLS]: 'xls.',
  [fileTypeEnum.XLSX]: 'xlsx.'
}

export const departmentRules = {
  Branch: v => !!v && typeof v == 'string' && isLowercase(v),
  'Department code': v => !!v && (typeof v == 'string' || typeof v == 'number'),
  'Department/Clinic name [ไทย]': v => !!v && typeof v == 'string',
  'Department/Clinic name [Eng]': v => !!v && typeof v == 'string',
}

export const doctorRules = {
  Branch: v => !!v && typeof v == 'string' && isLowercase(v),
  prefix_name: v => !!v && typeof v == 'string',
  license_no: v => !!v && (typeof v == 'string' || typeof v == 'number'),
  'Doctor name (TH)': v => !!v && typeof v == 'string',
  'Doctor name (EN)': v => !!v && typeof v == 'string',
  'Department code': v => !!v && (typeof v == 'string' || typeof v == 'number'),
}
