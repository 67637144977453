import client from '@/apis/http-client'
const fetchDataCouponReport = async ({commit}, {page, search, limit, sortBy, sortDesc, start_date, end_date, filter}) => {
    //TODO: debug data input
    try {
        let urlString = `/por/api/getAllCouponReport?page=${page}&&limit=${limit}&&q=${search}&&filter=${filter}&&start_date=${start_date}&&end_date=${end_date}`
        if (sortBy.length !== 0) {
            urlString = `/por/api/getAllCouponReport?page=${page}&&limit=${limit}&&q=${search}&&orderby=${sortBy}&&sort=${sortDesc[0] === true ? 'DESC' : 'ASC'}&&filter=${filter}&&start_date=${start_date}&&end_date=${end_date}`
        }
        let data = await client.secureHttpClient.get(urlString);
        commit('SET_DATA', data)
        return data !== undefined ? data : {};
    } catch (e) {
        return false;
    }
}
const actions = {
    fetchDataCouponReport
}
const mutations = {
    SET_TOKEN(state, {token}) {
        state.token = token;
    }
}
export default {
    namespaced: true,
    actions,mutations
}