import client from '@/apis/http-client'
import qs from 'qs'
// import moment from 'moment'
import {convertFileToBase64} from '../helper/helper'
import Vue from 'vue'
const pathUrl = `por/api/organization`
const mobilePathUrl = `por/api/mobile/organization`
const pathOrgUrl = `sso/rbac/organization/permission`

// Initial state.
const defaultState = () => {
  return {
    // Filters
    orgList: {
      all: [],
      selected: []
    },
    filterStatus: '',
    textSearch: '',
    deleteObj: {codename: '', user_count: 0},
    isDelete: false,
    // List of users
    isLoading: false,
    organizationsList: [],
    updateIdList: [],
    currentPage: 1,
    totalUsers: 0,
    totalOrganizations: 0,
    pageSize: 25,
    total: 0,
    sortBy: 'updated_at',
    sortDesc: 'DESC',
    status: 0,
    delete_users: [],
    organizationObject: {
      initial: '',
      bu_code: null,
      codename: '',
      name_th: '',
      name_en: '',
      name: '',
      address_th: '',
      address_en: '',
      address: '',
      phone: '',
      fax: '',
      email: '',
      website: '',
      facebook: '',
      facebook_name: '',
      facebook_messenger_official: '',
      line_official: '',
      line_name: '',
      latitude: null,
      longitude: null,
      is_active: true,
      user_org: [],
      image_url: '',
      binding_card_logo_url: '',
      create_by_user: null,
      update_by_user: null
    },
    isEditMode: false,
    isHeaderImageError: false,
    headerImageErrorMessage: '',
    isBindingImageError: false,
    bindingImageErrorMessage: ''
  }
}

// State
let state = defaultState()

// Actions
const actions = {
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  resetState({commit}) {
    commit('RESET_STATE')
  },
  setEditMode({commit}, isEditable) {
    commit('SET_EDIT_MODE', isEditable)
  },
  setDeleteObj({commit}, obj) {
    commit('SET_DELETE_OBJ', obj)
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setFilterStatus({commit}, status) {
    let selected = ''
    if (status.length === 1) {
      selected = status[0]
    }
    commit('SET_FILTER_STATUS', selected)
  },
  setTextSearch({commit}, text) {
    if (text !== state.textSearch) commit('SET_TEXT_SEARCH', text)
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    }  else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setSelectedId({commit}, ids) {
    commit('SET_UPDATE_ID', ids)
  },
  setDeletePopup({commit}, status) {
    commit('SET_POPUP_DELETE', status)
  },
  setUserDelete({commit}, id) {
    commit('SET_USER_DELETE', id)
  },
  setHeaderImage({commit}, file) {
    commit('SET_HEADER_IMAGE', file)
  },
  setBindingImage({commit}, file) {
    commit('SET_BINDING_IMAGE', file)
  },
  setHeaderImageError({commit}, data) {
    commit('SET_HEADER_IMAGE_ERROR', data)
  },
  setBindingImageError({commit}, data) {
    commit('SET_BINDING_IMAGE_ERROR', data)
  },
  /// EDIT call
  async deleteOrganization({commit, dispatch}) {
    try {
      commit('SET_IS_LOADING', true)
      await client.secureHttpClient
        .delete(`${mobilePathUrl}/${state.deleteObj.codename}`)
        .then(() =>
          client.secureHttpClient.delete(
            `${pathUrl}/${state.deleteObj.codename}`
          )
        )
        .catch(e => {
          if (e.response.status === 404) {
            return client.secureHttpClient.delete(
              `${pathUrl}/${state.deleteObj.codename}`
            )
          }
          return e
        })
      commit('SET_POPUP_DELETE', false)
      commit('SET_DELETE_OBJ', defaultState().deleteObj)
      dispatch('fetch')
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_POPUP_DELETE', false)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  // API calls
  async fetch({commit}) {
    commit('SET_IS_LOADING', true)
    const {
      currentPage,
      pageSize,
      textSearch,
      filterStatus,
      sortBy,
      sortDesc,
      orgList
    } = state
    try {
      const order_by = sortDesc === 'DESC' ? `-${sortBy}` : sortBy
      let params = {
        category: 'AppConfig',
        page: currentPage,
        page_size: pageSize,
        search: textSearch,
        order_by,
        ...(orgList.selected.length > 0 && {
          codename: orgList.selected.map(o => o.codename)
        })
      }
      params =
        filterStatus === '' ? params : {...params, is_active: filterStatus}
      let data = await client.secureHttpClient.get(`${pathUrl}`,{
          params,
          paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_TOTAL_ORGANIZATIONS', data.data.total)
      commit('SET_ORGANIZATION_LIST', data.data.data)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async update({commit, dispatch}, status) {
    commit('SET_IS_LOADING', true)
    let ids = state.updateIdList.map(({codename}) => codename)
    let body = {organizations: ids, is_active: status}
    try {
      await client.secureHttpClient.post(`${pathUrl}/bulk_update`, body)
      dispatch('fetch')
      commit('SET_UPDATE_ID', [])
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async validateName(_, payload) {
    try {
      await client.secureHttpClient.post(`${pathUrl}/validation`, payload)
      return true
    } catch {
      return false
    }
  },
  async setOrgObject({commit}, pk) {
    commit('SET_IS_LOADING', true)
    let role = await client.secureHttpClient.get(`${pathUrl}/${pk}`)
    if (role) {
      commit('SET_ORG_OBJECT', role.data)
    }
    commit('SET_IS_LOADING', false)
  },
  async create({commit}) {
    let payload = state.organizationObject
    payload.name = state.organizationObject.name_en
    payload.address = state.organizationObject.address_en
    payload.description = payload.codename
    commit('SET_IS_LOADING', true)
    try {
      await client.secureHttpClient.post(`${pathUrl}`, payload)
      await client.secureHttpClient.post(`${mobilePathUrl}`, payload)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      commit('SET_IS_LOADING', false)
      throw new Error(e.response.data.message)
    }
  },
  async edit({commit}, payload) {
    commit('SET_IS_LOADING', true)
    const {pk, object} = payload
    object.name = state.organizationObject.name_en
    object.address = state.organizationObject.address_en
    try {
      await client.secureHttpClient.patch(`${mobilePathUrl}/${pk}`, object)
      await client.secureHttpClient.patch(`${pathUrl}/${pk}`, object)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      commit('SET_IS_LOADING', false)
      throw new Error(e.response.data.message)
    }
  },
  async setOrgInfo({commit}) {
    const data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=View&category=Organization`
    )
    commit('SET_ORG_FILTER', data.data)
  },
}

const mutations = {
  RESET_STATE(state) {
    state.organizationObject = defaultState().organizationObject
    state.isEditMode = defaultState().isEditMode
    state.isHeaderImageError = defaultState().isHeaderImageError
    state.headerImageErrorMessage = defaultState().headerImageErrorMessage
    state.isBindingImageError = defaultState().isBindingImageError
    state.bindingImageErrorMessage = defaultState().bindingImageErrorMessage
  },
  RESET_LIST_STATE(state) {
    state.orgList = defaultState().orgList
    state.filterStatus = defaultState().filterStatus
    state.textSearch = defaultState().textSearch
    state.currentPage = defaultState().currentPage
    state.pageSize = defaultState().pageSize
    state.sortBy = defaultState().sortBy
    state.sortDesc = defaultState().sortDesc
  },
  SET_EDIT_MODE(state, isEdit) {
    state.isEditMode = isEdit
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  SET_FILTER_STATUS(state, status) {
    state.filterStatus = status
  },
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  SET_TOTAL_ORGANIZATIONS(state, total) {
    state.totalOrganizations = total
  },
  SET_ORGANIZATION_LIST(state, organizations) {
    state.organizationsList = organizations
  },
  SET_ORGANIZATION(state, organization) {
    state.organizationObject = organization
  },
  SET_ORG_FILTER(state, org) {
    const selected = []

    if (org.data.length === 1) {
      org.data.forEach(d =>
        selected.push({
          codename: d.codename,
          selected: true
        })
      )
    }
    state.orgList.selected = selected
    state.orgList.all = org.data
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_UPDATE_ID(state, ids) {
    state.updateIdList = ids
  },
  SET_POPUP_DELETE(state, status) {
    state.isDelete = status
  },
  SET_DELETE_OBJ(state, obj) {
    state.deleteObj = obj
  },
  SET_ORG_OBJECT(state, org) {
    org.binding_card_logo_url = org.binding_card_logo_url !== null ? org.binding_card_logo_url : ""
    state.organizationObject = org
  },
  SET_USER_DELETE(state, id) {
    state.delete_users.push(id)
  },
  SET_HEADER_IMAGE(state, file) {
    convertFileToBase64(file)
      .then(base64 => {
        state.organizationObject.image_url = base64
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_HEADER_IMAGE_ERROR(state, {isError, message}) {
    state.isHeaderImageError = isError
    state.headerImageErrorMessage = message
  },
  SET_BINDING_IMAGE(state, file) {
    convertFileToBase64(file)
      .then(base64 => {
        state.organizationObject.binding_card_logo_url = base64
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_BINDING_IMAGE_ERROR(state, {isError, message}) {
    state.isBindingImageError = isError
    state.bindingImageErrorMessage = message
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
