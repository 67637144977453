let pathUrl = `/por/api`
// Initial state.
import client from '@/apis/http-client'
import qs from 'qs'
import Vue from 'vue'

const defaultState = () => {
  return {
    qrCodeObject: {
      id: 0,
      org_codename: '',
      org: {
        name: ''
      },
      validation_code: '',
      created_date: '',
      update_date: ''
    },
    isLoading: false,
    qrCodeList: [],
    total: 0,
    sortBy: 'update_date',
    sortDesc: 'DESC',
    textSearch: '',
    pageSize: 25,
    currentPage: 1,
    errorMessage: ''
  }
}

// State
let state = defaultState()

// Actions
const actions = {
  async fetch({commit}) {
    const {textSearch, sortBy, sortDesc} = state
    commit('SET_IS_LOADING', true)
    try {
      const params = {
        q: textSearch,
        orderBy: sortBy,
        sort: sortDesc
      }
      let data = await client.secureHttpClient.get(
        `${pathUrl}/org-package-validation-codes`,
        {
          params,
          paramsSerializer: params => qs.stringify(params, {encode: false})
        }
      )
      commit('SET_TOTAL', data.data.data.length)
      commit('SET_QR_CODE_LIST', data.data.data)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async setQrCodeObject({commit}, codename) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/org-package-validation-codes/${codename}`)
    if (data) {
      commit('SET_QR_CODE', data.data.data)
    }
    commit('SET_IS_LOADING', false)
  },
  async update({commit}, payload) {
    commit('SET_IS_LOADING', true)
    const body = {
      validation_code: payload.object.validation_code
    }
    try {
      await client.secureHttpClient.patch(`${pathUrl}/org-package-validation-codes/${payload.id}`, body)
      commit('SET_IS_LOADING', false)
      return true
    } catch (e) {
      Vue.$log.error(e)
      const errMessage = e.response.data.message || 'Incomplete information'
      commit('SET_IS_LOADING', false)
      commit('SET_ERROR_MESSAGE', errMessage)
      return false
    }
  },
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  resetState({commit}) {
    commit('RESET_STATE')
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setTextSearch({commit}, text) {
    if (text !== state.textSearch) commit('SET_TEXT_SEARCH', text)
  }
}

// Mutations
const mutations = {
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_TOTAL(state, total) {
    state.total = total
  },
  SET_QR_CODE_LIST(state, data) {
    state.qrCodeList = data
  },
  SET_QR_CODE(state, data) {
    state.qrCodeObject = data
  },
  RESET_LIST_STATE(state) {
    state.textSearch = defaultState().textSearch
    state.currentPage = defaultState().currentPage
    state.pageSize = defaultState().pageSize
    state.sortBy = defaultState().sortBy
    state.sortDesc = defaultState().sortDesc
  },
  RESET_STATE(state) {
    state.qrCodeObject = defaultState().qrCodeObject
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  SET_ERROR_MESSAGE(state, message) {
    state.errorMessage = message
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
