import client from '@/apis/http-client'
import {symptomaticAreasStatusEnum} from '@/constants/symptomatic-areas'
import Vue from 'vue'
let pathUrl = `/por/api`
const pathOrgUrl = `sso/rbac/organization/permission`
import qs from 'qs'

// Initial state.
const defaultState = () => {
  return {
    // Filters
    filterStatus: '',
    filterHospital: '',
    textSearch: '',
    deleteId: 0,
    isDelete: false,
    // List
    isLoading: false,
    isLoadingBranch: false,
    symptomaticAreasList: [],
    updateIdList: [],
    currentPage: 1,
    pageSize: 25,
    total: 0,
    sortableTotal: 0,
    sortBy: 'update_date',
    sortDesc: 'DESC',
    orgObject: [],
    sortableList: [],
    org_codename: '',
    symptomaticAreasObject: {
      id: '',
      org_codename: '',
      contents: [
        {
          code: 'th',
          name: ''
        },
        {
          code: 'en',
          name: ''
        }
      ],
      status: 0,
      create_by_user: null,
      update_by_user: null
    },
    editOrderObject: {
      id: '',
      org_codename: '',
      contents: [
        {
          code: 'th',
          name: ''
        },
        {
          code: 'en',
          name: ''
        }
      ],
      status: 0,
      create_by_user: null,
      update_by_user: null
    },
    isEditMode: false,
    errorMessage: '',
    isEditOrder: false
  }
}

// State
let state = defaultState()

// Actions
const actions = {
  async reorderSymptomaticAreasList({commit}, clonedBannersList) {
    commit('SET_SORTABLE_LIST', clonedBannersList)
    await client.secureHttpClient.patch(
      `${pathUrl}/symptomatic/bulk-order-update`,
      clonedBannersList
    )
  },
  async setOrgInfo({commit}, isViewOnly) {
    let mode = ''
    if (isViewOnly) {
      mode = 'View'
    } else if (state.isEditMode) {
      mode = 'Edit'
    } else {
      mode = 'Add'
    }

    let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=${mode}&category=Symptomatic`
    )
    commit('SET_ORG_DETAIL', data.data)
  },
  async setViewOrgInfo({commit}, isDetail) {
    commit('SET_IS_LOADING_BRANCH', true)
    try {
      let data = await client.secureHttpClient.get(
        `${pathOrgUrl}?permission=View&category=Symptomatic`
      )
      commit('SET_IS_LOADING_BRANCH', false)
      if (isDetail) {
        commit('SET_ORG_DETAIL', data.data)
      } else {
        commit('SET_ORG_LIST', data.data)
      }
    } catch (e) {
      commit('SET_IS_LOADING_BRANCH', false)
    }
  },
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  resetState({commit}) {
    commit('RESET_STATE')
  },
  resetSearchAndFilter({commit}) {
    commit('RESET_SEARCH_AND_FILTER')
  },
  setEditMode({commit}, isEditable) {
    commit('SET_EDIT_MODE', isEditable)
  },
  setDeleteId({commit}, id) {
    commit('SET_DELETE_ID', id)
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setFilterStatus({commit}, status) {
    let selected = ''
    if (status.length === 1) {
      selected = status[0]
    }
    commit('SET_FILTER_STATUS', selected)
  },
  setFilterHospital({commit}, status) {
    commit('SET_FILTER_HOSPITAL', status)
  },
  setShowEditOrder({commit}, status) {
    commit('SET_SHOW_EDIT_ORDER', status)
  },
  async setEditOrder({commit}, item) {
    const indexNo = state.sortableList.findIndex(x => x.id === item.id)
    commit('SET_EDIT_ORDER_OBJECT', {
      ...item,
      indexNo,
      total: state.sortableList.length
    })
    commit('SET_SHOW_EDIT_ORDER', true)
  },
  setTextSearch({commit}, text) {
    if (text === state.textSearch) return
    commit('SET_TEXT_SEARCH', text)
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setSelectedId({commit}, ids) {
    commit('SET_UPDATE_ID', ids)
  },
  setDeletePopup({commit}, status) {
    commit('SET_POPUP_DELETE', status)
  },
  async setSymptomaticAreasId({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/symptomatic/${id}`)
    if (data) {
      commit('SET_SYMPTOMATIC_AREAS_OBJECT', data.data)
    }
    commit('SET_IS_LOADING', false)
  },
  async update({commit, dispatch}, status) {
    let ids = state.updateIdList.map(id => {
      return id.id
    })
    let body = {ids: ids, status: status}
    await client.secureHttpClient.patch(
      `${pathUrl}/symptomatic/bulk-update`,
      body
    )
    dispatch('fetch')
    commit('SET_UPDATE_ID', [])
  },
  async deletePkg({commit, dispatch}) {
    try {
      commit('SET_IS_LOADING', true)
      await client.secureHttpClient.delete(
        `${pathUrl}/symptomatic/${state.deleteId}`
      )
      commit('SET_POPUP_DELETE', false)
      commit('SET_DELETE_ID', 0)
      dispatch('fetch')
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  // API calls
  async fetch({commit}) {
    const {
      currentPage,
      pageSize,
      textSearch,
      filterStatus,
      filterHospital
    } = state
    const params = {
      page: currentPage,
      limit: pageSize,
      q: textSearch,
      ...(filterStatus !== '' && {status: filterStatus}),
      ...(filterHospital !== '' && {orgs: [filterHospital]})
    }
    commit('SET_IS_LOADING', true)
    // const   {page, search, limit, status, category, sortBy, sortDesc} = state
    try {
      if (filterHospital && filterHospital != '') {
        let data = await client.secureHttpClient.get(`${pathUrl}/symptomatic`, {
          params,
          paramsSerializer: params => qs.stringify(params, {encode: false})
        })
        commit('SET_TOTAL', data.data.total)
        commit('SET_SYMPTOMATIC_AREAS_LIST', data.data.data)
      } else {
        commit('SET_TOTAL', 0)
        commit('SET_SYMPTOMATIC_AREAS_LIST', [])
      }
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_SYMPTOMATIC_AREAS_LIST', [])
      commit('SET_IS_LOADING', false)
    }
  },
  async fetchSortableList({commit}) {
    const {filterHospital} = state

    commit('SET_IS_LOADING', true)
    try {
      const params = {
        listType: 'sorting',
        ...(filterHospital !== '' && {orgs: [filterHospital]})
      }
      let data = await client.secureHttpClient.get(`${pathUrl}/symptomatic`, {
        params,
        paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_SORTABLE_TOTAL', data.data.data.length)
      commit('SET_SORTABLE_LIST', data.data.data)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async changeStatus({dispatch}, {status, id}) {
    try {
      await client.secureHttpClient.patch(
        `${pathUrl}/symptomatic/${id}/change-status`,
        {
          status: status
            ? symptomaticAreasStatusEnum.INACTIVE
            : symptomaticAreasStatusEnum.ACTIVE
        }
      )
      dispatch('fetch')
      return true
    } catch (e) {
      dispatch('fetch')
      Vue.$log.error(e)
      return false
    }
  },
  async updateSymptomaticAreas({commit}, payload) {
    const {pkg, id} = payload
    commit('SET_IS_LOADING', true)
    try {
      await client.secureHttpClient.put(`${pathUrl}/symptomatic/${id}`, pkg)
      commit('SET_IS_LOADING', false)
      return true
    } catch (e) {
      const errMessage = e.response.data.message || 'Incomplete information'
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      commit('SET_ERROR_MESSAGE', errMessage)
      return false
    }
  },
  async create({commit}, pkg) {
    commit('SET_IS_LOADING', true)
    try {
      await client.secureHttpClient.post(`${pathUrl}/symptomatic`, pkg)
      commit('RESET_STATE')
      commit('SET_IS_LOADING', false)
      return true
    } catch (e) {
      const errMessage = e.response.data.message || 'Incomplete information'
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      commit('SET_ERROR_MESSAGE', errMessage)
      return false
    }
  }
}

// Mutations
const mutations = {
  SET_ORG_LIST(state, org) {
    let results = org.data.map(object => {
      return {text: object.name, value: object.codename}
    })
    state.orgObject = results
  },
  SET_SYMPTOMATIC_AREAS_LIST(state, list) {
    state.symptomaticAreasList = list
  },
  SET_ORG_DETAIL(state, org) {
    let results = org.data.map(object => {
      return {text: object.name, value: object.codename}
    })
    state.orgObject = results
  },
  RESET_STATE(state) {
    state.symptomaticAreasObject = defaultState().symptomaticAreasObject
  },
  RESET_SEARCH_AND_FILTER(state) {
    state.symptomaticAreasList = defaultState().symptomaticAreasList
    state.filterStatus = defaultState().filterStatus
    state.textSearch = defaultState().textSearch
  },
  RESET_LIST_STATE(state) {
    state.currentPage = defaultState().currentPage
    state.pageSize = defaultState().pageSize
    state.symptomaticAreasList = defaultState().symptomaticAreasList
    state.filterStatus = defaultState().filterStatus
    state.sortBy = defaultState().sortBy
  },
  SET_EDIT_MODE(state, isEdit) {
    state.isEditMode = isEdit
  },
  SET_ERROR_MESSAGE(state, message) {
    state.errorMessage = message
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_IS_LOADING_BRANCH(state, isLoading) {
    state.isLoadingBranch = isLoading
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  SET_FILTER_STATUS(state, status) {
    state.filterStatus = status
  },
  SET_FILTER_HOSPITAL(state, status) {
    state.filterHospital = status
  },
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  SET_TOTAL(state, total) {
    state.total = total
  },
  SET_SORTABLE_TOTAL(state, total) {
    state.sortableTotal = total
  },
  SET_SORTABLE_LIST(state, results) {
    state.sortableList = results
  },
  SET_SYMPTOMATIC_AREAS_OBJECT(state, pkg) {
    state.symptomaticAreasObject = {
      ...pkg.data, ...{
        contents: [
          {...pkg.data.contents.find(content => content.code === 'th')},
          {...pkg.data.contents.find(content => content.code === 'en')}
        ]
      }
    }
  },
  SET_EDIT_ORDER_OBJECT(state, banner) {
    state.editOrderObject = banner
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_UPDATE_ID(state, ids) {
    state.updateIdList = ids
  },
  SET_POPUP_DELETE(state, status) {
    state.isDelete = status
  },
  SET_DELETE_ID(state, id) {
    state.deleteId = id
  },
  SET_SHOW_EDIT_ORDER(state, status) {
    state.isEditOrder = status
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
