export const typeEnum = {
  INFORMATIVE: 'INFORMATIVE',
  INTERACTION: 'INTERACTION'
}

export const typeText = {
  [typeEnum.INFORMATIVE]: 'Informative',
  [typeEnum.INTERACTION]: 'Interaction'
}

export const appearOnPageTypeEnum = {
  HOME: 'HOME',
  PATIENT_PROFILE: 'PATIENT_PROFILE',
  SMART_ASSISTANT: 'SMART_ASSISTANT',
  SHOP: 'SHOP',
  MY_ACCOUNT: 'MY_ACCOUNT'
}

export const appearOnPageTypeText = {
  [appearOnPageTypeEnum.HOME]: 'Home',
  [appearOnPageTypeEnum.PATIENT_PROFILE]: 'Patient Profile',
  [appearOnPageTypeEnum.SMART_ASSISTANT]: 'Assistant',
  [appearOnPageTypeEnum.SHOP]: 'Shop',
  [appearOnPageTypeEnum.MY_ACCOUNT]: 'My Account'
}

export const heroBannerStatusEnum = {
  ACTIVE: 0,
  INACTIVE: 1
}

export const heroBannerStatus = {
  [heroBannerStatusEnum.ACTIVE]: 'Active',
  [heroBannerStatusEnum.INACTIVE]: 'Inactive'
}

export const linkToEnum = {
  LINK_WITHIN_APP: 'app',
  LINK_TO_WEBSITE: 'website'
}

export const linkToText = {
  [linkToEnum.LINK_WITHIN_APP]: 'Link within App',
  [linkToEnum.LINK_TO_WEBSITE]: 'Link to Website'
}

export const routeTypeEnum = {
  INTERNAL_WEB: 'internalWeb',
  EXTERNAL_WEB: 'externalWeb',
  NEWS_DETAIL: 'newsDetail',
  COUPON_DETAIL: 'couponDetail',
  COUPON_LIST: 'couponList',
  PACKAGES_DETAIL: 'packagesDetail',
  PACKAGE_LIST: 'packageList',
  MEMBER_CARD: 'memberCard',
  SEE_DOCTOR_NOW: 'seeDoctorNow',
  DOCTOR_LIST: 'doctorList',
  MAIN_ASSISTANT: 'mainAssistant',
  DEPARTMENT_LIST: 'department_list',
  DOCTOR_DETAIL: 'doctorDetail',
  POPUP_IMAGE: 'popupImage'
}

export const sectionEnum = {
  COUPON: 'coupon',
  DEPARTMENT: 'department',
  DOCTOR: 'doctor',
  MEMBERCARD: 'membercard',
  NEWS: 'news',
  PACKAGE: 'package',
}

export const sectionEnumText = {
  [sectionEnum.COUPON]: 'Coupon',
  [sectionEnum.DEPARTMENT]: 'Department',
  [sectionEnum.DOCTOR]: 'Doctor',
  [sectionEnum.MEMBERCARD]: 'Membercard',
  [sectionEnum.NEWS]: 'News',
  [sectionEnum.PACKAGE]: 'Package & Promotion',
}

export const appRouteType = {
  [sectionEnum.COUPON]: routeTypeEnum.COUPON_DETAIL,
  [sectionEnum.DEPARTMENT]: routeTypeEnum.DEPARTMENT_LIST,
  [sectionEnum.DOCTOR]: routeTypeEnum.DOCTOR_DETAIL,
  [sectionEnum.MEMBERCARD]: routeTypeEnum.MEMBER_CARD,
  [sectionEnum.NEWS]: routeTypeEnum.NEWS_DETAIL,
  [sectionEnum.PACKAGE]: routeTypeEnum.PACKAGES_DETAIL,
}

export const browserTypeEnum = {
  INTERNAL_WEB: routeTypeEnum.INTERNAL_WEB,
  EXTERNAL_WEB: routeTypeEnum.EXTERNAL_WEB,
}

export const browserTypeText = {
  [browserTypeEnum.INTERNAL_WEB]: 'In-App Browser',
  [browserTypeEnum.EXTERNAL_WEB]: 'Default Device Browser',
}

export const sectionsWithExpired = [sectionEnum.COUPON, sectionEnum.NEWS, sectionEnum.PACKAGE]