import Vue from 'vue'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import storages from './local-storages'
import {EventBus} from '../helper/event-bus'
import {convertToRolePermissionByOrg} from '@/helper/helper'

const authStorage = storages.authLocalStorage()

const opts = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000, // indicates, 10000ms ie. 10 seconds
  headers: {
    'Content-Type': 'application/json'
  }
}

// Axios instance without Authorize header.
const simpleHttpClient = axios.create(opts)

const getUserPermission = async (userId, token) => {
  const access_token = `Bearer ${token}`

  let roles = {}
  try {
    const options = { headers: {Authorization: access_token} }
    const userDetail = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/sso/rbac/user/${userId}`, options)
    // const userDetail = await secureHttpClient.get(`/sso/rbac/user/${userId}`)
    roles = convertToRolePermissionByOrg(userDetail.data.roles)
  } catch (e) {
    Vue.$log.error(e)
  }
  return roles
}

// Authenticate with user/pass.
const Login = (username, password) => {
  const payload = JSON.stringify({
    username: username,
    password: password,
    logged_app: 'portal'
  })

  return simpleHttpClient.post('/sso/auth/v2/token/', payload)
    .then(async (res) => {
      authStorage.setLastLogin()
      const user = jwt_decode(res.data.access)
      const roles = await getUserPermission(user.user_id, res.data.access)
      return {
        access: res.data.access,
        refresh: res.data.refresh,
        user,
        roles
      }
    })
}

// Axios instance with Authorize header => Bearer.
const secureHttpClient = axios.create(opts)

secureHttpClient.interceptors.request.use(async request => {
  const token = `Bearer ${authStorage.getAccessToken()}`
  try {
    const lastLogin = authStorage.getLastLogin()
    const options = {params: {last_login_date: lastLogin}, headers: {Authorization: token}}
    await axios.get(`${process.env.VUE_APP_API_BASE_URL}/por/api/admin/is_log_force_log_out`, options)
  }
  catch (e) {
    if (e.response.status === 403) {
      return EventBus.$emit('sessionHasExpired')
    }
  }
  request.headers['Authorization'] = token
  return request
})

secureHttpClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 404) {
      EventBus.$emit('notFound')
      return Promise.resolve(null)
    }
    return Promise.reject(error)
  },
)

const refreshToken = () => {
  const refreshToken = authStorage.getRefreshToken()
  return simpleHttpClient
    .post('/sso/auth/v2/token/refresh/', {refresh: refreshToken})
    .then(async (res) => {
      const user = jwt_decode(res.data.access)
      const roles = await getUserPermission(user.user_id, res.data.access)
      return {
        access: res.data.access,
        user,
        refresh: refreshToken,
        roles
      }
    })
    .catch(() => {
      EventBus.$emit('sessionHasExpired')
    })
}

// Function that will be called to refresh authorization
const refreshAuthLogic = failedRequest => {
  const refreshToken = authStorage.getRefreshToken()
  return simpleHttpClient
    .post('/sso/auth/v2/token/refresh/', {refresh: refreshToken})
    .then(refreshResonse => {
      authStorage.setAccessToken(refreshResonse.data.access)
      failedRequest.response.config.headers['Authorization'] =
        'Bearer ' + authStorage.getAccessToken()
      return Promise.resolve()
    })
    .catch(() => {
      // Unable to refresh, force relogin.
      EventBus.$emit('sessionHasExpired')
    })
}

// Instantiate the interceptor
createAuthRefreshInterceptor(secureHttpClient, refreshAuthLogic)

export default {
  Login,
  refreshToken,
  simpleHttpClient,
  secureHttpClient,
}
