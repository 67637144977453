import client from '@/apis/http-client'
import Vue from 'vue'
let pathUrl = `/por/api`

// Initial state.
const defaultState = () => {
  return {
    // List of app versions
    isLoading: false,
    appVersionList: [],
    appVersionObject:{
      id: null,
      min_os_version: null,
      app_version: null,
      description_en: null,
      description_th: null,
      create_by_user: null,
      update_by_user: null
    }
  }
}

// State
let state = defaultState()
// Actions
const actions = {
  resetState({commit}) {
    commit('RESET_STATE')
  },
  // API calls
  async fetch({commit}) {
    commit('SET_IS_LOADING', true)
    try {
      let result = await client.secureHttpClient.get(`${pathUrl}/forceupdates`)      
      commit('SET_APP_VERSION_LIST', result.data.data)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async setAppVersionId({commit}, id) {
    commit('SET_IS_LOADING', true)
    let result = await client.secureHttpClient.get(`${pathUrl}/forceupdate/${id}`)
    if (result) {
      commit('SET_APP_VERSION', result.data.data)
    }
    commit('SET_IS_LOADING', false)
  },
  async updateVersion({commit}, payload) {
    commit('SET_IS_LOADING', true)
    try{
      await client.secureHttpClient.patch(`${pathUrl}/forceupdate/${payload.id}`, payload.versionObj);
    }catch(e){
      commit('SET_IS_LOADING', false);
      return false;
    }
    commit('SET_IS_LOADING', false)
    return true;
  },
}

// Mutations
const mutations = {
  RESET_STATE(state) {
    state.isLoading = defaultState().isLoading
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_APP_VERSION_LIST(state, appVersions) {
    state.appVersionList = appVersions
  },
  SET_APP_VERSION(state, appVersion) {
    state.appVersionObject = appVersion
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
