import {capitalizeFirstLetter} from '@/helper/helper'
import {mobileAppName} from '@/constants/constants'

export default {

  Email: {
    Verifying: {
      th: "ยืนยันอีเมลของคุณ",
      en: "Verifying your email"
    },
    Successfully: {
      th: "ยืนยันอีเมลของคุณสำเร็จ",
      en: "Successfully verified your email"
    },
    Failed: {
      th: `การยืนยันอีเมลผิดพลาด<br>ลิ้งก์ไม่ถูกต้องหรือหมดอายุแล้ว<br>กดปุ่ม รับอีเมล ใน ${mobileAppName} App เพื่อให้ระบบส่งการยืนยันอีเมลใหม่อีกครั้ง`,
      en: `Email Verification Failed<br>The link is invalid or has expired.<br>Please click the ‘Get Email’ on ${mobileAppName} App to receive a new verification mail.`
    },

  },
  SnackBar: {
    Successfully(feature, action, isSingular=true) {
      const verb = isSingular ? 'has' : 'have'
      return capitalizeFirstLetter(`${feature} ${verb} been ${action} successfully.`)
    },
    Failed(feature, action) { return capitalizeFirstLetter(`${action} ${feature} has been failed.`) },
  }
}