export const paymentMethod = {
  OVER_THE_COUNTER: 'Over the counter',
  ATM_KIOSK: 'ATM / KIOSK',
  INTERNET_MOBILE_BANKING: 'Internet / Mobile banking',
  WEB_PAY_DIRECT_DEBIT: 'Web pay / Direct debit',
  CREDIT_CARD: 'Credit card'
}

export const orderStatus = {
  WAITING_FOR_PAYMENT: 'Waiting for payment',
  IN_PROGRESS: 'In progress',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  REFUNDED: 'Refunded'
}

export const orderPaymentStatus = {
  NEW: 'Awaiting payment',
  AWAITING_PAYMENT_CHECKOUT: 'Awaiting payment',
  IN_PROGRESS: 'In Progress',
  PENDING: 'Pending',
  SUCCESS: 'Success',
  FAILED: 'Failed',
  CANCELLED: 'Cancelled',
  EXPIRED: 'Expired',
  REFUNDED: 'Refunded'
}

export const limitExport =
  parseInt(process.env.VUE_APP_EXPORT_FILE_ROW_LIMIT) || 5000

export const productTypeEnum = {
  PACKAGES: 'PACKAGES'
}

export const channelEnum = {
  ALL: 'ALL',
  MOBILE: 'MOBILE',
  WEB: 'WEB',
}

export const channelText = {
  [channelEnum.ALL]: 'All',
  [channelEnum.MOBILE]: 'Mobile Application',
  [channelEnum.WEB]: 'Website',
}
