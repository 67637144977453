import client from '@/apis/http-client'
import Vue from 'vue'
import qs from 'qs'
import _ from 'lodash'
let pathUrl = `/por/api`
const pathOrgUrl = `sso/rbac/organization/permission`

// Initial state.
const defaultState = () => {
  return {
    isLoading: false,
    featureSetupList: [],
    total: 0,
    // params
    currentPage: 1,
    pageSize: 25,
    textSearch: '',
    orgList: {
      all: [],
      selected: []
    },
    sortBy: '',
    sortDesc: 'DESC',
    initialFeatures: [],
    featureSetupDetail: {
      org: {
        codename: '',
        name: '',
        name_th: ''
      },
      features: [],
      update_by_user: null
    }
  }
}

// State
let state = defaultState()

function createParams(state) {
  const {currentPage, pageSize, textSearch, orgList, sortBy, sortDesc} = state

  const params = {
    page: currentPage,
    limit: pageSize,
    q: `${encodeURIComponent(textSearch)}`,
    ...(sortBy.length > 0 && {
      orderBy: sortBy,
      sort: sortDesc,
    }),
    ...(orgList.selected.length > 0 && {
      orgs: orgList.selected.map(o => o.codename)
    })
  }
  return params
}

function getUpdatedContents(initialFeatures, currentFeatures) {
  const updatedContents = []

  for (let indexFeature in initialFeatures) {
    for (let indexContent in initialFeatures[indexFeature].contents) {
      const initialContent =
        initialFeatures[indexFeature].contents[indexContent]
      const currentContent =
        currentFeatures[indexFeature].contents[indexContent]
      if (!_.isEqual(initialContent, currentContent)) {
        updatedContents.push(currentContent)
      }
    }
  }
  return updatedContents
}
// Actions
const actions = {
  // API calls
  async fetch({commit}) {
    commit('SET_IS_LOADING', true)
    const params = createParams(state)
    try {
      let data = await client.secureHttpClient.get(`${pathUrl}/sections`, {
        params,
        paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_TOTAL', data.data.total)
      commit('SET_FEATURE_SETUP_LIST', data.data.data)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async fetchDetail({commit}, codename) {
    commit('SET_IS_LOADING', true)

    try {
      let data = await client.secureHttpClient.get(
        `${pathUrl}/sections/${codename}`
      )
      const featureDetail = data.data.data
      commit('SET_FEATURE_SETUP_DETAIL', featureDetail)
      commit('SET_INITIAL_FEATURES', _.cloneDeep(featureDetail.features))
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async update({commit}, codename) {
    commit('SET_IS_LOADING', true)
    const updatedFeatures = getUpdatedContents(
      state.initialFeatures,
      state.featureSetupDetail.features
    )

    try {
      if (updatedFeatures.length > 0) {
        await client.secureHttpClient.patch(
          `${pathUrl}/sections/${codename}`,
          updatedFeatures
        )
      }
      commit('SET_IS_LOADING', false)
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  resetState({commit}) {
    commit('RESET_STATE')
  },
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  async setListOrgInfo({commit}) {
    let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=View&category=AppConfig`
    )
    commit('SET_ORG_FILTER', data.data)
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setTextSearch({commit}, text) {
    if (text === state.textSearch) return
    commit('SET_TEXT_SEARCH', text)
  }
}

// Mutations
const mutations = {
  RESET_STATE(state) {
    state.featureSetupDetail = defaultState().featureSetupDetail
    state.initialFeatures = defaultState().initialFeatures
    state.isLoading = defaultState().isLoading
  },
  RESET_LIST_STATE(state) {
    state.featureSetupList = defaultState().featureSetupList
    state.total = defaultState().total
    state.currentPage = defaultState().currentPage
    state.pageSize = defaultState().pageSize
    state.textSearch = defaultState().textSearch
    state.orgList = defaultState().orgList
    state.sortBy = defaultState().sortBy
    state.sortDesc = defaultState().sortDesc
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  SET_TOTAL(state, total) {
    state.total = total
  },
  SET_FEATURE_SETUP_LIST(state, featureSetups) {
    state.featureSetupList = featureSetups
  },
  SET_FEATURE_SETUP_DETAIL(state, featureSetupDetail) {
    state.featureSetupDetail = featureSetupDetail
  },
  SET_INITIAL_FEATURES(state, features) {
    state.initialFeatures = features
  },
  SET_ORG_FILTER(state, org) {
    const selected = []

    if (org.data.length === 1) {
      org.data.forEach(d =>
        selected.push({
          codename: d.codename,
          selected: true
        })
      )
    }
    state.orgList.selected = selected
    state.orgList.all = org.data
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
