import client from '@/apis/http-client'
import Vue from 'vue'
let pathUrl = `/por/api`
const pathOrgUrl = `sso/rbac/organization/permission`
import {convertFileToBase64} from '../helper/helper'
import {discountTypeEnum} from '@/constants/package'

// Initial state.
const defaultState = () => {
  return {
    subPackageObject: {
      id: null,
      package_id: null,
      full_price: 0,
      discount: null,
      discount_type: discountTypeEnum.NO_DISCOUNT,
      centrix_code: null,
      contents: [
        {
          code: 'th',
          title: null,
          description: '',
          thumbnail: '',
          thumbnail_alt: ''
        },
        {
          code: 'en',
          title: null,
          description: '',
          thumbnail: '',
          thumbnail_alt: ''
        }
      ],
    },
    subPackageList: [],
    isLoading: false,
    isEditMode: false,
    isImageEnError: false,
    imageEnErrorMessage: '',
    isImageThError: false,
    imageThErrorMessage: '',
    isDelete: false,
    deleteId: 0
  }
}

// State
let state = defaultState()

// Actions
const actions = {
  async setOrgInfo({commit}, isViewOnly) {
    let mode = ''
    if (isViewOnly) {
      mode = 'View'
    } else if (state.isEditMode) {
      mode = 'Edit'
    } else {
      mode = 'Add'
    }

    let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=${mode}&category=Package`
    )
    commit('SET_ORG_LIST', data.data)
  },
  setIsLoading({commit}, show) {
    commit('SET_IS_LOADING', show)
  },
  setImageError({commit}, data) {
    commit('SET_IMAGE_ERROR', data)
  },
  setImage({commit}, data) {
    commit('SET_IMAGE', data)
  },
  resetState({commit}) {
    commit('RESET_STATE')
  },
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  setEditMode({commit}, isEditable) {
    commit('SET_EDIT_MODE', isEditable)
  },
  setDeleteId({commit}, id) {
    commit('SET_DELETE_ID', id)
  },
  setDeletePopup({commit}, status) {
    commit('SET_POPUP_DELETE', status)
  },
  setPackageId({commit}, id) {
    commit('SET_PACKAGE_ID', id)
  },
  async setSubpackageId({commit}, id) {
    let data = await client.secureHttpClient.get(`${pathUrl}/sub-package/${id}`)
    if (data) {
      commit('SET_SUB_PACKAGE', data.data)
    }
  },
  async upload(_, payload) {
    try {
      let data = await client.secureHttpClient.post(
        `${pathUrl}/uploadImage`,
        payload
      )
      return data.data.url
    } catch (e) {
      Vue.$log.error(e)
      return ''
    }
  },
  async delete({commit}) {
    try {
      commit('SET_IS_LOADING', true)
      await client.secureHttpClient.delete(
        `${pathUrl}/sub-package/${state.deleteId}`
      )
      commit('SET_POPUP_DELETE', false)
      commit('SET_DELETE_ID', 0)
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      commit('SET_POPUP_DELETE', false)
      return false
    }
  },
  setStyleImage({commit}) {
    commit('SET_STYLE_CONTENT')
  },
  // API calls
  async fetch({commit}, packageId) {
    try {
      let data = await client.secureHttpClient.get(`${pathUrl}/sub-package/${packageId}/list`)
      commit('SET_SUB_PACKAGE_LIST', data.data.data)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
    }
  },
  async update({commit}, payload) {
    const {pkg, id} = payload
    commit('SET_IS_LOADING', true)
    pkg.discount = pkg.discount ? pkg.discount : 0

    try {
      let data = await client.secureHttpClient.put(
        `${pathUrl}/sub-package/${id}`,
        pkg
      )
      commit('SET_IS_LOADING', false)
      return data.data.success
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async create({commit}, pkg) {
    commit('SET_IS_LOADING', true)
    pkg.full_price = parseInt(pkg.full_price)
    pkg.discount = pkg.discount ? pkg.discount : 0

    try {
      let create = await client.secureHttpClient.post(
        `${pathUrl}/sub-package/`,
        pkg
      )
      commit('SET_IS_LOADING', false)
      return create.data.success
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
}

// Mutations
const mutations = {
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_ORG_LIST(state, org) {
    let results = org.data.map(object => {
      return {text: object.name, value: object.codename}
    })
    state.orgObject = results
  },
  RESET_STATE(state) {
    state.subPackageObject = defaultState().subPackageObject
    state.isImageEnError = defaultState().isImageEnError
    state.imageEnErrorMessage = defaultState().imageEnErrorMessage
    state.isImageThError = defaultState().isImageThError
    state.imageThErrorMessage = defaultState().imageThErrorMessage
  },
  RESET_LIST_STATE(state) {
    state.subPackageList = defaultState().subPackageList
  },
  SET_SUB_PACKAGE_LIST(state, subPackages) {
    state.subPackageList = subPackages
  },
  SET_STYLE_CONTENT(state) {
    // state.subPackageObject.contents[0].description = state.subPackageObject.contents[0].description
    //   .toString()
    //   .split('<img')
    //   .join('<img width="100%" height="auto"')
    // state.subPackageObject.contents[1].description = state.subPackageObject.contents[1].description
    //   .toString()
    //   .split('<img')
    //   .join('<img width="100%" height="auto"')

      state.subPackageObject.contents[0].description = state.subPackageObject.contents[0].description.split('<img')
      .map((imgTag) => {
        const srcMatch = imgTag.match(/src="([^"]*)"/); // Extract the image URL
        if (srcMatch && srcMatch[1]) {
          const imageUrl = srcMatch[1];
          // If the image URL does not contain 'emoji', add width and height attributes
          if (!imageUrl.includes('emoji')) {
            return ' width="100%" height="auto" ' + imgTag;
          }
        }
        return imgTag;
      })
      .join('<img');

      state.subPackageObject.contents[1].description = state.subPackageObject.contents[1].description.split('<img')
      .map((imgTag) => {
        const srcMatch = imgTag.match(/src="([^"]*)"/); // Extract the image URL
        if (srcMatch && srcMatch[1]) {
          const imageUrl = srcMatch[1];
          if (!imageUrl.includes('emoji')) {
            return ' width="100%" height="auto" ' + imgTag;
          }
        }
        return imgTag;
      })
      .join('<img');
  },
  SET_EDIT_MODE(state, isEdit) {
    state.isEditMode = isEdit
  },
  SET_PACKAGE_ID(state, id) {
    state.subPackageObject.package_id = id
  },
  SET_SUB_PACKAGE(state, pkg) {
    state.subPackageObject = {...defaultState().subPackageObject, ...pkg}
    state.subPackageObject.discount = pkg.discount === 0 ? null : pkg.discount
  },
  SET_IMAGE_ERROR(state, {lang, isError, message}) {
    if (lang === 'en') {
      state.isImageEnError = isError
      state.imageEnErrorMessage = message
    }
    if (lang === 'th') {
      state.isImageThError = isError
      state.imageThErrorMessage = message
    }
  },
  SET_IMAGE(state, {lang, file}) {
    convertFileToBase64(file)
      .then(base64 => {
        if (lang === 'th') {
          state.subPackageObject.contents[0].thumbnail = base64
        }
        if (lang === 'en') {
          state.subPackageObject.contents[1].thumbnail = base64
        }
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_POPUP_DELETE(state, status) {
    state.isDelete = status
  },
  SET_DELETE_ID(state, id) {
    state.deleteId = id
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
