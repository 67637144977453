'use strict'

import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import verify from './verify'
import customer from './customer'
import report from './report'
import news from './news'
import doctor from './doctor'
import department from './department'

import packages from './packages'

import coupon from './coupon'

import banner from './banner'
import userRole from './user-role'
import rolePermission from './role-permission'
import subPackage from './sub-packages'
import symptomaticAreas from './symptomatic-areas'
import organization from './organization'
import myProfile from './my-profile'
import notification from './notification'
import order from './order'
import digitalProduct from './digital-product'
import forceUpdate from './force-update'
import localize from './localize'
import termAndCondition from './term-and-condition'
import featureSetup from './feature-setup'
import heroBanner from './hero-banner'
import importFile from './import-file'
import qrCode from './qr-code'
import contentRepeater from './content-repeater'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    auth,
    verify,
    customer,
    report,
    news,
    doctor,
    department,
    packages,
    coupon,
    banner,
    userRole,
    rolePermission,
    subPackage,
    symptomaticAreas,
    organization,
    myProfile,
    notification,
    order,
    digitalProduct,
    forceUpdate,
    localize,
    termAndCondition,
    featureSetup,
    heroBanner,
    importFile,
    qrCode,
    contentRepeater
  }
})
