const setting = require('../../setting.json')

export const mobileAppName = setting.mobileAppName || 'Princ Health'
// ratio 1:1
export const imageNavBar = setting.image.navBar || '/images/nav-logo.png'
// ratio 36:11
export const imageHospitalLogo = setting.image.hospitalLogo || '/images/princ-health-logo-2x.png'
// ratio 600:337
export const imageHospitalBg = setting.image.hospitalBg || '/images/bg.jpg'

export const actionEnum = {
  CREATED: 'created',
  EDITED: 'edited',
  STATUS_UPDATED: 'status updated',
  REMOVED: 'removed',
  ORDER_UPDATED: 'order updated',
}

export const tabEnum = {
  LIST: 'List',
  SORT: 'Sort',
}