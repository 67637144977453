<template>
  <v-app>
    <nav-bar />

    <v-content class="pt-5">
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
import Navbar from '../components/Navbar'
export default {
  name: 'default',

  components: {'nav-bar': Navbar}
}
</script>
