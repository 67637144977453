import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)
const setting = require('../../setting.json')

const vuetifyOpts = {
  theme: {
    dark: false,
    themes: {
      light: {
        borderColor: setting.color.border || '#CECFD3',
        chip: setting.color.chip || '#DBF4FF',
        danger200Color: setting.color.danger200 || '#FFECEC',
        danger400Color: setting.color.danger400 || '#FF6F6C',
        danger300Color: setting.color.danger300 || '#FFC3C1',
        danger500Color: setting.color.danger500 || '#D52E2A',
        danger600Color: setting.color.danger600 || '#B31C19',
        danger700Color: setting.color.danger700 || '#910F0C',
        danger800Color: setting.color.danger800 || '#6F0503',
        disableTextColor: setting.color.disableText || '#B6B7BD',
        errorTextColor: setting.color.errorText || '#FF5252',
        failChip: setting.color.failChip || '#ECF0F3',
        headerTextColor: setting.color.headerText || '#1F2647',
        hoverBgColor: setting.color.hoverBg || '#f6f6f6',
        hoverMenuColor: setting.color.hoverMenu || '#044279',
        info200Color: setting.color.info200 || '#DBF4FF',
        info400Color: setting.color.info400 || '#5ECDFF',
        info500Color: setting.color.info500 || '#03A9F4',
        info600Color: setting.color.info600 || '#008CCB',
        info800Color: setting.color.info800 || '#00547A',
        primary100Color: setting.color.primary100 || '#EAF5FF',
        primary200Color: setting.color.primary200 || '#BDE0FF',
        primary400Color: setting.color.primary400 || '#34A0FF',
        primary500Color: setting.color.primary500 || '#218AE6',
        primary600Color: setting.color.primary600 || '#1070C4',
        primary700Color: setting.color.primary700 || '#0458A2',
        primary800Color: setting.color.primary800 || '#003C70',
        primary: setting.color.primary || '#245698',
        primaryTextColor: setting.color.primaryText || '#505362',
        secondary: setting.color.secondary || '#9E8819',
        secondaryTextColor: setting.color.secondaryText || '#797B86',
        success200Color: setting.color.success200 || '#F2FFE0',
        success300Color: setting.color.success300 || '#DDFFB5',
        success600Color: setting.color.success600 || '#6FA136',
        success800Color: setting.color.success800 || '#3C5D16',
        textColor: setting.color.text || '#fff',
        warning300Color: setting.color.warning300 || '#FFF7B7',
        warning400Color: setting.color.warning400 || '#FFEE5C',
        warning600Color: setting.color.warning600 || '#D6C000',
        warning800Color: setting.color.warning800 || '#5C5200',
        whiteColor: setting.color.white || '#FFFFFF',
        grayscale: setting.color.grayscale || '#F6F8F9',
        divider: setting.color.divider || '#E7E7E9',
        loginBtnColor: setting.color.loginBtn || '#80C3D9',
        imageBgColor: setting.color.imageBg || '#40BAB8',
        divHoverBgColor: setting.color.divHoverBg || '#EEEEEE',
        typographyPrimaryTextColor: setting.color.typographyPrimaryText || '#424C80'
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  }
}

export default new Vuetify(vuetifyOpts)
