import client from '@/apis/http-client'
import Vue from 'vue'
let pathUrl = `/por/api`
const pathOrgUrl = `sso/rbac/organization/permission`
import qs from 'qs'
import {prefix} from '@/constants/term-condition'

const defaultState = () => {
  return {
    // Filters
    filterType: '',
    isLoading: false,
    termAndConditionList: [],
    currentPage: 1,
    pageSize: 25,
    total: 0,
    sortBy: 'create_date',
    sortDesc: 'DESC',
    types: [],
    latestVersion: null,
    orgObject: [],
    termAndConditionObject: {
      id: null,
      orgs: null,
      type: null,
      version: null,
      app_version: null,
      contents: [
        {code: 'en', description: ''},
        {code: 'th', description: ''}
      ]
    }
  }
}

// State
let state = defaultState()

function addContentPrefix(content) {
  if (content.description.includes(prefix[content.code])) {
    return content
  } else {
    return {
      code: content.code,
      description: `${prefix[content.code]}${content.description}`
    }
  }
}

// Actions
const actions = {
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setFilterType({commit}, type) {
    commit('SET_FILTER_TYPE', type)
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setTypeTermAndConditionObject({commit}, type) {
    commit('SET_TYPE_TERM_AND_CONDITION_OBJECT', type)
  },
  // API calls
  async fetch({commit}) {
    commit('SET_IS_LOADING', true)
    const {
      currentPage,
      pageSize,
      filterType,
      sortBy,
      sortDesc
    } = state
    let params = {
      page: currentPage,
      limit: pageSize,
      orderBy: sortBy,
      sort: sortDesc
    }
    if( filterType && filterType !== 'all' && filterType !== null ){
      params.type = filterType;
    }
    try {
      let data = await client.secureHttpClient.get(`${pathUrl}/term_and_conditions`, {
        params,
        paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_TOTAL', data.data.total)
      commit('SET_TERM_AND_CONDITION_LIST', data.data.data)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async create({commit}, termAndCondition) {
    commit('SET_IS_LOADING', true)
    const contentWithPrefix = termAndCondition.contents.map(addContentPrefix)

    try {
      await client.secureHttpClient.post(`${pathUrl}/term_and_condition`, {
        ...termAndCondition,
        ...{contents: contentWithPrefix}
      })
      commit('SET_IS_LOADING', false)
      return true
    } catch (e) {
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async getLatestVersion({commit}) {
    const {termAndConditionObject} = state
    try {
      const params = {
        ...(termAndConditionObject.type !== null && {
          type: termAndConditionObject.type
        })
      }
      let data = await client.secureHttpClient.get(
        `${pathUrl}/term_and_condition`,
        {
          params,
          paramsSerializer: params => qs.stringify(params, {encode: false})
        }
      )

      commit('RESET_VERSION')
      commit('RESET_APP_VERSION')
      commit('SET_LATEST_VERSION', data.data.data.version)
      commit('SET_ID', data.data.data.id)
      commit('SET_CONTENT_DESCRIPTION', data.data.data.contents)
    } catch (e) {
      commit('SET_LATEST_VERSION', null)
    }
  },
  async setOrgInfo({commit}) {
    commit('SET_IS_LOADING', true)
    try {
      let data = await client.secureHttpClient.get(
        `${pathOrgUrl}?permission=${
          state.isEditMode ? 'Edit' : 'Add'
        }&category=AppConfig`
      )
      commit('SET_ORG_LIST', data.data)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      commit('SET_IS_LOADING', false)
      Vue.$log.error(e)
    }
  },
  resetState({commit}) {
    commit('RESET_STATE')
  }
}

// Mutations
const mutations = {
  RESET_LIST_STATE(state) {
    state.filterType = defaultState().filterType
    state.sortBy = defaultState().sortBy
  },
  RESET_STATE(state) {
    state.latestVersion = defaultState().latestVersion
    state.termAndConditionObject = defaultState().termAndConditionObject
  },
  RESET_VERSION(state) {
    state.termAndConditionObject.version = defaultState().termAndConditionObject.version
  },
  RESET_APP_VERSION(state) {
    state.termAndConditionObject.app_version = defaultState().termAndConditionObject.app_version
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  SET_FILTER_TYPE(state, type) {
    state.filterType = type
  },
  SET_TOTAL(state, total) {
    state.total = total
  },
  SET_TERM_AND_CONDITION_LIST(state, news) {
    state.termAndConditionList = news
  },
  SET_TYPE_TERM_AND_CONDITION_OBJECT(state, type) {
    state.termAndConditionObject.type = type
  },
  SET_NEWS(state, pkg) {
    state.newsObject = pkg
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_LATEST_VERSION(state, latestVersion) {
    state.latestVersion = latestVersion
  },
  SET_ID(state, id) {
    state.termAndConditionObject.id = id
  },
  SET_CONTENT_DESCRIPTION(state, contents) {
    for (let content of contents) {
      for (let index in state.termAndConditionObject.contents) {
        if (
          state.termAndConditionObject.contents[index].code === content.code
        ) {
          state.termAndConditionObject.contents[index] = {
            code: content.code,
            description: content.description
          }
        }
      }
    }
  },
  SET_ORG_LIST(state, org) {
    state.orgObject = org.data
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
