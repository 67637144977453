// Initial state.
import Vue from 'vue'
import {convertFileToBase64} from '@/helper/helper'
import storages from '@/apis/local-storages'
import client from '@/apis/http-client'
import {fileTypeText} from '@/constants/import-file'
const pathUrl = `/por/api`
const authStorage = storages.authLocalStorage()

const defaultState = () => {
  return {
    // filter
    orgObject: [],
    fileObject: {
      org_codename: null,
      type: null,
      preference: '',
      file: '',
      filename: '',
      fileType: ''
    },
    isLoading: false,
    fileIsError: false,
    fileErrorMessage: '',
    errorMessage: '',
    validType: null,
    activityLogs: []
  }
}

// State
let state = defaultState()

// Actions
const actions = {
  resetState({commit}) {
    commit('RESET_STATE')
  },
  resetFileState({commit}) {
    commit('RESET_FILE_STATE')
  },
  setOrgInfo({commit}) {
    const roles = authStorage.getRoles()
    const canAccessOrgs = Object.keys(roles).filter(
      codename =>
        roles[codename].permissions.includes('CanBulkDepartment') ||
        roles[codename].permissions.includes('CanBulkDoctor')
    )
    commit('SET_ORG_LIST', canAccessOrgs)
  },
  setFileError({commit}, data) {
    commit('SET_FILE_ERROR', data)
  },
  setFile({commit}, data) {
    commit('SET_FILE', data)
  },
  async checkType({commit}, org_codename) {
    try {
      const data = await client.secureHttpClient.get(
        `${pathUrl}/check-master-data/${org_codename}`
      )
      commit('SET_VALID_TYPE', data.data)
      return true
    } catch (e) {
      Vue.$log.error(e)
      const errMessage = e.response.data.message || 'Incomplete information'
      commit('SET_ERROR_MESSAGE', errMessage)
      return false
    }
  },
  async import({commit}) {
    commit('SET_IS_LOADING', true)
    try {
      await client.secureHttpClient.post(
        `${pathUrl}/import-master-data`,
        state.fileObject
      )
      commit('SET_IS_LOADING', false)
      return true
    } catch (e) {
      Vue.$log.error(e)
      const errMessage = e.response.data.message || 'Incomplete information'
      commit('SET_IS_LOADING', false)
      commit('SET_ERROR_MESSAGE', errMessage)
      return false
    }
  },
  async fetchActivityLogs({commit}, org_codename) {
    commit('SET_IS_LOADING', true)
    try {
      const data = await client.secureHttpClient.get(
        `${pathUrl}/activity-logs/${org_codename}`
      )
      commit('SET_ACTIVITY_LOGS', data.data.data)
      commit('SET_IS_LOADING', false)
      return true
    } catch (e) {
      Vue.$log.error(e)
      const errMessage = e.response.data.message || 'Incomplete information'
      commit('SET_IS_LOADING', false)
      commit('SET_ERROR_MESSAGE', errMessage)
      return false
    }
  }
}

const mutations = {
  RESET_STATE(state) {
    state.fileObject = defaultState().fileObject
    state.isLoading = defaultState().isLoading
    state.fileIsError = defaultState().fileIsError
    state.fileErrorMessage = defaultState().fileErrorMessage
    state.validType = defaultState().validType
    state.activityLogs = defaultState().activityLogs
  },
  RESET_FILE_STATE(state) {
    state.fileObject.file = defaultState().fileObject.file
    state.fileObject.filename = defaultState().fileObject.filename
    state.fileObject.fileType = defaultState().fileObject.fileType
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_ORG_LIST(state, orgs) {
    const newOrgs = []
    orgs.forEach(key => {
      newOrgs.push({text: key, value: key})
    })
    state.orgObject = newOrgs
  },
  SET_FILE_ERROR(state, {isError, message}) {
    state.fileIsError = isError
    state.fileErrorMessage = message
  },
  SET_FILE(state, file) {
    state.fileObject.filename = file.name
    state.fileObject.fileType = fileTypeText[file.type]
    convertFileToBase64(file)
      .then(base64 => {
        state.fileObject.file = base64
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_ERROR_MESSAGE(state, msg) {
    state.errorMessage = msg
  },
  SET_VALID_TYPE(state, validType) {
    state.validType = validType
  },
  SET_ACTIVITY_LOGS(state, activityLogs) {
    state.activityLogs = activityLogs
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
