// Initial state.
import client from '@/apis/http-client'
import {convertFileToBase64, splitToDateAndTime} from '@/helper/helper'
import Vue from 'vue'
import qs from 'qs'
import moment from 'moment'
import {
  heroBannerStatusEnum,
  linkToEnum,
  appRouteType,
  sectionEnumText,
  typeEnum
} from '@/constants/hero-banner'
const pathUrl = `/por/api`
const pathOrgUrl = `sso/rbac/organization/permission`

const defaultState = () => {
  return {
    isEditMode: false,
    // filter
    orgList: {
      all: [],
      selected: []
    },
    // List of hero banner
    heroBannerList: [],
    sortableList: [],
    updateId: 0,
    isPreview: false,
    totalPackages: 0,
    total: 0,
    currentPage: 1,
    pageSize: 25,
    sortableTotal: 0,
    sortBy: 'update_date',
    sortDesc: 'DESC',
    isDelete: false,
    deleteId: 0,
    filterStatus: '',
    filterType: null,
    // Object of hero banner
    orgObject: [],
    sections: [],
    contentList: [],
    heroBannerObject: {
      type: null,
      appear_on_page_type: '',
      start_date: '',
      start_time: '',
      end_date: '',
      end_time: '',
      status: 0,
      contents: [
        {
          code: 'th',
          title: null,
          thumbnail: ''
        },
        {
          code: 'en',
          title: null,
          thumbnail: ''
        }
      ],
      org_codename: null,
      link_to: linkToEnum.LINK_WITHIN_APP,
      route_type: '',
      route_value: '',
      section: {text: '', value: 0},
      content_id: 0,
      link_contents: [],
      browser_type: null,
      is_app: false,
      update_by_user: null,
      update_date: null,
      create_by_user: null,
      create_date: null
    },
    editOrderObject: {
      type: null,
      appear_on_page_type: '',
      start_date: '',
      start_time: '',
      end_date: '',
      end_time: '',
      status: 0,
      contents: [
        {
          code: 'th',
          title: null,
          thumbnail: ''
        },
        {
          code: 'en',
          title: null,
          thumbnail: ''
        }
      ],
      org_codename: null,
      link_to: linkToEnum.LINK_WITHIN_APP,
      route_type: '',
      route_value: '',
      section: {text: '', value: 0},
      content_id: 0,
      link_contents: [],
      browser_type: null,
      is_app: false,
      update_by_user: null,
      update_date: null,
      create_by_user: null,
      create_date: null
    },
    sectionContent: {
      isLoading: false,
      textSearch: '',
      page: 1,
      pageSize: 25,
      totalSize: 0
    },
    isDisableAfterPublish: false,
    isUnpublished: false,
    isPublished: false,
    isExpired: false,
    isLoading: false,
    isLoadingBranch: false,
    isImageEnError: false,
    imageEnErrorMessage: '',
    isImageThError: false,
    imageThErrorMessage: '',
    errorMessage: '',
    isEditOrder: false,
  }
}

// State
let state = defaultState()

// Actions
const actions = {
  resetState({commit}) {
    commit('RESET_STATE')
  },
  resetLinkTo({commit}) {
    commit('RESET_LINK_TO')
  },
  resetSection({commit}) {
    commit('RESET_SECTION')
  },
  resetContent({commit}) {
    commit('RESET_CONTENT')
  },
  resetStartDatetime({commit}) {
    commit('RESET_START_DATETIME')
  },
  resetEndDatetime({commit}) {
    commit('RESET_END_DATETIME')
  },
  resetContentTable({commit}) {
    commit('RESET_CONTENT_TABLE')
  },
  resetWebsite({commit}) {
    commit('RESET_WEBSITE')
  },
  resetSearchAndFilter({commit}) {
    commit('RESET_SEARCH_AND_FILTER')
  },
  setEditMode({commit}, isEditable) {
    commit('SET_EDIT_MODE', isEditable)
  },
  async setOrgInfo({commit}, isViewOnly) {
    commit('SET_IS_LOADING', true)

    let mode = ''
    if (isViewOnly) {
      mode = 'View'
    } else if (state.isEditMode) {
      mode = 'Edit'
    } else {
      mode = 'Add'
    }

    let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=${mode}&category=HeroBanner`
    )
    commit('SET_ORG_LIST', data.data)
    commit('SET_IS_LOADING', false)
  },
  async setListOrgInfo({commit}) {
    commit('SET_IS_LOADING_BRANCH', true)
    try {
      let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=View&category=HeroBanner`
      )
      commit('SET_IS_LOADING_BRANCH', false)
      commit('SET_ORG_FILTER', data.data)
    } catch (e) {
      commit('SET_IS_LOADING_BRANCH', false)
    }
  },
  async create({commit}, payload) {
    if (payload.link_to === linkToEnum.LINK_WITHIN_APP) {
      payload.route_type = appRouteType[state.heroBannerObject.section.type]
      payload.route_value = state.heroBannerObject.content_id
    }
    if (payload.link_to === linkToEnum.LINK_TO_WEBSITE) {
      payload.route_type = state.heroBannerObject.browser_type
      payload.route_value = state.heroBannerObject.route_value
    }
    if (payload.type === typeEnum.INFORMATIVE) {
      payload.route_type = null
      payload.route_value = null
    }
    commit('SET_IS_LOADING', true)
    try {
      await client.secureHttpClient.post(`${pathUrl}/hero-banner`, payload)
      commit('RESET_STATE')
      commit('SET_IS_LOADING', false)
      return true
    } catch (e) {
      const errMessage = e.response.data.message || 'Incomplete information'
      commit('SET_IS_LOADING', false)
      commit('SET_ERROR_MESSAGE', errMessage)
      return false
    }
  },
  async update({commit}, payload) {
    commit('SET_IS_LOADING', true)
    const {id, object} = payload
    if (object.link_to === linkToEnum.LINK_WITHIN_APP) {
      object.route_type = appRouteType[state.heroBannerObject.section.type]
      object.route_value = state.heroBannerObject.content_id
    }
    if (object.link_to === linkToEnum.LINK_TO_WEBSITE) {
      object.route_type = state.heroBannerObject.browser_type
      object.route_value = state.heroBannerObject.route_value
    }
    if (object.type === typeEnum.INFORMATIVE) {
      object.route_type = null
      object.route_value = null
    }
    try {
      await client.secureHttpClient.put(`${pathUrl}/hero-banner/${id}`, object)
      commit('SET_IS_LOADING', false)
      return true
    } catch (e) {
      Vue.$log.error(e)
      const errMessage = e.response.data.message || 'Incomplete information'
      commit('SET_IS_LOADING', false)
      commit('SET_ERROR_MESSAGE', errMessage)
      return false
    }
  },
  setImageError({commit}, data) {
    commit('SET_IMAGE_ERROR', data)
  },
  setImage({commit}, data) {
    commit('SET_IMAGE', data)
  },
  // API calls
  async reorderList({commit}, list) {
    commit('SET_SORTABLE_LIST', list)
    let resultsReorder = list.map((object) => {
      return {id: object.id}
    })
    await client.secureHttpClient.patch(`${pathUrl}/hero-banner/bulk-order-update`,
      resultsReorder
    )
  },
  async fetch({commit}) {
    const {
      currentPage,
      pageSize,
      textSearch,
      filterType,
      filterStatus,
      sortBy,
      sortDesc,
      orgList
    } = state
    commit('SET_IS_LOADING', true)
    try {
      const params = {
        page: currentPage,
        limit: pageSize,
        q: textSearch,
        orderBy: sortBy,
        sort: sortDesc,
        ...(filterStatus !== '' && {status: filterStatus}),
        ...(filterType !== null && {banner_type: filterType}),
        ...(orgList.selected.length > 0 && {
          orgs: orgList.selected.map(o => o.codename)
        })
      }
      let data = await client.secureHttpClient.get(`${pathUrl}/hero-banners`, {
        params,
        paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_TOTAL', data.data.total)
      commit('SET_HERO_BANNER_LIST', data.data.data)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async fetchSortableList({commit}) {
    const {
      orgList
    } = state
    commit('SET_IS_LOADING', true)
    try {
      const params = {
        listType: 'sorting',
        ...(orgList.selected.length > 0 && {
          orgs: orgList.selected.map(o => o.codename)
        })
      }
      let data = await client.secureHttpClient.get(`${pathUrl}/hero-banners`, {
        params,
        paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_SORTABLE_TOTAL', data.data.data.length)
      commit('SET_SORTABLE_LIST', data.data.data)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async fetchSections({commit}) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(
      `${pathUrl}/banner/section/list?isWebbrowser=false`
    )
    commit('SET_IS_LOADING', false)
    commit('SET_SECTIONS', data.data)
  },
  async fetchContentList({commit}) {
    const {page, pageSize, textSearch} = state.sectionContent
    commit('SET_CONTENT_IS_LOADING', true)
    try {
      const params = {
        page: page,
        limit: pageSize,
        q: textSearch,
        org_codename: [state.heroBannerObject.org_codename]
      }
      let data = await client.secureHttpClient.get(
        `${pathUrl}/banner/section/${state.heroBannerObject.section.value}/content`,
        {
          params,
          paramsSerializer: params => qs.stringify(params, {encode: false})
        }
      )
      commit('SET_CONTENT_TOTAL', data.data.total)
      commit('SET_CONTENT_LIST', data.data.results)
      commit('SET_CONTENT_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_CONTENT_IS_LOADING', false)
    }
  },
  setContentPage({commit}, page) {
    commit('SET_CONTENT_PAGE', page)
  },
  setContentPageSize({commit}, size) {
    commit('SET_CONTENT_PAGE_SIZE', size)
  },
  setContentTextSearch({commit}, text) {
    if (text !== state.sectionContent.textSearch)
      commit('SET_CONTENT_TEXT_SEARCH', text)
  },
  async setHeroBannerObject({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/hero-banner/${id}`)
    if (data) {
      commit('SET_HERO_BANNER', data.data.data)
    }
    commit('SET_IS_LOADING', false)
  },
  async setHeroBannerPreview({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/hero-banner/${id}`)
    if (data) {
      commit('SET_HERO_BANNER', data.data.data)
      commit('SET_SHOW_PREVIEW', true)
    }
    commit('SET_IS_LOADING', false)
  },
  setShowPreview({commit}, status) {
    commit('SET_SHOW_PREVIEW', status)
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setShowEditOrder({commit}, status) {
    commit('SET_SHOW_EDIT_ORDER', status)
  },
  async setEditOrder({commit}, item) {
    const indexNo = state.sortableList.findIndex(x => x.id === item.id);
    commit('SET_EDIT_ORDER_OBJECT', {...item, indexNo, total: state.sortableList.length})
    commit('SET_SHOW_EDIT_ORDER', true)
  },
  setTextSearch({commit}, text) {
    if (text == state.textSearch) return
    commit('SET_TEXT_SEARCH', text)
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setSelectedId({commit}, id) {
    commit('SET_UPDATE_ID', id)
  },
  setFilterType({commit}, type) {
    commit('SET_FILTER_TYPE', type)
  },
  setFilterStatus({commit}, status) {
    let selected = ''
    if (status.length === 1) {
      selected = status[0]
    }
    commit('SET_FILTER_STATUS', selected)
  },
  /// EDIT call
  async changeStatus({commit, dispatch}, status) {
    try {
      await client.secureHttpClient.patch(
        `${pathUrl}/hero-banner/${state.updateId}/change-status`,
        {
          status: status
            ? heroBannerStatusEnum.INACTIVE
            : heroBannerStatusEnum.ACTIVE
        }
      )
      dispatch('fetch')
      commit('SET_UPDATE_ID', 0)
      return true
    } catch (e) {
      dispatch('fetch')
      Vue.$log.error(e)
      return false
    }
  },
  async delete({commit, dispatch}) {
    try {
      commit('SET_IS_LOADING', true)
      await client.secureHttpClient.delete(
        `${pathUrl}/hero-banner/${state.deleteId}`
      )
      commit('SET_POPUP_DELETE', false)
      commit('SET_DELETE_ID', 0)
      dispatch('fetch')
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      commit('SET_POPUP_DELETE', false)
      return false
    }
  },
  setDeletePopup({commit}, status) {
    commit('SET_POPUP_DELETE', status)
  },
  setDeleteId({commit}, id) {
    commit('SET_DELETE_ID', id)
  },
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  setLinkedContent({commit}, payload) {
    commit('SET_CONTENT_ID', payload.content_id)
    commit(
      'SET_LINKED_CONTENT',
      payload.contents
        ? payload.contents
        : defaultState().heroBannerObject.link_contents
    )
    if (payload.expiry_date !== undefined) commit('SET_END_DATETIME', payload.expiry_date)
  }
}

const mutations = {
  RESET_STATE(state) {
    state.heroBannerObject = defaultState().heroBannerObject
    state.isImageEnError = defaultState().isImageEnError
    state.imageEnErrorMessage = defaultState().imageEnErrorMessage
    state.isImageThError = defaultState().isImageThError
    state.imageThErrorMessage = defaultState().imageThErrorMessage
    state.isUnpublished = defaultState().isUnpublished
    state.isPublished = defaultState().isPublished
    state.isExpired = defaultState().isExpired
  },
  RESET_LIST_STATE(state) {
    state.currentPage = defaultState().currentPage
    state.pageSize = defaultState().pageSize
    state.textSearch = defaultState().textSearch
    state.filterStatus = defaultState().filterStatus
    state.filterType = defaultState().filterType
    state.sortBy = defaultState().sortBy
    state.sortDesc = defaultState().sortDesc
    state.orgList = defaultState().orgList
  },
  RESET_LINK_TO() {
    state.heroBannerObject.link_to = defaultState().heroBannerObject.link_to
  },
  RESET_SECTION(state) {
    state.heroBannerObject.route_type = defaultState().heroBannerObject.route_type
    state.heroBannerObject.section = defaultState().heroBannerObject.section
  },
  RESET_CONTENT(state) {
    state.heroBannerObject.content_id = defaultState().heroBannerObject.content_id
    state.heroBannerObject.link_contents = defaultState().heroBannerObject.link_contents
    state.heroBannerObject.route_value = defaultState().heroBannerObject.route_value
  },
  RESET_START_DATETIME(state) {
    state.heroBannerObject.start_date = defaultState().heroBannerObject.start_date
    state.heroBannerObject.start_time = defaultState().heroBannerObject.start_time
  },
  RESET_END_DATETIME(state) {
    state.heroBannerObject.end_date = defaultState().heroBannerObject.end_date
    state.heroBannerObject.end_time = defaultState().heroBannerObject.end_time
  },
  RESET_CONTENT_TABLE(state) {
    state.sectionContent.isLoading = defaultState().sectionContent.isLoading
    state.sectionContent.textSearch = defaultState().sectionContent.textSearch
    state.sectionContent.page = defaultState().sectionContent.page
    state.sectionContent.pageSize = defaultState().sectionContent.pageSize
    state.sectionContent.totalSize = defaultState().sectionContent.totalSize
  },
  RESET_WEBSITE(state) {
    state.heroBannerObject.browser_type = defaultState().heroBannerObject.browser_type
    state.heroBannerObject.route_type = defaultState().heroBannerObject.route_type
    state.heroBannerObject.route_value = defaultState().heroBannerObject.route_value
  },
  RESET_SEARCH_AND_FILTER(state) {
    state.heroBannerList = defaultState().heroBannerList
    state.filterStatus = defaultState().filterStatus
    state.filterType = defaultState().filterType
    state.textSearch = defaultState().textSearch
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_IS_LOADING_BRANCH(state, isLoading) {
    state.isLoadingBranch = isLoading
  },
  SET_EDIT_MODE(state, isEdit) {
    state.isEditMode = isEdit
  },
  SET_ORG_LIST(state, org) {
    state.orgObject = org.data.map(object => {
      return {text: object.name, value: object.codename}
    })
  },
  SET_ORG_FILTER(state, org) {
    const selected = []

    if (org.data.length === 1) {
      org.data.forEach(d =>
        selected.push({
          codename: d.codename,
          selected: true
        })
      )
    }
    state.orgList.selected = selected
    state.orgList.all = org.data
  },
  SET_IMAGE_ERROR(state, {lang, isError, message}) {
    if (lang === 'en') {
      state.isImageEnError = isError
      state.imageEnErrorMessage = message
    }
    if (lang === 'th') {
      state.isImageThError = isError
      state.imageThErrorMessage = message
    }
  },
  SET_IMAGE(state, {lang, file}) {
    convertFileToBase64(file)
      .then(base64 => {
        if (lang === 'th') {
          state.heroBannerObject.contents[0].thumbnail = base64
        }
        if (lang === 'en') {
          state.heroBannerObject.contents[1].thumbnail = base64
        }
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  SET_TOTAL(state, total) {
    state.total = total
  },
  SET_SORTABLE_TOTAL(state, total) {
    state.sortableTotal = total
  },
  SET_HERO_BANNER_LIST(state, heroBanners) {
    state.heroBannerList = heroBanners
  },
  SET_SORTABLE_LIST(state, banners) {
    state.sortableList = banners
  },
  SET_HERO_BANNER(state, heroBanner) {
    state.heroBannerObject = {...defaultState().heroBannerObject, ...heroBanner}
    state.heroBannerObject.link_contents =
      state.heroBannerObject.link_contents === null
        ? []
        : state.heroBannerObject.link_contents

    if (state.heroBannerObject.type === typeEnum.INTERACTION) {
      state.heroBannerObject.is_app = Object.values(appRouteType).includes(
        state.heroBannerObject.route_type
      )
      if (state.heroBannerObject.is_app) {
        state.heroBannerObject.content_id = heroBanner.route_value
        state.heroBannerObject.link_to = linkToEnum.LINK_WITHIN_APP
        const sectionText = Object.keys(appRouteType).find(
          key => appRouteType[key] === state.heroBannerObject.route_type
        )
        const section = state.sections.find(s => s.text === sectionText)
        state.heroBannerObject.section = {
          value: section.value,
          text: sectionEnumText[section.text],
          type: section.text
        }
      } else {
        state.heroBannerObject.link_to = linkToEnum.LINK_TO_WEBSITE
        state.heroBannerObject.browser_type = state.heroBannerObject.route_type
      }
    }

    state.isUnpublished = moment(heroBanner.start_date).isAfter()
    state.isPublished = moment(heroBanner.start_date).isSameOrBefore() && moment(heroBanner.end_date).isSameOrAfter()
    state.isExpired = moment(heroBanner.end_date).isBefore()

    const start = !heroBanner.start_date
      ? {dateString: '', timeString: ''}
      : splitToDateAndTime(heroBanner.start_date)
    state.heroBannerObject.start_date = start.dateString
    state.heroBannerObject.start_time = start.timeString
    const end = !heroBanner.end_date
      ? {dateString: '', timeString: ''}
      : splitToDateAndTime(heroBanner.end_date)
    state.heroBannerObject.end_date = end.dateString
    state.heroBannerObject.end_time = end.timeString
  },
  SET_EDIT_ORDER_OBJECT(state, banner) {
    state.editOrderObject = banner
  },
  SET_SHOW_PREVIEW(state, status) {
    state.isPreview = status
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_UPDATE_ID(state, id) {
    state.updateId = id
  },
  SET_POPUP_DELETE(state, status) {
    state.isDelete = status
  },
  SET_DELETE_ID(state, id) {
    state.deleteId = id
  },
  SET_FILTER_STATUS(state, status) {
    state.filterStatus = status
  },
  SET_FILTER_TYPE(state, type) {
    state.filterType = type
  },
  SET_ERROR_MESSAGE(state, msg) {
    state.errorMessage = msg
  },
  SET_SECTIONS(state, sections) {
    state.sections = sections
  },
  SET_CONTENT_ID(state, content_id) {
    state.heroBannerObject.content_id = content_id
  },
  SET_LINKED_CONTENT(state, content) {
    state.heroBannerObject.link_contents = content
  },
  SET_END_DATETIME(state, endDatetime) {
    const end = splitToDateAndTime(endDatetime)
    state.heroBannerObject.end_date = end.dateString
    state.heroBannerObject.end_time = end.timeString
  },
  SET_CONTENT_IS_LOADING(state, isLoading) {
    state.sectionContent.isLoading = isLoading
  },
  SET_CONTENT_LIST(state, data) {
    state.contentList = data
  },
  SET_CONTENT_TOTAL(state, total) {
    state.sectionContent.totalSize = total
  },
  SET_CONTENT_PAGE(state, page) {
    state.sectionContent.page = page
  },
  SET_CONTENT_PAGE_SIZE(state, pageSize) {
    state.sectionContent.pageSize = pageSize
  },
  SET_CONTENT_TEXT_SEARCH(state, text) {
    state.sectionContent.textSearch = text
  },
  SET_SHOW_EDIT_ORDER(state, status) {
    state.isEditOrder = status
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
