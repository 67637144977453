export const typeEnum = {
  NO_LINK: 'noLink',
  LINK_WITHIN_APP: 'internalWeb',
  LINK_TO_WEBSITE: 'externalWeb'
}

export const typeText = {
  [typeEnum.NO_LINK]: 'No link',
  [typeEnum.LINK_WITHIN_APP]: 'Internal Link (In-App)',
  [typeEnum.LINK_TO_WEBSITE]: 'External Link (URL)'
}

export const bannerStatusEnum = {
  ACTIVE: 0,
  INACTIVE: 1
}

export const bannerStatus = {
  [bannerStatusEnum.ACTIVE]: 'Active',
  [bannerStatusEnum.INACTIVE]: 'Inactive'
}

export const linkToEnum = {
  LINK_WITHIN_APP: 'app',
  LINK_TO_WEBSITE: 'website'
}

export const sectionEnum = {
  COUPON: 'coupon',
  DEPARTMENT: 'department',
  DOCTOR: 'doctor',
  MEMBERCARD: 'membercard',
  NEWS: 'news',
  PACKAGE: 'package',
  EXTERNAL_WEB: 'externalWeb',
  INTERNAL_WEB: 'internalWeb',
  POPUP_IMAGE: 'popupImage',
}

export const sectionEnumText = {
  [sectionEnum.COUPON]: 'Coupon',
  [sectionEnum.DEPARTMENT]: 'Department',
  [sectionEnum.DOCTOR]: 'Doctor',
  [sectionEnum.MEMBERCARD]: 'Membercard',
  [sectionEnum.NEWS]: 'News',
  [sectionEnum.PACKAGE]: 'Package & Promotion',
  [sectionEnum.EXTERNAL_WEB]: 'Default Device Browser',
  [sectionEnum.INTERNAL_WEB]: 'In-App Browser',
  [sectionEnum.POPUP_IMAGE]: 'Popup image',
}

export const sectionsWithExpired = [sectionEnum.COUPON, sectionEnum.NEWS, sectionEnum.PACKAGE]
