<template>
  <div>
    <!--  Group Menu -->
    <v-list-group
      v-if="'isGroup' in item && item.isGroup"
      v-show="groupHasPerm(item)"
      t-data="group-menu"
      color="whiteColor"
      :class="{'menu-node-group': true, 'pl-2': item.subgroup}"
      :sub-group="item.subgroup"
    >
      <v-icon slot="appendIcon" v-if="item.subgroup">mdi-chevron-down</v-icon>
      <v-img
        t-data="group-icon"
        slot="prependIcon"
        aspect-ratio="1"
        max-height="24"
        max-width="24"
        :src="
          `/images/icons/navbar/${item.icon}-` +
            checkRoute(item.childs) +
            '.png'
        "
      ></v-img>
      <template v-slot:activator>
        <v-list-item-title v-text="item.title"></v-list-item-title>
      </template>
      <menu-node
        v-for="childItem in item.childs"
        :key="childItem.title"
        :item="childItem"
      ></menu-node>
    </v-list-group>

    <!--  Single Menu -->
    <v-list-item
      v-else
      t-data="single-menu"
      :class="{'v-list-item--active': sameRoute(item)}"
      :to="{name: item.route}"
      :target="item.hasOwnProperty('target') ? item.target : ''"
      v-show="item.title === 'Dashboard' ? true : singleHasPerm(item)"
      exact
    >
      <v-list-item-icon class="mr-4">
        <v-img
          v-if="sameRoute(item)"
          t-data="on-icon"
          aspect-ratio="1"
          v-show="item.user_member_ship !== null ? true : false"
          max-height="24"
          max-width="24"
          :src="'/images/icons/navbar/' + item.icon + '-on.png'"
        ></v-img>
        <v-img
          v-else
          t-data="off-icon"
          aspect-ratio="1"
          v-show="item.user_member_ship !== null ? true : false"
          max-height="24"
          max-width="24"
          :src="'/images/icons/navbar/' + item.icon + '-off.png'"
        ></v-img>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="item.title"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import {hasPermision, hasPermissions, isStaff} from '@/helper/helper'

export default {
  name: 'MenuNode',
  props: {
    item: {type: Object},
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  methods: {
    groupHasPerm(item) {
      const perms = item.childs.map(c => {
        return c.hasOwnProperty('permCodes') ? c.permCodes : []
      }).flat()
      return perms.length > 0 ? this.hasPerm(perms) : true
    },
    singleHasPerm(item) {
      const perms = item.hasOwnProperty('permCodes') ? item.permCodes : ''
      return perms.length > 0 ? this.hasPerm(perms) : true
    },
    hasPerm(permCodes) {
      if (permCodes.includes('CanViewAppConfig')) {
        return isStaff()
      }
      if (Array.isArray(permCodes)) {
        return hasPermissions(permCodes)
      }
      return hasPermision(permCodes)
    },
    sameRoute(item) {
      if (Object.keys(item).includes('activeRoutes')) {
        return item.activeRoutes.includes(this.currentRouteName)
      }
      return item.route === this.currentRouteName
    },
    findByRoute(arr) {
      return arr.find(a => {
        if (a.childs && a.childs.length > 0) {
          return this.sameRoute(a) ? true : this.findByRoute(a.childs)
        } else {
          return this.sameRoute(a)
        }
      })
    },
    checkRoute(items) {
      return this.findByRoute(items)
        ? 'on'
        : 'off'
    },
  }
}
</script>

<style scoped>
.v-list-item--active {
  font-family: SukhumvitTadmai-bold !important;
  font-weight: 700 !important;
  background-color: var(--primary-700-color);
  margin: 0px 6px;
  border-radius: 4px !important;
  color: var(--white-color) !important;
}

.v-list-item--link:before {
  background-color: var(--primary-700-color);
}

.v-list-item--active::v-deep div {
  font-family: SukhumvitTadmai-bold !important;
  font-weight: 700 !important;
}

.list-item-title-font-size {
  font-size: 16px !important;
  color: var(--white-color);
}
.v-list-item:hover {
  background-color: var(--hover-menu-color);
  border-radius: 4px !important;
  margin: 0px 6px;
}

.v-list-item__title {
  font-family: SukhumvitTadmai;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: var(--white-color);
}

.v-list-item,
.v-list-item::before,
.v-list-item > .v-ripple__container {
  border-radius: 4px !important;
}
</style>

<style lang="scss">
.menu-node-group > .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child{
  margin-right: 16px !important;
}
.menu-node-group > .v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 0 !important;
}
</style>