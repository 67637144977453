import storages from '@/apis/local-storages'
import client from '@/apis/http-client'

const authStorage = storages.authLocalStorage()

// Default state or reload if there is exising one.
const defaultState = () => {
  return {
    isLoggedIn: authStorage.getIsLoggedIn() || false,
    user: authStorage.getUser() || null,
    access: authStorage.getAccessToken() || null,
    refresh: authStorage.getRefreshToken() || null,
    roles: authStorage.getRoles() || null
  }
}

// State
const state = defaultState()

// Getters
const getters = {
  getUser(state) {
    return state.user
  },
  getIsLoggedIn(state) {
    return state.isLoggedIn
  },
  isForceChangePassword(state) {
    return state.user && state.user.force_change_password
  }
}

// Actions
const actions = {
  async login({commit}, {user, pass}) {
    const result = await client.Login(user, pass)

    if (result instanceof Error) return false

    commit('SET_USER', result)
    return true
  },
  async forget(_, email) {
    try {
      await client.simpleHttpClient.get(
        `/sso/rbac/user/reset_password/${email}`
      )
      return true
    } catch {
      return false
    }
  },
  async refreshUser({commit}) {
    const data = await client.refreshToken()
    commit('SET_USER', data)
  },
  async validateToken(_, {token, new_password}) {
    const url = encodeURI(`/sso/rbac/user/reset_password/${token}/confirm`)
    const result = await client.simpleHttpClient.patch(url, {
      new_password: new_password
    })
    if (result instanceof Error) return false
    return true
  },
  logout({commit}) {
    commit('UNSET_USER')
  },
  async reset({commit}, newPassword) {
    const payload = JSON.stringify({
      new_password: newPassword
    })

    const result = await client.secureHttpClient.post(
      '/sso/auth/reset/',
      payload
    )
    if (result instanceof Error) return false

    commit('UNSET_USER')
    return true
  }
}

// Mutations
const mutations = {
  SET_USER(state, {user, access, refresh, roles}) {
    authStorage.setIsLoggedIn(true)
    authStorage.setUser(user)
    authStorage.setAccessToken(access)
    authStorage.setRefreshToken(refresh)
    authStorage.setRoles(roles)

    state.isLoggedIn = true
    state.user = user
    state.access = access
    state.refresh = refresh
    state.roles = roles
  },
  UNSET_USER(state) {
    authStorage.removeAll()
    Object.assign(state, defaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
