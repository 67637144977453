import client from '@/apis/http-client'
import _ from 'lodash'
import moment from 'moment'
import Vue from 'vue'
let pathUrl = `/por/api`
// const pathOrgUrl = `por/api/organization?page_size=1000`
const pathOrgUrl = `sso/rbac/organization/permission`
import {convertFileToBase64, splitToDateAndTime} from '../helper/helper'
import qs from 'qs'
import {discountTypeEnum, displayTypeEnum, metaEnum} from '@/constants/package'

// Initial state.
const defaultState = () => {
  return {
    // Filters
    orgList: {
      all: [],
      selected: []
    },
    departmentList: [],
    promotionOnly: [],
    filterDepartment: null,
    filterStatus: '',
    filterChannel: null,
    textSearch: '',
    deleteId: 0,
    isDelete: false,
    // List of packages
    isLoading: false,
    packagesList: [],
    updateIdList: [],
    isPreview: false,
    currentPage: 1,
    totalPackages: 0,
    pageSize: 25,
    total: 0,
    sortBy: 'update_date',
    sortDesc: 'DESC',
    orgObject: [],
    // Package object
    packageObject: {
      package_type: null,
      display_type: displayTypeEnum.ALL,
      id: null,
      price: null,
      full_price: 0,
      orgs: [],
      contents: [
        {
          code: 'th',
          title: '',
          description: '',
          thumbnail: '',
          cover_image: '',
          thumbnail_alt: '',
          cover_image_alt: ''
        },
        {
          code: 'en',
          title: '',
          description: '',
          thumbnail: '',
          cover_image: '',
          thumbnail_alt: '',
          cover_image_alt: ''
        }
      ],
      meta: {
        SEO: [
          {
            code: 'th',
            title: '',
            description: '',
            image: ''
          },
          {
            code: 'en',
            title: '',
            description: '',
            image: ''
          }
        ],
        FACEBOOK: [
          {
            code: 'th',
            title: '',
            description: '',
            image: ''
          },
          {
            code: 'en',
            title: '',
            description: '',
            image: ''
          }
        ],
        X: [
          {
            code: 'th',
            title: '',
            description: '',
            image: ''
          },
          {
            code: 'en',
            title: '',
            description: '',
            image: ''
          }
        ]
      },
      is_promotion: 0,
      discount: null,
      promo_date_from: null,
      promo_date_to: null,
      period_start_date: '',
      period_end_date: '',
      period_start_time: '',
      period_end_time: '',
      validity_start_date: '',
      validity_start_time: '',
      validity_end_date: '',
      validity_end_time: '',
      status: 0,
      is_highlight: 0,
      tags: [],
      alias: '',
      discount_type: discountTypeEnum.NO_DISCOUNT,
      centrix_code: '',
      department_id: null,
      available_status: null,
      share_link: null,
      create_by_user: null,
      update_by_user: null
    },
    packageObjOld: {
      package_type: null,
      display_type: displayTypeEnum.ALL,
      id: null,
      price: null,
      full_price: 0,
      orgs: [],
      contents: [
        {
          code: 'th',
          title: '',
          description: '',
          thumbnail: '',
          cover_image: '',
          thumbnail_alt: '',
          cover_image_alt: ''
        },
        {
          code: 'en',
          title: '',
          description: '',
          thumbnail: '',
          cover_image: '',
          thumbnail_alt: '',
          cover_image_alt: ''
        }
      ],
      meta: {
        SEO: [
          {
            code: 'th',
            title: '',
            description: '',
            image: ''
          },
          {
            code: 'en',
            title: '',
            description: '',
            image: ''
          }
        ],
        FACEBOOK: [
          {
            code: 'th',
            title: '',
            description: '',
            image: ''
          },
          {
            code: 'en',
            title: '',
            description: '',
            image: ''
          }
        ],
        X: [
          {
            code: 'th',
            title: '',
            description: '',
            image: ''
          },
          {
            code: 'en',
            title: '',
            description: '',
            image: ''
          }
        ]
      },
      is_promotion: 0,
      discount: null,
      promo_date_from: null,
      promo_date_to: null,
      period_start_date: '',
      period_end_date: '',
      period_start_time: '',
      period_end_time: '',
      validity_start_date: '',
      validity_start_time: '',
      validity_end_date: '',
      validity_end_time: '',
      status: 0,
      is_highlight: 0,
      tags: [],
      alias: '',
      discount_type: discountTypeEnum.NO_DISCOUNT,
      centrix_code: '',
      department_id: null,
      available_status: null,
      share_link: null,
      create_by_user: null,
      update_by_user: null
    },
    price: 0,
    isEditMode: false,
    duplicateId: null,
    isDisableAfterPublish: false,
    isImageEnError: false,
    imageEnErrorMessage: '',
    isImageThError: false,
    imageThErrorMessage: '',
    isCoverImageEnError: false,
    coverImageEnErrorMessage: '',
    isCoverImageThError: false,
    coverImageThErrorMessage: '',
    metaImageErrorMessage: {
      [metaEnum.SEO]: {
        en: '',
        th: ''
      },
      [metaEnum.FACEBOOK]: {
        en: '',
        th: ''
      },
      [metaEnum.X]: {
        en: '',
        th: ''
      }
    },
    isMetaImageError: {
      [metaEnum.SEO]: {
        en: false,
        th: false
      },
      [metaEnum.FACEBOOK]: {
        en: false,
        th: false
      },
      [metaEnum.X]: {
        en: false,
        th: false
      }
    },
    duplicateContent: {
      package: false,
      [metaEnum.SEO]: false,
      [metaEnum.FACEBOOK]: false,
      [metaEnum.X]: false
    },
  }
}

// State
let state = defaultState()

// Function
const transformDuplicate = packageObject => {
  delete packageObject['update_date']
  delete packageObject['update_by']
  delete packageObject['create_date']
  delete packageObject['create_by']
  packageObject.contents.map(c => delete c['id'])
  return packageObject
}

// Actions
const actions = {
  async setOrgInfo({commit}, isViewOnly) {
    let mode = ''
    if (isViewOnly) {
      mode = 'View'
    } else if (state.isEditMode) {
      mode = 'Edit'
    } else {
      mode = 'Add'
    }

    let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=${mode}&category=Package`
    )
    commit('SET_ORG_LIST', data.data)
  },
  async setListOrgInfo({commit}) {
    let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=View&category=Package`
    )
    commit('SET_ORG_FILTER', data.data)
  },
  setIsLoading({commit}, show) {
    commit('SET_IS_LOADING', show)
  },
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  resetDepartmentList({commit}) {
    commit('SET_DEPARTMENT_LIST', defaultState().departmentList)
  },
  resetChangePackageTypeState({commit}) {
    commit('RESET_CHANGE_PACKAGE_TYPE_STATE')
  },
  resetChangeDisplayTypeState({commit}) {
    commit('RESET_CHANGE_DISPLAY_TYPE_STATE')
  },
  resetPackage({commit}) {
    commit('RESET_PACKAGE')
  },
  setImageError({commit}, data) {
    commit('SET_IMAGE_ERROR', data)
  },
  setImage({commit}, data) {
    commit('SET_IMAGE', data)
  },
  setCoverImageError({commit}, data) {
    commit('SET_COVER_IMAGE_ERROR', data)
  },
  setCoverImage({commit}, data) {
    commit('SET_COVER_IMAGE', data)
  },
  setMetaImageError({commit}, data) {
    commit('SET_META_IMAGE_ERROR', data)
  },
  setMetaImage({commit}, data) {
    commit('SET_META_IMAGE', data)
  },
  resetState({commit}, excludeKey) {
    commit('RESET_STATE', excludeKey)
  },
  setEditMode({commit}, isEditable) {
    commit('SET_EDIT_MODE', isEditable)
  },
  setDeleteId({commit}, id) {
    commit('SET_DELETE_ID', id)
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setFilterDepartment({commit}, departments) {
    commit('SET_FILTER_DEPARTMENT', departments)
  },
  setFilterPromotion({commit}, promotions) {
    commit('SET_FILTER_PROMOTION', promotions)
  },
  setFilterChannel({commit}, channel) {
    commit('SET_FILTER_CHANNEL', channel)
  },
  setShowPreview({commit}, status) {
    commit('SET_SHOW_PREVIEW', status)
  },
  setTextSearch({commit}, text) {
    if (text == state.textSearch) return
    commit('SET_TEXT_SEARCH', text)
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setSelectedId({commit}, ids) {
    commit('SET_UPDATE_ID', ids)
  },
  setDeletePopup({commit}, status) {
    commit('SET_POPUP_DELETE', status)
  },
  async setPackageId({commit}, id) {
    let data = await client.secureHttpClient.get(`${pathUrl}/package/${id}`)
    if (data) {
      commit('SET_PACKAGE', data.data)
      commit('SET_SHARE_LINK', data.data.share_link)
    }
  },
  async setDuplicatePackageId({commit}, id) {
    let data = await client.secureHttpClient.get(`${pathUrl}/package/${id}`)
    if (data) {
      commit('SET_PACKAGE', {
        ...data.data,
        id: defaultState().packageObject.id,
        period_start_date: defaultState().packageObject.period_start_date,
        period_end_date: defaultState().packageObject.period_end_date,
        period_start_time: defaultState().packageObject.period_start_time,
        period_end_time: defaultState().packageObject.period_end_time,
        validity_start_date: defaultState().packageObject.validity_start_date,
        validity_start_time: defaultState().packageObject.validity_start_time,
        validity_end_date: defaultState().packageObject.validity_end_date,
        validity_end_time: defaultState().packageObject.validity_end_time,
        share_link: defaultState().packageObject.share_link,
        create_by_user: defaultState().packageObject.create_by_user,
        update_by_user: defaultState().packageObject.update_by_user
      })
    }
  },
  async setPackagePreview({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/package/${id}`)
    if (data) {
      commit('SET_PACKAGE', data.data)
    }
    commit('SET_IS_LOADING', false)
  },
  /// EDIT call
  async update({commit, dispatch}, status) {
    let ids = state.updateIdList.map(id => {
      return id.id
    })
    let body = {ids: ids, status: status === 'Active' ? 0 : 1}

    try {
      await client.secureHttpClient.patch(
        `${pathUrl}/package/bulk-status-update`,
        body
      )
      dispatch('fetch')
      commit('SET_UPDATE_ID', [])
      return true
    } catch (e) {
      dispatch('fetch')
      Vue.$log.error(e)
      return false
    }
  },
  async upload(_, payload) {
    try {
      let data = await client.secureHttpClient.post(
        `${pathUrl}/uploadImage`,
        payload
      )
      return data.data.url
    } catch (e) {
      Vue.$log.error(e)
      return ''
    }
  },
  async deletePkg({commit, dispatch}) {
    try {
      commit('SET_IS_LOADING', true)
      await client.secureHttpClient.delete(
        `${pathUrl}/package/${state.deleteId}`
      )
      commit('SET_POPUP_DELETE', false)
      commit('SET_DELETE_ID', 0)
      dispatch('fetch')
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      commit('SET_POPUP_DELETE', false)
      return false
    }
  },
  // API calls
  async fetch({commit}) {
    const {
      currentPage,
      pageSize,
      textSearch,
      filterDepartment,
      promotionOnly,
      filterChannel,
      filterStatus,
      sortBy,
      sortDesc,
      orgList
    } = state
    commit('SET_IS_LOADING', true)
    try {
      const params = {
        page: currentPage,
        limit: pageSize,
        q: textSearch,
        orderBy: sortBy,
        sort: sortDesc,
        status: filterStatus,
        ...(filterDepartment !== null && {department_id: filterDepartment}),
        ...(filterChannel !== null && {display_type: filterChannel}),
        ...(promotionOnly.length > 0 && {promotion: promotionOnly[0]}),
        ...(orgList.selected.length > 0 && {
          orgs: orgList.selected.map(o => o.codename)
        })
      }
      let data = await client.secureHttpClient.get(`${pathUrl}/package`, {
        params,
        paramsSerializer: params => qs.stringify(params, {encode: false})
      })
      commit('SET_TOTAL_PACKAGES', data.data.total)
      commit('SET_PACKAGES_LIST', data.data.results)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async fetchDepartment({commit}, orgs) {
    try {
      const params = {
        org_codename: orgs
      }
      let data = await client.secureHttpClient.get(
        `${pathUrl}/departmentlist?lang=en`,
        {
          params,
          paramsSerializer: params => qs.stringify(params, {encode: false})
        }
      )
      commit('SET_DEPARTMENT_LIST', data.data.data)
    } catch (e) {
      Vue.$log.error(e)
    }
  },
  async setAlias({commit}, alias) {
    const params = {
        alias: alias,
        db: 'packages',
        id: state.packageObject.id
    }
    try {
      let data = await client.secureHttpClient.post(
        `${pathUrl}/alias/checked`,
        {
          ...params,
          paramsSerializer: params => qs.stringify(params, {encode: false})
        }
      )
      if (data.data.success) {
        commit('SET_ALIAS', data.data.data)
      }
    } catch (e) {
      Vue.$log.error(e)
    }
  },
  setStyleImage({commit}) {
    commit('SET_STYLE_CONTENT')
  },

  async updatePkg({commit}, payload) {
    const {pkg, id} = payload
    commit('SET_IS_LOADING', true)
    pkg.discount = pkg.discount ? pkg.discount : 0

    try {
      let data = await client.secureHttpClient.put(
        `${pathUrl}/package/${id}`,
        pkg
      )
      commit('SET_IS_LOADING', false)
      pkg.orgs = pkg.orgs[0].codename
      return data.data.success
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async create({commit}, pkg) {
    commit('SET_IS_LOADING', true)
    pkg.full_price = parseInt(pkg.full_price)
    pkg.discount = pkg.discount ? pkg.discount : 0
    const packageObject = transformDuplicate(pkg)

    try {
      let create = await client.secureHttpClient.post(
        `${pathUrl}/package/`,
        packageObject
      )
      commit('SET_IS_LOADING', false)
      commit('SET_PACKAGE_ID', create.data.id)
      return create.data.success
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async generateShareLink({commit}, id) {
    try {
      let data = await client.secureHttpClient.patch(
        `${pathUrl}/package/${id}/generate-share-link`
      )
      if (data) {
        commit('SET_SHARE_LINK', data.data.share_link)
        return data.data.success
      }
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  setDuplicateId({commit}, id) {
    commit('SET_DUPLICATE_ID', id)
  },
  async setPreview({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/package/${id}`)
    if (data) {
      commit('SET_PACKAGE', data.data)
      commit('SET_SHOW_PREVIEW', true)
    }
    commit('SET_IS_LOADING', false)
  }
}

// Mutations
const mutations = {
  SET_ORG_LIST(state, org) {
    let results = org.data.map(object => {
      return {text: object.name, value: object.codename}
    })
    state.orgObject = results
  },
  SET_ORG_FILTER(state, org) {
    const selected = []

    if (org.data.length === 1) {
      org.data.forEach(d =>
        selected.push({
          codename: d.codename,
          selected: true
        })
      )
    }
    state.orgList.selected = selected
    state.orgList.all = org.data
  },
  SET_DEPARTMENT_LIST(state, departments) {
    state.departmentList = departments
  },
  SET_ALIAS(state, alias) {
    state.packageObject.alias = alias
  },
  RESET_STATE(state, excludeKey) {
    if (excludeKey !== undefined) {
      state.packageObject = {
        [excludeKey]: state.packageObject[excludeKey],
        ..._.omit(defaultState().packageObject, excludeKey)
      }
      state.packageObjOld = {
        [excludeKey]: state.packageObjOld[excludeKey],
        ..._.omit(defaultState().packageObjOld, excludeKey)
      }
    } else {
      state.packageObject = defaultState().packageObject
      state.packageObjOld = defaultState().packageObjOld
    }
    state.departmentList = defaultState().departmentList
    state.duplicateId = defaultState().duplicateId
    state.isDisableAfterPublish = defaultState().isDisableAfterPublish
    state.isImageEnError = defaultState().isImageEnError
    state.imageEnErrorMessage = defaultState().imageEnErrorMessage
    state.isImageThError = defaultState().isImageThError
    state.imageThErrorMessage = defaultState().imageThErrorMessage
    state.isCoverImageEnError = defaultState().isCoverImageEnError
    state.coverImageEnErrorMessage = defaultState().coverImageEnErrorMessage
    state.isCoverImageThError = defaultState().isCoverImageThError
    state.coverImageThErrorMessage = defaultState().coverImageThErrorMessage
    state.isMetaImageError = defaultState().isMetaImageError
    state.metaImageErrorMessage = defaultState().metaImageErrorMessage
    state.duplicateContent = defaultState().duplicateContent
  },
  RESET_LIST_STATE(state) {
    state.currentPage = defaultState().currentPage
    state.pageSize = defaultState().pageSize
    state.textSearch = defaultState().textSearch
    state.filterStatus = defaultState().filterStatus
    state.filterDepartment = defaultState().filterDepartment
    state.promotionOnly = defaultState().promotionOnly
    state.sortBy = defaultState().sortBy
    state.sortDesc = defaultState().sortDesc
    state.orgList = defaultState().orgList
    state.filterChannel = defaultState().filterChannel
  },
  RESET_CHANGE_PACKAGE_TYPE_STATE(state) {
    state.packageObject.full_price = defaultState().packageObject.full_price
    state.packageObject.price = defaultState().packageObject.price
    state.packageObject.discount = defaultState().packageObject.discount
    state.packageObject.discount_type = defaultState().packageObject.discount_type
    state.packageObject.centrix_code = defaultState().packageObject.centrix_code
  },
  RESET_CHANGE_DISPLAY_TYPE_STATE(state) {
    state.packageObject.tags = defaultState().packageObject.tags
    state.packageObject.alias = defaultState().packageObject.alias
    state.packageObject.contents[0].thumbnail_alt = defaultState().packageObject.contents[0].thumbnail_alt
    state.packageObject.contents[1].thumbnail_alt = defaultState().packageObject.contents[1].thumbnail_alt
    state.packageObject.contents[0].cover_image_alt = defaultState().packageObject.contents[0].cover_image_alt
    state.packageObject.contents[1].cover_image_alt = defaultState().packageObject.contents[1].cover_image_alt
    state.packageObject.meta = defaultState().packageObject.meta
    state.isMetaImageError = defaultState().isMetaImageError
    state.metaImageErrorMessage = defaultState().metaImageErrorMessage
    state.duplicateContent = defaultState().duplicateContent
  },
  RESET_PACKAGE(state) {
    state.packageObject = JSON.parse(JSON.stringify(state.packageObjOld))
    state.isImageEnError = defaultState().isImageEnError
    state.imageEnErrorMessage = defaultState().imageEnErrorMessage
    state.isImageThError = defaultState().isImageThError
    state.imageThErrorMessage = defaultState().imageThErrorMessage
    state.isCoverImageEnError = defaultState().isCoverImageEnError
    state.coverImageEnErrorMessage = defaultState().coverImageEnErrorMessage
    state.isCoverImageThError = defaultState().isCoverImageThError
    state.coverImageThErrorMessage = defaultState().coverImageThErrorMessage
    state.isMetaImageError = defaultState().isMetaImageError
    state.metaImageErrorMessage = defaultState().metaImageErrorMessage
    state.duplicateContent = defaultState().duplicateContent
  },
  SET_STYLE_CONTENT(state) {
    state.packageObject.contents[0].description = state.packageObject.contents[0].description.split('<img')
      .map((imgTag) => {
        const srcMatch = imgTag.match(/src="([^"]*)"/); // Extract the image URL
        if (srcMatch && srcMatch[1]) {
          const imageUrl = srcMatch[1];
          // If the image URL does not contain 'emoji', add width and height attributes
          if (!imageUrl.includes('emoji')) {
            return ' width="100%" height="auto" ' + imgTag;
          }
        }
        return imgTag;
      })
      .join('<img');

      state.packageObject.contents[1].description = state.packageObject.contents[1].description.split('<img')
      .map((imgTag) => {
        const srcMatch = imgTag.match(/src="([^"]*)"/); // Extract the image URL
        if (srcMatch && srcMatch[1]) {
          const imageUrl = srcMatch[1];
          if (!imageUrl.includes('emoji')) {
            return ' width="100%" height="auto" ' + imgTag;
          }
        }
        return imgTag;
      })
      .join('<img');
  },
  SET_EDIT_MODE(state, isEdit) {
    state.isEditMode = isEdit
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  SET_FILTER_DEPARTMENT(state, departments) {
    state.filterDepartment = departments
  },
  SET_FILTER_PROMOTION(state, status) {
    state.promotionOnly = status
  },
  SET_FILTER_CHANNEL(state, channel) {
    state.filterChannel = channel
  },
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  SET_TOTAL_PACKAGES(state, total) {
    state.totalPackages = total
  },
  SET_PACKAGES_LIST(state, packages) {
    state.packagesList = packages
  },
  SET_PACKAGE_ID(state, id) {
    state.packageObject.id = id
  },
  SET_PACKAGE(state, pkg) {
    state.packageObject = {...defaultState().packageObject, ...pkg}

    const orgs = pkg.orgs.map(org => org.org_codename)
    const available_status = pkg.orgs.map(org => org.is_for_sales)

    state.packageObject.orgs = orgs
    state.packageObject.available_status =
      available_status.length > 0 ? available_status[0] : null
    state.packageObject.status = pkg.status

    const start = !pkg.period_start_date
      ? {dateString: '', timeString: ''}
      : splitToDateAndTime(pkg.period_start_date)
    state.packageObject.period_start_date = start.dateString
    state.packageObject.period_start_time = start.timeString
    const end = !pkg.period_end_date
      ? {dateString: '', timeString: ''}
      : splitToDateAndTime(pkg.period_end_date)
    state.packageObject.period_end_date = end.dateString
    state.packageObject.period_end_time = end.timeString

    const validityStart = !pkg.validity_start_date
      ? {dateString: '', timeString: ''}
      : splitToDateAndTime(pkg.validity_start_date)
    state.packageObject.validity_start_date = validityStart.dateString
    state.packageObject.validity_start_time = validityStart.timeString
    const validityEnd = !pkg.validity_end_date
      ? {dateString: '', timeString: ''}
      : splitToDateAndTime(pkg.validity_end_date)
    state.packageObject.validity_end_date = validityEnd.dateString
    state.packageObject.validity_end_time = validityEnd.timeString

    state.packageObject.discount_type =
      pkg.is_promotion === 0 ? discountTypeEnum.NO_DISCOUNT : pkg.discount_type
    state.packageObject.discount = pkg.is_promotion === 0 ? null : pkg.discount

    state.isDisableAfterPublish = moment(pkg.period_start_date).isSameOrBefore()

    if (state.packageObject.meta === null) {
      state.packageObject.meta = {...defaultState().packageObject.meta}
    }

    // For reset if not duplicate mode
    if (state.duplicateId === null) {
      state.packageObjOld = JSON.parse(JSON.stringify(state.packageObject))
    } else {
      // On duplicate mode keep only package_type
      state.packageObjOld.package_type = JSON.parse(JSON.stringify(state.packageObject)).package_type
    }
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_UPDATE_ID(state, ids) {
    state.updateIdList = ids
  },
  SET_IMAGE_ERROR(state, {lang, isError, message}) {
    if (lang === 'en') {
      state.isImageEnError = isError
      state.imageEnErrorMessage = message
    }
    if (lang === 'th') {
      state.isImageThError = isError
      state.imageThErrorMessage = message
    }
  },
  SET_IMAGE(state, {lang, file}) {
    convertFileToBase64(file)
      .then(base64 => {
        if (lang === 'th') {
          state.packageObject.contents[0].thumbnail = base64
        }
        if (lang === 'en') {
          state.packageObject.contents[1].thumbnail = base64
        }
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_COVER_IMAGE_ERROR(state, {lang, isError, message}) {
    if (lang === 'en') {
      state.isCoverImageEnError = isError
      state.coverImageEnErrorMessage = message
    }
    if (lang === 'th') {
      state.isCoverImageThError = isError
      state.coverImageThErrorMessage = message
    }
  },
  SET_COVER_IMAGE(state, {lang, file}) {
    convertFileToBase64(file)
      .then(base64 => {
        if (lang === 'th') {
          state.packageObject.contents[0].cover_image = base64
        }
        if (lang === 'en') {
          state.packageObject.contents[1].cover_image = base64
        }
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_META_IMAGE_ERROR(state, {lang, type, isError, message}) {
    state.isMetaImageError[type][lang] = isError
    state.metaImageErrorMessage[type][lang] = message
  },
  SET_META_IMAGE(state, {lang, type, file}) {
    convertFileToBase64(file)
      .then(base64 => {
        if (lang === 'th') {
          state.packageObject.meta[type][0].image = base64
        }
        if (lang === 'en') {
          state.packageObject.meta[type][1].image = base64
        }
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_POPUP_DELETE(state, status) {
    state.isDelete = status
  },
  SET_DELETE_ID(state, id) {
    state.deleteId = id
  },
  SET_SHOW_PREVIEW(state, status) {
    state.isPreview = status
  },
  SET_SHARE_LINK(state, shareLink) {
    state.packageObject.share_link = shareLink
    state.packageObjOld.share_link = shareLink
  },
  SET_DUPLICATE_ID(state, id) {
    state.duplicateId = id
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
