export const discountTypeEnum = {
  NO_DISCOUNT: 'NO_DISCOUNT',
  PERCENTAGE: 'PERCENTAGE',
  PRICE: 'PRICE'
}

export const discountTypeText = {
  [discountTypeEnum.NO_DISCOUNT]: 'No Discount',
  [discountTypeEnum.PERCENTAGE]: 'Percentage (%)',
  [discountTypeEnum.PRICE]: 'Price (Baht)',
}

export const packageTypeEnum = {
  SINGLE: 'SINGLE',
  GROUP: 'GROUP',
}

export const packageTypeText = {
  [packageTypeEnum.SINGLE]: 'Single package',
  [packageTypeEnum.GROUP]: 'Group package',
}

export const displayTypeEnum = {
  ALL: 'ALL',
  MOBILE: 'MOBILE',
  WEB: 'WEB',
}

export const displayTypeText = {
  [displayTypeEnum.ALL]: 'All',
  [displayTypeEnum.MOBILE]: 'Mobile Application',
  [displayTypeEnum.WEB]: 'Website',
}

export const metaEnum = {
  SEO: 'SEO',
  FACEBOOK: 'FACEBOOK',
  X: 'X',
}

export const metaText = {
  [metaEnum.SEO]: 'SEO Meta',
  [metaEnum.FACEBOOK]: 'Facebook Meta',
  [metaEnum.X]: 'X Meta',
}
