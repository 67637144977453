import client from '@/apis/http-client'

const defaultState = () => {
    return {
        token: ''
    }
}
const state = defaultState()

const verify = async ({commit}, {token}) => {
    try {
        let data = await client.simpleHttpClient.post('/por/api/user/verify', {
            email_token: token
        });
        commit('SET_TOKEN', data)
        return data.data.success;
    } catch (e) {
        return false;
    }
}
const mutations = {
    SET_TOKEN(state, {token}) {
        state.token = token;
    }
}

const actions = {
    verify
}

export default {
    namespaced: true,
    state,
    actions, mutations
}
