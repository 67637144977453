import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {store} from './store'
import vuetify from './plugins/vuetify'
import VueQuillEditor from 'vue-quill-editor'
// import 'vue-material-design-icons/styles.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import './sass/main.scss'

// load plugins
import './plugins/vuelidate'
import './plugins/vuejs-logger'
import './plugins/branch-io'

Vue.use(VueQuillEditor, {})

Vue.config.productionTip = false

Vue.use(vuetify, {
  iconfont: 'md'
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
