<template>
  <v-app>
    <v-app-bar app color="primary" dark dense>
      <v-spacer></v-spacer>
      <v-toolbar-title>ADMIN PORTAL</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'simple'
}
</script>
