import client from '@/apis/http-client'
import moment from 'moment'
// import qs from 'qs'
const pathOrgUrl = `sso/rbac/organization/permission`

import {convertFileToBase64} from '../helper/helper'
import Vue from 'vue'
import qs from 'qs'
let pathUrl = `/por/api`
const defaultState = () => ({
  // Listing mode
  orgList: {
    all: [],
    selected: [],
  },
  isPreview: false,
  totalNotifications: 0,
  notificationsList: [],
  listCurrentPage: 1,
  listPageSize: 25,
  filterType: '',
  sortBy: 'create_date',
  sortDesc: 'DESC',
  textSearch: '',
  sections: [],
  page: 1,
  pageSize: 25,
  total: 0,
  isBrowser: false,
  isLoading: false,
  statusTab: 0,
  orgObject: [],
  provinces: ['All'],
  // Create/Edit mode
  isEditMode: false,
  notificationObject: {
    id: null,
    target: '0',
    // target
    gender: '',
    ageFrom: '0',
    ageTo: '999',
    province: 'All',
    registerDateFrom: '2019-11-01',
    registerDateTo: moment().format('YYYY-MM-DD'),
    publishDate: '0',
    // publishDate
    date: '',
    time: '',
    section_id: null,
    content_id: null,
    browser_url: '',
    section: null,
    orgs: [],
    image: '',
    contents: [
      {
        id: '',
        code: 'th',
        title: '',
        description: '',
      },
      {
        id: '',
        code: 'en',
        title: '',
        description: '',
      }
    ],
    status: '1',
    active_date: null,
    linkedContent: null
  }
})

// Initial state
let state = defaultState()

// Actions
const actions = {
  setImage({commit}, file) {
    commit('SET_IMAGE', file)
  },
  setTextSearch({commit}, text) {
    if (text === state.textSearch) return
    commit('SET_TEXT_SEARCH', text)
  },
  setTabStatus({commit}, status) {
    commit('SET_TAB_STATUS', status)
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setFilterType({commit}, status) {
    commit('SET_FILTER_TYPE', status)
  },
  resetState({commit}) {
    commit('RESET_STATE')
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setLoading({commit}, status) {
    commit('SET_LOADING', status)
  },
  setBrowserMode({commit}, value) {
    commit('SET_BROWSER_MODE', value)
  },
  resetBrowserMode({commit}) {
    commit('RESET_BROWSER_MODE')
  },
  async setListOrgInfo({commit}) {
    commit('SET_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathOrgUrl}?permission=View&category=Notification`)
    commit('SET_ORG_FILTER', data.data)
    commit('SET_LOADING', false)
  },
  async setOrgInfo({commit}) {
    commit('SET_LOADING', true)
    let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=${
        state.isEditMode ? 'Edit' : 'Add'
      }&category=Banner`
    )
    commit('SET_ORG_LIST', data.data)
    commit('SET_LOADING', false)
  },
  setLinkedContent({commit}, payload) {
    commit('SET_SECTION_ID', payload)
    commit(
      'SET_LINKED_CONTENT',
      payload.contents
        ? payload.contents
        : defaultState().notificationObject.linkedContent
    )
  },
  async fetch({commit}) {
    const {
      listCurrentPage,
      listPageSize,
      textSearch,
      filterType,
      sortBy,
      sortDesc,
      orgList,
    } = state
    commit('SET_LOADING', true)
    try {
      const params = {
        page: listCurrentPage,
        limit: listPageSize,
        q: textSearch,
        type: filterType,
        orderBy: sortBy,
        sort: sortDesc,
        orgs: orgList.selected.filter(({selected}) => selected).map(({codename}) => codename),
      }

      let data = await client.secureHttpClient.get(`${pathUrl}/notification`, {params, paramsSerializer: params => qs.stringify(params, { encode: false })})
      commit('SET_TOTAL_NOTIFICATIONS', data.data.total)
      commit('SET_NOTIFICATIONS_LIST', data.data.results)
      commit('SET_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_LOADING', false)
    }
  },
  async fetchContentList(_, payload) {
    let apiUrl = `${pathUrl}/banner/section/${payload.id}/content`
    const params = {
      page: payload.page,
      limit: payload.pageSize,
      q: payload.search,
      org_codename: payload.orgs,
    }
    let data = await client.secureHttpClient.get(apiUrl, {params, paramsSerializer: params => qs.stringify(params, { encode: false })})

    return data.data
  },
  async fetchProvinceList({commit}) {
    commit('SET_LOADING', true)
    let apiUrl = `${pathUrl}/notification/regions`
    let data = await client.secureHttpClient.get(apiUrl)
    const provinces = ['All', ...data.data.data]
    commit('SET_PROVINCES', provinces)
    commit('SET_LOADING', false)
  },
  setEditMode({commit}, isEditable) {
    commit('SET_EDIT_MODE', isEditable)
  },
  async fetchSections({commit}) {
    commit('SET_LOADING', true)
    let data = await client.secureHttpClient.get(
      `${pathUrl}/banner/section/list?isWebbrowser=${state.isBrowser}`
    )

    commit('SET_SECTIONS', data.data)
    commit('SET_LOADING', false)
  },
  setShowPreview({commit}, status) {
    commit('SET_SHOW_PREVIEW', status)
  },
  async setNotificationeId({commit}, id) {
    commit('SET_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/notification/${id}`)
    if (data) {
      commit('SET_NOTIFICATION', data.data)
    }
    commit('SET_LOADING', false)
  },
  async setNotificationPreview({commit}, id) {
    commit('SET_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/notification/${id}`)
    if (data) {
      commit('SET_NOTIFICATION', data.data.data)
      commit('SET_SHOW_PREVIEW', true)
    }
    commit('SET_LOADING', false)
  },
  async create({commit}) {
    let payload = {
      type: state.notificationObject.target === '0' ? 'all' : 'customize',
      title_th: state.notificationObject.contents[0].title,
      message_th: state.notificationObject.contents[0].description,
      title_en: state.notificationObject.contents[1].title,
      message_en: state.notificationObject.contents[1].description,
      org_codename: state.notificationObject.orgs.map(({codename}) => codename),
      image: state.notificationObject.image,
    }
    if (payload.type === 'customize') {
      payload = {
        ...payload,
        minage: state.notificationObject.ageFrom,
        maxage: state.notificationObject.ageTo,
        gender: state.notificationObject.gender,
        register_date_from: state.notificationObject.registerDateFrom,
        register_date_to: state.notificationObject.registerDateTo,
        region: state.notificationObject.province === 'All' ? '' : state.notificationObject.province,
      }
    }
    if (state.notificationObject.publishDate === '1') {
      payload = {
        ...payload,
        send_after: `${state.notificationObject.date} ${state.notificationObject.time}:00`,
      }
    }
    if (state.notificationObject.content_id) {
      payload = {
        ...payload,
        content: {
          section_id: state.notificationObject.section_id,
          content_id: state.notificationObject.content_id,
        },
      }
    }
    if (state.notificationObject.browser_url) {
      payload = {
        ...payload,
        content: {
          section_id: state.notificationObject.section,
          browser_url: state.notificationObject.browser_url,
          content_id: '0',
        },
      }
    }
    try {
      commit('SET_LOADING', true)
      await client.secureHttpClient.post(`${pathUrl}/notification`, payload)
      commit('SET_LOADING', false)
      return true
    }
    catch (e) {
      commit('SET_LOADING', false)
      return false
    }
  },
}

// Mutations
const mutations = {
  SET_TOTAL_NOTIFICATIONS(state, total) {
    state.totalNotifications = total
  },
  SET_NOTIFICATIONS_LIST(state, notifications) {
    state.notificationsList = notifications
  },
  SET_NOTIFICATION(state, notification) {
    state.notificationObject = notification
    state.notificationObject.contents = [
      {
        code: 'th',
        title: notification.title_th,
        description: notification.message_th,
      },
      {
        code: 'en',
        title: notification.title_en,
        description: notification.message_en,
      }
    ]
  },
  SET_ORG_FILTER(state, org) {
    state.orgList.selected = org.data.map(({codename}) => codename)
    state.orgList.all = org.data
  },
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  RESET_STATE(state) {
    state.notificationObject = defaultState().notificationObject
  },
  SET_EDIT_MODE(state, isEdit) {
    state.isEditMode = isEdit
  },
  SET_LINKED_CONTENT(state, content) {
    state.notificationObject.linkedContent = content
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_FILTER_TYPE(state, type) {
    state.filterType = type
  },
  SET_LOADING(state, status) {
    state.isLoading = status
  },
  SET_TAB_STATUS(state, status) {
    state.statusTab = status
  },
  SET_IMAGE(state, file) {
    convertFileToBase64(file)
      .then(base64 => {
        state.notificationObject.image= base64
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_SECTIONS(state, sections) {
    state.sections = sections
  },
  SET_PROVINCES(state, provinces) {
    state.provinces = provinces
  },
  SET_SECTION_ID(state, payload) {
    state.notificationObject.section_id = payload.section_id
    state.notificationObject.content_id = payload.content_id
  },
  SET_BROWSER_MODE(state, value) {
    state.isBrowser = value
  },
  RESET_BROWSER_MODE(state) {
    state.isBrowser = defaultState().isBrowser
    state.notificationObject.browser_url = null
    state.notificationObject.section = null
    state.notificationObject.linkedContent = null
  },
  SET_ORG_LIST(state, org) {
    state.orgObject = org.data
  },
  SET_PAGE(state, page) {
    state.listCurrentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.listPageSize = size
  },
  SET_SHOW_PREVIEW(state, status) {
    state.isPreview = status
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
