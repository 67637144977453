import client from '@/apis/http-client'
// import moment from 'moment'
import Vue from 'vue'
import storages from '../apis/local-storages'
const pathUrl = `por/api/me/profile`
const adminPathUrl = `por/api/admin`

const authStorage = storages.authLocalStorage()

// Initial state.
const defaultState = () => {
  return {
    isLoading: false,
    userObject: {
      id: null,
      first_name: '',
      last_name: '',
      email: '',
    },
    passwordObject: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    }
  }
}

// State
let state = defaultState()

// Actions
const actions = {
  resetState({commit}) {
    commit('RESET_STATE')
  },
  // API calls
  async fetch({commit}) {
    commit('SET_IS_LOADING', true)
    try {
      let data = await client.secureHttpClient.get(pathUrl)
      commit('SET_USER', data.data)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async update({commit, dispatch}, {first_name, last_name, email}) {
    commit('SET_IS_LOADING', true)
    try {
      const body = {first_name, last_name, email, username: email}
      const req = await client.secureHttpClient.patch(pathUrl, body)
      await client.refreshToken()
      dispatch('fetch')
      commit('SET_IS_LOADING', false)
      return req.status === 200
    }
    catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async chkExistingEmail({commit}, {email, id}) {
    try {
      let chkEmail = await client.secureHttpClient.post(`${adminPathUrl}/email/validation`, {email, id})
      commit('SET_IS_LOADING', false)
      return chkEmail.status === 200
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async changePassword({commit}, {current_password, new_password}) {
    try {
      commit('SET_IS_LOADING', true)
      await client.secureHttpClient.patch(`/por/api/me/profile/change_password`, {current_password, new_password})
      commit('SET_IS_LOADING', false)
      authStorage.setLastLogin()
      return {status: true}
    }
    catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return {status: false, msg: e.response.data.message}
    }
  }
}

const mutations = {
  RESET_STATE(state) {
    state.userObject = defaultState().userObject
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_USER(state, user) {
    state.userObject = user
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

