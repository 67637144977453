<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      :mini-variant="miniVariant"
      :clipped="clipped"
      permanent
      color="primary800Color"
    >
      <v-list-item class="v-list-item-avatar justify-center" dark>
        <v-img
          t-data="page-logo"
          aspect-ratio="1"
          height="40"
          width="40"
          max-height="40"
          max-width="40"
          :src="navLogo"
          class="mr-4"
        ></v-img>

        <v-list-item-content>
          <v-list-item-title t-data="page-headline" class="navbar-logo-text"
            >ADMIN PORTAL</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <!-- Menu -->
      <v-list dense dark t-data="menu-contents">
        <menu-node v-for="(item, i) in items" :key="i" :item="item"></menu-node>
      </v-list>

      <v-divider></v-divider>

      <!-- Actions menu -->
      <v-list dense dark>
        <v-list-item-group>
          <!-- Settings -->
          <!-- <v-list-item :to="{name: 'settings'}">
            <v-list-item-icon>
              <v-icon>settings</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->

          <!-- Logout menu -->
          <v-list-item link @click.stop="dialog = true">
            <v-list-item-icon>
              <v-icon t-data="logout-btn">logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title t-data="logout-content">Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append v-if="!miniVariant">
        <div class="pa-3 text-center" style="color: white">
          {{ env }} Version: {{ version }}
        </div>
      </template>
    </v-navigation-drawer>

    <!-- Application toolbar -->
    <v-app-bar color="whiteColor" :clipped-left="clipped" app fixed light>
      <v-btn @click.stop="miniVariant = !miniVariant" icon>
        <v-icon t-data="icon-menu">mdi-{{ `${miniVariant ? 'menu' : 'menu-open'}` }}</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-flex>
        <v-row class="float-right">
          <v-col>
            <v-avatar size="40">
              <v-img
                t-data="avatar-img"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADIBAMAAABfdrOtAAAAG1BMVEUAAAD///8/Pz+/v7/f399/f3+fn58fHx9fX18kPjWPAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABrklEQVR4nO3UT0/CMBjH8ccx2I56IF6nkHglQoxHnX/gSKIvwBi9TyWel7gX7tP2GQpEE4revp+EluX3ZN3ariIAAAAAAAAAAAAAAAAA8JvjwrXZsb94Ldbj5HtgVdtKJuPLuXbX4xu9Gl5N5qv54GrSXwZWtbVeIdmt3qSQtwdJSsnOVvNHkcUyCFVxjiqZaTeTtBb/W5UctkGoipMW+YV2H9Jom4828tICq4rTqXtuJV7kWdtsX5tuoc17m59bYFVx9qpOrV1anburqf50DSRbTsyhhMCq4gZpdMK06xSluzp1jS7xorZYZykEVhU3yCw8Xqd2LxGeOp9+vUhaSwisKmqM7mhzEGma5c3u5A8GaarN6ZJuv43zqew+XcnUHu/bwusX+NQu8LCS3Rde11jWt7Burby0Z3D9rls491v2QtxnNpT2Y9St1RQ+b9yTh8CqItz713dbqZS0lrA8bmv5V5Cunz0LQtX2emEJhrU/IGd2QPb0pjJw/8LSWBCqtnd0oObuxHeH+GD9qE9c3G8Dq4qWnfju9ae9EwKrAgAAAAAAAAAAAAAAAPAfPgFZpkiD9I8rfAAAAABJRU5ErkJggg=="
              ></v-img>
            </v-avatar>
          </v-col>
          <v-col :to="{name: 'my-profile'}">
            <v-list-item-title t-data="profile-name">{{ name }}</v-list-item-title>
            <v-list-item-subtitle t-data="profile-email">{{ email }}</v-list-item-subtitle>
          </v-col>
        </v-row>
      </v-flex>
    </v-app-bar>

    <!-- Dialog -->
    <div class="text-center">
      <v-dialog max-width="45%" v-model="dialog">
        <template v-slot:activator="{on}">
          <v-btn text v-on="on">
            <span class="mr-2">Logout</span>
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>

        <v-card class="pa-10">
          <v-card-title class="headline">
            <v-spacer></v-spacer>Are you sure you want to logout?
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grayscale" class="primaryTextColor--text" @click="cancel">Cancel</v-btn>
            <v-btn color="primary" @click="confirm">Yes</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {version} from '../../package'
import {hasPermision, hasPermissions} from '../helper/helper'
import MenuNode from '@/components/MenuNode'
import {imageNavBar} from '@/constants/constants'

export default {
  components: {
    MenuNode
  },
  computed: {
    navLogo: function() {
      return imageNavBar
    },
    env: function() {
      const nodeEnv = process.env.VUE_APP_ENV_NAME || 'development'
      return nodeEnv.charAt(0).toUpperCase() + nodeEnv.slice(1)
    },
    version: function() {
      return version
    },
    name: function() {
      return this.user.name
    },
    email: function() {
      return this.user.email
    },
    currentRouteName() {
      return this.$route.name
    },
    ...mapState('auth', ['user'])
  },

  methods: {
    ...mapActions('auth', ['logout']),
    routeTo(route) {
      this.$router.push({name: route})
    },
    cancel() {
      this.dialog = false
    },
    hasPerm(permCodes) {
      if (Array.isArray(permCodes)) {
        return hasPermissions(permCodes)
      }
      return hasPermision(permCodes)
    },
    confirm() {
      this.dialog = false
      setTimeout(() => {
        this.logout()
        this.$router.push({name: 'login'})
      }, 100)
    },
    checkRoute(items) {
      return items.find(item => item.route === this.currentRouteName)
        ? 'on'
        : 'off'
    },
  },

  data() {
    return {
      dialog: false,
      drawer: true,
      miniVariant: false,
      clipped: false,
      items: [
        {
          title: 'Dashboard',
          icon: 'dashboard',
          route: 'dashboard',
          permCodes: ''
        },
        {
          title: 'Customers',
          icon: 'customers',
          route: 'user-list',
          activeRoutes: ['user-list', 'user-detail'],
          permCodes: ['CanViewBindedCustomer', 'CanViewAllCustomer']
        },
        {
          title: 'News',
          icon: 'news',
          route: 'news-list',
          activeRoutes: ['news-list', 'news-create', 'news-edit'],
          permCodes: 'CanViewNews'
        },
        {
          title: 'Package & Promotion',
          icon: 'package-promo',
          route: 'package-list',
          activeRoutes: ['package-list', 'package-create', 'package-edit'],
          permCodes: 'CanViewPackage'
        },
        {
          title: 'Coupon',
          icon: 'coupon',
          route: 'coupon-list',
          activeRoutes: ['coupon-list', 'coupon-create', 'coupon-edit'],
          permCodes: 'CanViewCoupon'
        },
        {
          title: 'Order Management',
          icon: 'order',
          isGroup: true,
          subgroup: false,
          childs: [
            {
              title: 'Order',
              route: 'order',
              activeRoutes: ['order', 'order-detail'],
              permCodes: 'CanViewOrderPackage',
            },
            {
                title: 'Digital Product',
                route: 'digital-product',
                permCodes: 'CanViewOrderPackage',
            },
          ]
        },
        {
          title: 'Slide Banner',
          icon: 'banner',
          route: 'banner-list',
          activeRoutes: ['banner-list', 'banner-create', 'banner-edit'],
          permCodes: 'CanViewBanner'
        },
        {
          title: 'Hero Banner',
          icon: 'hero-banner',
          route: 'hero-banner-list',
          activeRoutes: ['hero-banner-list', 'hero-banner-create', 'hero-banner-edit'],
          permCodes: 'CanViewHeroBanner'
        },
        {
          title: 'Bulk Data',
          icon: 'bulk-data',
          isGroup: true,
          subgroup: false,
          childs: [
            {
              title: 'Import',
              route: 'import-file',
              activeRoutes: ['import-file'],
              permCodes: ['CanBulkDepartment', 'CanBulkDoctor']
            },
          ]
        },
        {
          title: 'Doctor',
          icon: 'doctor',
          route: 'doctor-list',
          activeRoutes: ['doctor-list', 'doctor-create', 'doctor-edit'],
          permCodes: 'CanViewDoctor'
        },
        {
          title: 'Department Management',
          icon: 'department',
          isGroup: true,
          subgroup: false,
          childs: [
            {
                title: 'Symptomatic Areas',
                route: 'symptomatic-areas-list',
                activeRoutes: ['symptomatic-areas-list', 'symptomatic-areas-create', 'symptomatic-areas-edit'],
                permCodes: 'CanViewSymptomatic',
            },
            {
              title: 'Department',
              route: 'department-list',
              activeRoutes: ['department-list', 'department-create', 'department-edit'],
              permCodes: 'CanViewDepartment',
            },
          ]
        },
        {
          title: 'Push Notification',
          icon: 'notification',
          route: 'notification-list',
          activeRoutes: ['notification-list', 'notification-create'],
          permCodes: 'CanViewNotification'
        },
        {
          title: 'Reports',
          icon: 'report',
          isGroup: true,
          subgroup: false,
          childs: [
            {
              title: 'Coupon Usage',
              route: 'reports-coupon',
              permCodes: 'CanViewCoupon'
            }
          ]
        },
        {
          title: 'Assistant Tool',
          icon: 'assistant-tool',
          isGroup: true,
          subgroup: false,
          childs: [
            {
              title: 'Registration',
              route: 'generate-link',
              target: '_blank'
            },
            {
              title: 'Binding workaround',
              route: 'binding',
              target: '_blank'
            }
          ]
        },
        {
          title: 'QR Code Management',
          icon: 'qr-code-management',
          route: 'qr-code-list',
          activeRoutes: ['qr-code-list', 'qr-code-edit'],
          permCodes: 'CanViewQRCode'
        },
        {
          title: 'App Config',
          icon: 'report',
          isGroup: true,
          subgroup: false,
          childs: [
            {
              title: 'Force Update',
              route: 'force-update-list',
              activeRoutes: ['force-update-list', 'force-update-edit'],
              permCodes: 'CanViewAppConfig'
            },
            {
              title: 'Localize',
              route: 'localize',
              permCodes: 'CanViewAppConfig'
            },
            {
              title: 'Terms and Conditions',
              route: 'term-and-conditions',
              activeRoutes: ['term-and-conditions', 'term-and-condition-create'],
              permCodes: 'CanViewAppConfig'
            },
            {
              title: 'Feature Setup',
              route: 'feature-setup-list',
              activeRoutes: ['feature-setup-list', 'feature-setup-edit'],
              permCodes: 'CanViewAppConfig'
            },
          ]
        },
        {
          title: 'Role & Permission',
          icon: 'role-permission',
          isGroup: true,
          subgroup: false,
          childs: [
            {
              title: 'User',
              icon: 'local_offer',
              route: 'user-role-list',
              activeRoutes: ['user-role-list', 'user-role-create', 'user-role-edit'],
              permCodes: 'CanViewUser'
            },
            {
              title: 'User Role',
              icon: 'local_offer',
              route: 'role-permission-list',
              activeRoutes: ['role-permission-list', 'role-permission-create', 'role-permission-edit'],
              permCodes: 'CanViewRolePermission'
            },
            {
              title: 'Organization',
              icon: 'local_offer',
              route: 'organization-list',
              activeRoutes: ['organization-list', 'organization-create', 'organization-edit'],
              permCodes: 'CanViewOrganization'
            }
          ]
        },
      ],
    }
  }
}
</script>

<style scoped>
.navbar-logo-text {
  font-family: SukhumvitTadmai;
  font-size: 20px !important;
  font-weight: 300 !important;
}

.v-list-item-avatar {
  min-height: 64px;
}

.v-list-item__title {
  font-family: SukhumvitTadmai;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: var(--white-color);
}

</style>
