import client from '@/apis/http-client'
import moment from 'moment'
import Vue from 'vue'
let pathUrl = `/por/api`
const pathOrgUrl = `sso/rbac/organization/permission`

import {convertFileToBase64} from '../helper/helper'

// Initial state.
const defaultState = () => {
  return {
    // Filters
    promotionOnly: false,
    filterStatus: '',
    textSearch: '',
    deleteId: 0,
    isDelete: false,
    // List of coupons
    isLoading: false,
    couponsList: [],
    updateIdList: [],
    isPreview: false,
    currentPage: 1,
    totalCoupons: 0,
    pageSize: 25,
    total: 0,
    sortBy: 'update_date',
    sortDesc: 'DESC',
    orgObject: [],

    // coupon object
    couponObject: {
      id: null,
      price: null,
      orgs: [
        {
          codename: null,
          department_ids: [],
          departments: []
        }
      ],
      contents: [
        {
          code: 'th',
          title: null,
          description: '',
          thumbnail: '',
          name: ''
        },
        {
          code: 'en',
          title: null,
          description: '',
          thumbnail: '',
          name: ''
        }
      ],
      is_promotion: false,
      discount: '',
      discount_type: '0',
      promo_date_from: null,
      promo_date_to: null,
      start_date: '',
      end_date: '',
      status: '1',
      quantity: '0',
      name: ''
    },
    isEditMode: false,
    selectedArray: [],
    selectionDepartment: [],
    departments: [],
    orgFilterDepartment: null
  }
}

// State
let state = defaultState()

// Actions
const actions = {
  async setOrgInfo({commit}) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(
      `${pathOrgUrl}?permission=${
        state.isEditMode ? 'Edit' : 'Add'
      }&category=Coupon`
    )
    commit('SET_ORG_LIST', data.data)
    commit('SET_IS_LOADING', false)
  },
  resetList({commit}) {
    commit('RESET_LIST_STATE')
  },
  setImageThai({commit}, file) {
    commit('SET_IMAGE_THAI', file)
  },
  setImageEng({commit}, file) {
    commit('SET_IMAGE_ENG', file)
  },
  resetState({commit}) {
    commit('RESET_STATE')
  },
  setEditMode({commit}, isEditable) {
    commit('SET_EDIT_MODE', isEditable)
  },
  setDeleteId({commit}, id) {
    commit('SET_DELETE_ID', id)
  },
  setPage({commit}, page) {
    commit('SET_PAGE', page)
  },
  setPageSize({commit}, size) {
    commit('SET_PAGE_SIZE', size)
  },
  setFilterStatus({commit}, status) {
    commit('SET_FILTER_STATUS', status)
  },
  setOnlyPromotion({commit}, isPromo) {
    commit('SET_FILTER_PROMOTION', isPromo)
  },
  setShowPreview({commit}, status) {
    // isPreview:false,
    commit('SET_SHOW_PREVIEW', status)
  },
  setTextSearch({commit}, text) {
    if (text == state.textSearch) return
    commit('SET_TEXT_SEARCH', text)
  },
  setFieldSort({commit}, field) {
    if (field) {
      commit('SET_FIELD_SORT', field)
    } else {
      commit('SET_FIELD_SORT', defaultState().sortBy)
    }
  },
  setSort({commit}, sort) {
    commit('SET_SORT', sort === undefined ? 'DESC' : sort ? 'DESC' : 'ASC')
  },
  setSelectedId({commit}, ids) {
    commit('SET_UPDATE_ID', ids)
  },
  setDeletePopup({commit}, status) {
    commit('SET_POPUP_DELETE', status)
  },

  setSelectedArray({commit}) {
    commit('SET_SELECTED_ARRAY')
  },
  async setCouponId({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/coupon/${id}`)

    if (data) {
      commit('SET_COUPON', data.data)
    }
    commit('SET_IS_LOADING', false)
  },
  async setcouponPreview({commit}, id) {
    commit('SET_IS_LOADING', true)
    let data = await client.secureHttpClient.get(`${pathUrl}/coupon/${id}`)
    if (data) {
      commit('SET_COUPON', data.data)
      commit('SET_SHOW_PREVIEW', true)
    }
    commit('SET_IS_LOADING', false)
  },
  /// EDIT call
  async update({commit, dispatch}, status) {
    let ids = state.updateIdList.map(id => {
      return id.id
    })
    let body = {ids: ids, status: status === 'Active' ? 0 : 1}
    await client.secureHttpClient.patch(
      `${pathUrl}/coupon/bulk-status-update`,
      body
    )
    dispatch('fetch')
    commit('SET_UPDATE_ID', [])
  },
  async upload(_, payload) {
    try {
      let data = await client.secureHttpClient.post(
        `${pathUrl}/uploadImage`,
        payload
      )
      return data.data.url
    } catch (e) {
      Vue.$log.error(e)
      return ''
    }
  },
  async deletePkg({commit, dispatch}) {
    try {
      commit('SET_IS_LOADING', true)
      await client.secureHttpClient.delete(
        `${pathUrl}/coupon/${state.deleteId}`
      )
      commit('SET_POPUP_DELETE', false)
      commit('SET_DELETE_ID', 0)
      dispatch('fetch')
      return true
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      commit('SET_POPUP_DELETE', false)
      return false
    }
  },
  // API calls
  async fetch({commit}) {
    commit('SET_IS_LOADING', true)
    const {
      currentPage,
      pageSize,
      textSearch,
      promotionOnly,
      filterStatus,
      sortBy,
      sortDesc
    } = state
    try {
      let data = await client.secureHttpClient.get(
        `${pathUrl}/coupon?page=${currentPage}&&limit=${pageSize}&&q=${textSearch}&status=${filterStatus}&orderBy=${sortBy}&sort=${sortDesc}&promotion=${promotionOnly}`
      )
      commit('SET_TOTAL_COUPONS', data.data.total)
      commit('SET_COUPONS_LIST', data.data.results)
      commit('SET_IS_LOADING', false)
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  setStyleImage({commit}) {
    commit('SET_STYLE_CONTENT')
  },

  async updatePkg({commit}, payload) {
    const {pkg, id} = payload
    commit('SET_IS_LOADING', true)
    pkg.title = pkg.name
    pkg.price = parseInt(pkg.price)
    pkg.quantity = parseInt(pkg.quantity)
    const tempOrginalOrgs = pkg.orgs
    const tempOrgs = []

    for (const object of pkg.orgs) {
      if (!object.codename || object.department_ids.length === 0) {
        commit('SET_IS_LOADING', false)
        return false
      }
      tempOrgs.push({
        codename: object.codename,
        department_ids: await object.department_ids.map(
          dept => dept.id || dept
        ),
        selected: true
      })
    }
    pkg.orgs = tempOrgs
    if (pkg.orgs.length === 0) {
      commit('SET_IS_LOADING', false)
      return false
    }

    try {
      let data = await client.secureHttpClient.put(
        `${pathUrl}/coupon/${id}`,
        pkg
      )
      pkg.orgs = tempOrginalOrgs
      commit('SET_IS_LOADING', false)
      return data.data.success
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
    }
  },
  async create({commit}, pkg) {
    commit('SET_IS_LOADING', true)
    pkg.title = pkg.name
    pkg.price = parseInt(pkg.price)
    pkg.quantity = parseInt(pkg.quantity)
    pkg.full_price = parseInt(pkg.full_price)
    pkg.status = parseInt(pkg.status)

    const tempOrgs = []
    for (const object of pkg.orgs) {
      if (!object.codename || object.department_ids.length === 0) {
        commit('SET_IS_LOADING', false)
        return false
      }

      tempOrgs.push({
        codename: object.codename,
        department_ids: await object.department_ids.map(dept => dept.id),
        selected: true
      })
    }

    pkg.orgs = tempOrgs
    if (pkg.orgs.length === 0) {
      commit('SET_IS_LOADING', false)
      return false
    }

    try {
      let create = await client.secureHttpClient.post(`${pathUrl}/coupon/`, pkg)
      commit('RESET_STATE')
      commit('SET_IS_LOADING', false)
      return create.data.id ? true : false
    } catch (e) {
      Vue.$log.error(e)
      commit('SET_IS_LOADING', false)
      return false
    }
  },
  async fetchDepartmentToReplace({commit}, object) {
    const {orgFilterDepartment} = state
    try {
      commit('SET_IS_LOADING', true)
      let department = await client.secureHttpClient.get(
        `${pathUrl}/coupon/departments?orgs[${0}]=${object.codename ||
          orgFilterDepartment}`
      )
      let results = department.data.data.map(object => {
        return {
          name: object.name,
          id: object.id
        }
      })
      state.couponObject.orgs[object.index].departments = results
      commit('SET_IS_LOADING', false)
    } catch (e) {
      state.couponObject.orgs[object.index].departments = []
      commit('SET_IS_LOADING', false)
    }
  }
}

const mutations = {
  SET_ORG_LIST(state, org) {
    state.orgObject = org.data
  },
  RESET_STATE(state) {
    state.couponObject = defaultState().couponObject
    state.selectedArray = []
    state.selectionDepartment = []
    state.departments = []
    state.orgFilterDepartment = null
  },
  RESET_LIST_STATE(state) {
    state.filterStatus = defaultState().filterStatus
    state.promotionOnly = defaultState().promotionOnly
    state.sortBy = defaultState().sortBy
  },
  SET_STYLE_CONTENT(state) {
    state.couponObject.content[0].title = state.couponObject.content[0].name
    state.couponObject.content[1].title = state.couponObject.content[1].name
    state.couponObject.content[0].description = state.couponObject.content[0].description.toString()
      .split('<img')
      .map((imgTag) => {
        const srcMatch = imgTag.match(/src="([^"]*)"/); // Extract the image URL
        if (srcMatch && srcMatch[1]) {
          const imageUrl = srcMatch[1];
          // If the image URL does not contain 'emoji', add width and height attributes
          if (!imageUrl.includes('emoji')) {
            return ' width="100%" height="auto" ' + imgTag;
          }
        }
        return imgTag;
      })
      .join('<img');
      state.couponObject.content[1].description = state.couponObject.content[1].description.toString()
      .split('<img')
      .map((imgTag) => {
        const srcMatch = imgTag.match(/src="([^"]*)"/);
        if (srcMatch && srcMatch[1]) {
          const imageUrl = srcMatch[1];
          if (!imageUrl.includes('emoji')) {
            return ' width="100%" height="auto" ' + imgTag;
          }
        }
        return imgTag;
      })
      .join('<img');
  },
  SET_EDIT_MODE(state, isEdit) {
    state.isEditMode = isEdit
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_PAGE(state, page) {
    state.currentPage = page
  },
  SET_PAGE_SIZE(state, size) {
    state.pageSize = size
  },
  SET_FILTER_PROMOTION(state, isPromo) {
    state.promotionOnly = isPromo
  },
  SET_FILTER_STATUS(state, status) {
    state.filterStatus = status
  },
  SET_TEXT_SEARCH(state, text) {
    state.textSearch = text
  },
  SET_TOTAL_COUPONS(state, total) {
    state.totalCoupons = total
  },
  SET_COUPONS_LIST(state, coupons) {
    state.couponsList = coupons
  },
  SET_COUPON(state, pkg) {
    state.couponObject = pkg
    state.couponObject.status = pkg.status.toString()
    state.couponObject.discount_type = pkg.discount_type.toString()

    state.couponObject.start_date = moment(pkg.start_date)
      .toISOString()
      .substr(0, 10)
    state.couponObject.end_date = moment(pkg.end_date)
      .toISOString()
      .substr(0, 10)
  },
  SET_FIELD_SORT(state, field) {
    state.sortBy = field
  },
  SET_SORT(state, sort) {
    state.sortDesc = sort
  },
  SET_UPDATE_ID(state, ids) {
    state.updateIdList = ids
  },
  SET_IMAGE_THAI(state, file) {
    convertFileToBase64(file)
      .then(base64 => {
        state.couponObject.content[0].thumbnail = base64
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_IMAGE_ENG(state, file) {
    convertFileToBase64(file)
      .then(base64 => {
        state.couponObject.content[1].thumbnail = base64
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  SET_POPUP_DELETE(state, status) {
    state.isDelete = status
  },
  SET_DELETE_ID(state, id) {
    state.deleteId = id
  },
  SET_SHOW_PREVIEW(state, status) {
    state.isPreview = status
  },
  SET_SELECTED_ARRAY(state) {
    const selectedArray = [
      ...state.couponObject.orgs,
      {
        codename: null,
        department_ids: [],
        departments: []
      }
    ]
    state.couponObject.orgs = selectedArray
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
