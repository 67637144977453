import moment from 'moment'

const stringify = obj => JSON.stringify(obj)

const parse = str => JSON.parse(str)

const authLocalStorage = () => {
  // Read value that indicagte the current user is logged-in.
  const getIsLoggedIn = () => parse(localStorage.getItem('isloggedin'))

  // Store boolean indicate that current user is logged-in.
  const setIsLoggedIn = val =>
    localStorage.setItem('isloggedin', stringify(val))

  // Read logged-in user's information.
  const getUser = () => parse(localStorage.getItem('user'))
  const getRoles = () => parse(localStorage.getItem('roles'))

  // Store logged-in user's information.
  const setUser = user => localStorage.setItem('user', stringify(user))
  const setRoles = roles => localStorage.setItem('roles', stringify(roles))

  // Read access-token.
  const getAccessToken = () => parse(localStorage.getItem('access')) || ''

  const getLastLogin = () => parseInt(localStorage.getItem('last_login') || 0)

  const setLastLogin = () => localStorage.setItem('last_login', moment().unix().toString())

  // Store access-token.
  const setAccessToken = token =>
    localStorage.setItem('access', stringify(token))

  // Read refresh-token.
  const getRefreshToken = () => parse(localStorage.getItem('refresh')) || ''

  // Store refresh-token.
  const setRefreshToken = token =>
    localStorage.setItem('refresh', stringify(token))

  // Remove all data related to Auth.
  const removeAll = () => {
    localStorage.removeItem('isloggedin')
    localStorage.removeItem('user')
    localStorage.removeItem('access')
    localStorage.removeItem('refresh')
    localStorage.removeItem('roles')
  }

  return {
    getIsLoggedIn,
    setIsLoggedIn,
    getUser,
    getRoles,
    setUser,
    setRoles,
    getAccessToken,
    setAccessToken,
    getRefreshToken,
    setRefreshToken,
    getLastLogin,
    setLastLogin,
    removeAll
  }
}

export default {authLocalStorage}
