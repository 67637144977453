<template>
  <div :style="cssProps">
    <component v-bind:is="layout"></component>
  </div>
</template>

<script>
import Simple from './layouts/simple'
import Default from './layouts/default'
import External from './layouts/external'
import {EventBus} from './helper/event-bus'

export default {
  name: 'App',

  computed: {
    cssProps() {
      return {
        '--border-color': this.$vuetify.theme.themes.light.borderColor,
        '--danger-200-color': this.$vuetify.theme.themes.light.danger200Color,
        '--danger-400-color': this.$vuetify.theme.themes.light.danger400Color,
        '--danger-600-color': this.$vuetify.theme.themes.light.danger600Color,
        '--danger-800-color': this.$vuetify.theme.themes.light.danger800Color,
        '--disable-text-color': this.$vuetify.theme.themes.light.disableTextColor,
        '--error-text-color': this.$vuetify.theme.themes.light.errorTextColor,
        '--fail-chip-color': this.$vuetify.theme.themes.light.failChip,
        '--header-text-color': this.$vuetify.theme.themes.light.headerTextColor,
        '--hover-bg-color': this.$vuetify.theme.themes.light.hoverBgColor,
        '--hover-menu-color': this.$vuetify.theme.themes.light.hoverMenuColor,
        '--info-400-color': this.$vuetify.theme.themes.light.info400Color,
        '--info-500-color': this.$vuetify.theme.themes.light.info500Color,
        '--info-600-color': this.$vuetify.theme.themes.light.info600Color,
        '--info-800-color': this.$vuetify.theme.themes.light.info800Color,
        '--primary-100-color': this.$vuetify.theme.themes.light.primary100Color,
        '--primary-200-color': this.$vuetify.theme.themes.light.primary200Color,
        '--primary-400-color': this.$vuetify.theme.themes.light.primary400Color,
        '--primary-500-color': this.$vuetify.theme.themes.light.primary500Color,
        '--primary-700-color': this.$vuetify.theme.themes.light.primary700Color,
        '--primary-text-color': this.$vuetify.theme.themes.light.primaryTextColor,
        '--grayscale-color': this.$vuetify.theme.themes.light.grayscale,
        '--divider-color': this.$vuetify.theme.themes.light.divider,
        '--secondary-color': this.$vuetify.theme.themes.light.secondary,
        '--secondary-text-color': this.$vuetify.theme.themes.light.secondaryTextColor,
        '--success-800-color': this.$vuetify.theme.themes.light.success800Color,
        '--warning-600-color': this.$vuetify.theme.themes.light.warning600Color,
        '--warning-800-color': this.$vuetify.theme.themes.light.warning800Color,
        '--white-color': this.$vuetify.theme.themes.light.whiteColor,
        '--login-btn-color': this.$vuetify.theme.themes.light.loginBtnColor,
        '--image-bg-color': this.$vuetify.theme.themes.light.imageBgColor,
        '--div-hover-bg-color': this.$vuetify.theme.themes.light.divHoverBgColor,
        '--typography-primary-text-color': this.$vuetify.theme.themes.light.typographyPrimaryTextColor,
      };
    },
    layout() {
      if (this.$route.meta && this.$route.meta.layout) {
        return this.$route.meta.layout
      }
      let loggedIn = this.$store.getters['auth/getIsLoggedIn']
      return loggedIn ? 'default' : 'simple'
    }
  },

  created() {
    // Wide use for listening an event
    // of when user's session has expired.
    EventBus.$on('sessionHasExpired', () => {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({name: 'login'})
      })
    })
    EventBus.$on('notFound', () => {
      this.$router.push({name: 'not-found'})
    })
  },

  components: {
    Simple,
    Default,
    External
  }
}
</script>
<style>
@font-face {
  font-family: 'SukhumvitTadmai';
  src: local('SukhumvitTadmai'),
    url(../public/fonts/SukhumvitTadmai_Tex.otf) format('truetype');
}

@font-face {
  font-family: 'SukhumvitTadmai-bold';
  src: local('SukhumvitTadmai-bold'),
    url(../public/fonts/SukhumvitTadmai_Bol.otf) format('truetype');
}

.pageHeadline {
  font-weight: 700;
  font-family: 'SukhumvitTadmai-bold';
  font-size: 32px;
  color: var(--header-text-color);
}
</style>
